import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import globalVariable from "../config/generalConfig";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = globalVariable.MIDDLEWARE_URL;

const responseBody = res => res.body;

let token = null;

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .withCredentials()
      .then(responseBody),
  get: (url, params) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .query(params)
      .withCredentials()
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .withCredentials()
      .then(responseBody),
  post: (url, body, params = {},callback=()=>{}) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .query(params)
      .withCredentials()
      .then(responseBody)
};

const Auth = {
  current: () => requests.post("/isLoggedIn"),
  login: requestBody => requests.post("/login", requestBody),
  profile: () => requests.get("/profile"),
  logout: () => requests.post("/logout")
};

const Retaurant = {
  all: requestParams => requests.get("/restaurants/list", requestParams),
  details: requestParams => requests.get("/getRestaurantInfo", requestParams),
  changeRestaurant: requestParams =>
    requests.post("/setRestaurantInfo", requestParams),
  walkInStats: requestParams =>
    requests.get("/dashboard/walkInStats", requestParams),
  revenueStats: requestParams =>
    requests.get("/dashboard/revenueStats", requestParams),
  genderStats: requestParams =>
    requests.get("/dashboard/genderStats", requestParams),
  ageGroupStats: requestParams =>
    requests.get("/dashboard/ageGroupStats", requestParams),
  sessionStats: requestParams =>
    requests.get("/dashboard/sessionStats", requestParams),
  menus: requestParams => 
    requests.get("/restaurants/menus", requestParams),
  changeMenuStatus: bodyParams =>
    requests.post("/restaurants/change-menu-status", bodyParams),
  menuVariantGroups: requestParams =>
    requests.get("/restaurants/menu-variant-groups", requestParams),
  changeMenuVariantGroupStatus: (requestParams, bodyParams) => 
    requests.post("/restaurants/change-menu-variant-group-status", bodyParams, requestParams),
  changeMenuVariantStatus: (requestParams, bodyParams) => 
    requests.post("/restaurants/change-menu-variant-status", bodyParams, requestParams),
  menuVariants: requestParams =>
    requests.get("/restaurants/menu-variants", requestParams),
  detailView: requestParams =>
    requests.get("/restaurants/view", requestParams),
  update: (requestParams, bodyParams) => 
    requests.post("/restaurants/update", bodyParams, requestParams),
  updateTopMenus: requestParams => requests.post("/restaurants/updateTopMenus", requestParams),
  updateTopMenuSlots: requestParams => requests.post("/restaurants/updateTopMenuSlots", requestParams),
  orders: requestParams => requests.get("/orders/list", requestParams),
  view: requestParams => requests.get("/orders/view", requestParams),
  changeOrderStatus: (bodyParams, urlParams, callback=()=>{}) => requests.post("/orders/change-status", bodyParams, urlParams, callback=()=>{}),
  changeOrderStatusValue: (bodyParams, urlParams, callback=()=>{}) => requests.post("/orders/change-order-status", bodyParams, urlParams, callback=()=>{}),
  confirmOrders: (bodyParams, urlParams, callback=()=>{}) => requests.post("/orders/confirm", bodyParams, urlParams,callback=()=>{}),
  computeConfirm: (bodyParams, urlParams) => requests.post("/orders/compute-confirm", bodyParams, urlParams),
};

const Users = {
  crm: requestParams => requests.get("/users/CRM", requestParams),
  sendNotification: requestParams => requests.post("/users/sendNotification", requestParams),
  visits: requestParams => requests.get("/users/visits", requestParams),
  view: requestParams => requests.get("/users/view", requestParams),
};

const Hoppoints = {
  viewSummary: requestParams =>requests.get("/hoppoints/viewSummary", requestParams),
  transactions: requestParams =>requests.get("/hoppoints/transactions", requestParams)
};

export default {
  Auth,
  Retaurant,
  Hoppoints,
  Users,
  setToken: _token => {
    token = _token;
  }
};
