import React, { Component } from "react";
import { connect } from "react-redux";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import ReactTooltip from 'react-tooltip';
import {  Select, Input, Modal, Button, notification, Table, Switch, Collapse,Spin  } from "antd";
import _ from 'lodash';

import { capitalizeWord, formatDate } from "../../utils/Helpers";
import { MENUS_UNLOADED } from "../../constants/actionTypes";
import OrderModal from "./order-modal";
import agent from "../../agent";
import globalVariable from "../../config/generalConfig";
import moment from 'moment';

class OrdersListTable extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
    endpoint: globalVariable.MIDDLEWARE_SOCKET_URL,
    orderUtilities: [],
    utilitiesModal: false,
    selectedUtilitiesData: {},
    sortBy: 'created_at.desc',
    orders: [],
    isNotificationEnabled: false,
    notificationData: [],
    currentUtilityIndex: null,
    visits: [],
    orderMeta: {},
    selectedOrderData: {},
    visitsData: {},
    visitsMeta: {},
    selectedVisitData: {},
    tableId: "",
  };

  onAddReceiverId = (receiverId) => {
    this.props.onAddReceiversForNotifiaction(receiverId);
  }
  setOrderModal = async (orderModal, data) => {
    this.setState({
      orderModal
    });
    if (data) {
      await this.setState({
        selectedOrderData: data
      });
      this.onGetUserOrderDetails();
    }
    if (data && data.data && data.data.data && data.data.data.data) {
      await this.setState({
        selectedVisitData: data.data.data.data
      });
      this.onGetUserOrderDetails();
    }
  };
   //  view orders details
   onGetUserOrderDetails = () => {
    if (this.state.selectedOrderData && this.state.selectedOrderData.id) {
      let requestParams = {
        id: this.state.selectedOrderData.id,
        expand: "user,details,items,discount,transactions"
      };
      this.props.onGetUserOrderDetails(agent.Retaurant.view(requestParams));
    }
  };
  render() { 
    // console.log('Length',this.props.stateData.length);
       
    return (
      <table className="table table-bordered table-striped">
        <thead className="thead-light">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Order Number</th>
            <th scope="col">Order Type</th>
            <th scope="col">Time</th>
            <th scope="col">Sub Total</th>
            <th scope="col">Charges + GST + TDS</th>
            <th scope="col">Discounts</th>
            <th scope="col">Payable</th>
            <th scope="col">Order Status</th>
            <th scope="col">Order Details</th>
          </tr>
        </thead>
        <tbody>
          {(this.props.inOrdersListProgress || this.props.currentRestaurantInProgress) &&
            <tr><td colSpan="7"><Spin className="ordersList-table-spinner" /></td></tr>}
          {(!this.props.currentRestaurantInProgress || !this.props.inOrdersListProgress) && 
          this.props.ordersList &&
            this.props.ordersList.items && this.props.stateData &&
            this.props.stateData.length === 0 && (<tr><td colSpan="7">
              <div className="no-content-container">
                <div className="icon">
                  <i className="fa fa-coffee" />
                </div>
                <div className="text">No data
                </div>
              </div>
            </td></tr>)}
          {!this.props.inOrdersListProgress && this.props.stateData && 
            this.props.stateData.map((value, index) => { 
              // console.log(_.toNumber(parseFloat(value.total).toFixed(2)),value.discount_amount!=null && value.discount_amount?parseFloat(value.discount_amount).toFixed(2):0,value.delivery_charges?parseFloat(value.delivery_charges):0,value.packing_charges?parseFloat(value.packing_charges).toFixed(2):0);
              let values =  _.toNumber(parseFloat(value.sub_total).toFixed(2))-_.toNumber(parseFloat(value.total).toFixed(2))
              let dis =  (value.discount_amount!=null && value.discount_amount?_.toNumber(parseFloat(value.discount_amount).toFixed(2)):0) - Math.abs(values).toFixed(2)
             
              // if(value.status !=2 ){
                return (
                  <tr key={index}>
                    <td>
                    <div onClick={() => this.setOrderModal(true, value)}>
                      {value.order_date &&value.order_date!= null?(moment(value.order_date).format('DD/MM/YYYY')):(moment(value.created_at).format('DD/MM/YYYY')) }
                      </div>
                      </td>
                  <td>
                  <div className="order-link" onClick={() => this.setOrderModal(true, value)}>
                  {value.order_id}
                  </div>
                  </td>
                  {(() =>{
                    if(value.type === 0){
                      return<td>Dine</td>
                    }else if(value.type === 1) {
                      return<td>Take away</td>
                    }else if(value.type === 2) {
                      return<td>Delivery</td>}
                  })()}
                  <td>
                  {/* <div  className="order-link" onClick={() => this.setOrderModal(true, value)}> */}
                  {value.order_time}
                  {/* </div> */}
                  </td>
                  <td>Rs.{parseFloat(value.sub_total).toFixed(2)}</td>
                  <td>
                  Rs.{Math.abs(dis).toFixed(2)}
                  </td>
                  {/* <td>Rs.{Math.abs((parseFloat(value.total)+value.discount_amount?parseFloat(value.discount_amount).toFixed(2):0)-(value.delivery_charges?parseFloat(value.delivery_charges):0)-(value.packing_charges?parseFloat(value.packing_charges).toFixed(2):0)).toFixed(2)}</td> */}
                  <td>Rs.{value.discount_amount?parseFloat(value.discount_amount).toFixed(2):0}</td>
                  <td>Rs.{parseFloat(value.total).toFixed(2)}</td>
                    {(() =>{if(value.status === 0){
                      return<td>Rejected</td>
                    }else if(value.status === 1){
                      return<td>Paid</td>
                    }else if(value.status === 2){
                        return<td>Awaiting</td>
                    }else if(value.status === 5){
                      return<td> Payment Pending</td>
                    }else if(value.status === 8){
                      return<td> Preparing</td>
                    }else if(value.status === 9){
                      return<td> Order Ready</td>
                    }else if(value.status === 7){
                      return<td> Order Canceled</td>
                    }else{
                      return<td></td>
                    }
                  })()}
                   <td>
                  <div  className="order-link" onClick={() => this.setOrderModal(true, value)}>
                  View Details
                  </div>
                  </td>
                    {/* {() => {
                                    switch (value.status) {
                                      case 0:
                                        return <td>Inactive</td>;
                                      case 1:
                                        return <td>Active</td>;
                                      case 2:
                                        return <td>Pending</td>;
                                      case 3:
                                        return <td>Deleted</td>;
                                      case 4:
                                        return <td>Reported</td>;
                                      case 5:
                                        return <td>Accepted</td>;
                                      case 6:
                                        return <td>Payment Pending</td>;
                                    }
                                  }} */}
                    </tr>
                );
              // }else if(value.status === 5){
              //   return (
              //     <tr key={index}>
              //      <td>
              //       <div className="order-link" onClick={() => this.setOrderModal(true, value)}>
              //       {value.order_date &&value.order_date!= null?(moment(value.order_date).format('DD/MM/YYYY')):(moment(value.created_at).format('DD/MM/YYYY')) }
              //         </div>
              //         </td>
              //     <td>
              //     <div className="order-link" onClick={() => this.setOrderModal(true, value)}>
              //     {value.order_id}
              //     </div>
              //     </td>
              //     {(() =>{
              //       if(value.type === 0){
              //         return<td>Dine</td>
              //       }else if(value.type === 1) {
              //         return<td>Take away</td>
              //       }else if(value.type === 2) {
              //         return<td>Delivery</td>}
              //     })()}
              //     <td>
              //     <div className="order-link" onClick={() => this.setOrderModal(true, value)}>
              //     {value.order_time}
              //     </div>
              //     </td>
              //     <td>Rs.{Math.abs(value.total)}</td>
              //     <td>Rs.{Math.abs(parseInt(value.total)-parseInt(value.sub_total))}</td>
              //     <td>Rs.{Math.abs(value.sub_total)}</td>                  
              //       {(() =>{if(value.status === 0){
              //         return<td>Rejected</td>
              //       }else if(value.status === 1){
              //         return<td>Paid</td>
              //       }else if(value.status === 5){
              //           return<td>Pending</td>}
              //     })()}
              //       </tr>
              //   );
              // }else if(value.status === 0){
              //   return (
              //     <tr key={index}>
              //       <td>
              //       <div onClick={() => this.setOrderModal(true, value)}>
              //       {value.order_date &&value.order_date!= null?(moment(value.order_date).format('DD/MM/YYYY')):(moment(value.created_at).format('DD/MM/YYYY')) }
              //         </div>
              //         </td>
              //     <td>
              //     <div onClick={() => this.setOrderModal(true, value)}>
              //     {value.order_id}
              //     </div>
              //     </td>
              //     {(() =>{
              //       if(value.type === 0){
              //         return<td>Dine</td>
              //       }else if(value.type === 1) {
              //         return<td>Take away</td>
              //       }else if(value.type === 2) {
              //         return<td>Delivery</td>}
              //     })()}
              //      <td>
              //     <div onClick={() => this.setOrderModal(true, value)}>
              //     {value.order_time}
              //     </div>
              //     </td>
              //     <td>Rs.{Math.abs(value.total)}</td>
              //     <td>Rs.{Math.abs(parseInt(value.total)-parseInt(value.sub_total))}</td>
              //     <td>Rs.{Math.abs(value.sub_total)}</td>
              //       {(() =>{if(value.status === 0){
              //         return<td>Rejected</td>
              //       }else if(value.status === 1){
              //         return<td>Paid</td>
              //       }else if(value.status === 5){
              //           return<td>Pending</td>}
              //     })()}
              //       </tr>
              //   );
              // }
            })
          }
        </tbody>
        <OrderModal
          {...this.state}
          onClick={() => this.onGetOrders()}
          setOrderModal={value => this.setOrderModal(value)}
          userDetails={this.props.userOrderDetails}
          currentRestaurant={this.props.currentRestaurant}
          selectedVisitData={this.state.selectedOrderData}
          onSubmitOrder={type => this.onSubmitOrder(type)}
          isOrderChangeStatusProgress={this.props.isOrderChangeStatusProgress}
          orderStatusUpdated={this.props.orderStatusUpdated}
          selectedOrderData={this.state.selectedOrderData}
          onGetOrders={() => this.onGetOrders()}
          orderStatusUpdatedErrors={this.props.orderStatusUpdatedErrors}
          onClearErrors={() => this.props.onClearErrors()}
          orders={this.props.orders}
        />
      </table>
      
    )
  }
}


const mapStateToProps = state => {  
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    inOrdersListProgress: state.restaurant.inOrdersListProgress,
    currentRestaurant: state.profile.currentRestaurant,
    ordersList: state.restaurant.ordersList,
    notificationData: state.users.notificationData,
    inSendNotificationProgress: state.users.inSendNotificationProgress,
    utilitiesData: state.common.utilitiesData,
    ordersData: state.common.ordersData,
    visitsData: state.common.visitsData,
    notifyData: state.common.notifyData,
    orders: state.restaurant.orders,
    visits: state.users.visits,
    userDetails: state.users.userDetails,
    isOrderChangeStatusProgress: state.restaurant.isOrderChangeStatusProgress,
    orderStatusUpdated: state.restaurant.orderStatusUpdated,
    orderStatusUpdatedErrors: state.restaurant.orderStatusUpdatedErrors,
    isOrderProgress: state.restaurant.isOrderProgress,
    userOrderDetails: state.restaurant.userOrderDetails
  };
};

const mapDispatchToProps = dispatch => ({
  onUnload: () => dispatch({ type: MENUS_UNLOADED })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersListTable);