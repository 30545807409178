import {
  WALK_IN_STATS_LOADED,
  REVENUE_STATS_LOADED,
  GENDER_STATS_LOADED,
  AGE_GROUP_STATS_LOADED,
  SESSION_STATS_LOADED,
  GET_ALL_MENUS,
  CHANGE_MENU_STATUS,
  GET_TOP_MENUS,
  ASYNC_START,
  UPDATE_TOP_MENUS,
  MENUS_UNLOADED,
  UPDATE_TOP_MENU_SLOT,
  GET_ORDERS,
  GET_ORDERS_LIST,
  CHANGE_ORDER_STATUS,
  CLEAR_ERRORS,
  GET_VISITS,
  GET_USER_ORDER_DETAILS,
  CONFIRM_ORDERS,
  COMPUTE_CONFIRM,
  CHANGE_ORDER_STATUS_VALUE,
  MENU_VARIANT_GROUPS,
  CHANGE_MENU_VARIANT_GROUP_STATUS,
  CHANGE_MENU_VARIANT_STATUS,
  RESTAURANT_VIEW,
  RESTAURANT_UPDATE,
  MENU_VARIANTS
} from "../constants/actionTypes";

const defaultState = {
  walkInStats: {},
  revenueStats: {},
  genderStats: {},
  ageGroupStats: {},
  sessionStats: {},
  inWalkInStatsProgress: true,
  inRevenueStatsProgress: true,
  inAgeGroupStatsProgress: true,
  inGenderStatsProgress: true,
  inSessionStatsProgress: true,
  menus: {},
  topMenus: {},
  updateTopMenus: {},
  updateTopMenuSlots: {},
  topMenuSlotErrors: {},
  orders: {},
  ordersList:{},
  orderStatusUpdated: {},
  orderStatusUpdatedErrors: {},
  userOrderDetails: {},
  confirmOrders:{},
  orderStatusChange: {},
  changeMenuStatus: {},
  menuVariantGroups: {},
  changeMenuVariantGroupStatus: {},
  changeMenuVariantStatus: {},
  restaurantView: {},
  restaurantUpdate: {},
  menuVariants: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        orderStatusUpdatedErrors: {},
      };
    case WALK_IN_STATS_LOADED:
      return {
        ...state,
        walkInStats: action.error ? {} : action.payload,
        inWalkInStatsProgress: false
      };
    case REVENUE_STATS_LOADED:
      return {
        ...state,
        revenueStats: action.error ? {} : action.payload,
        inRevenueStatsProgress: false
      };
    case GENDER_STATS_LOADED:
      return {
        ...state,
        genderStats: action.error ? {} : action.payload,
        inGenderStatsProgress: false
      };
    case AGE_GROUP_STATS_LOADED:
      return {
        ...state,
        ageGroupStats: action.error ? {} : action.payload,
        inAgeGroupStatsProgress: false
      };
    case SESSION_STATS_LOADED:
      return {
        ...state,
        sessionStats: action.error ? {} : action.payload,
        inSessionStatsProgress: false
      };
    case GET_ALL_MENUS:
      return {
        ...state,
        inAllDishProgress: false,
        menus: action.error ? {} : action.payload
      };
    case CHANGE_MENU_STATUS:
        return {
          ...state,
          changeMenuStatusInProgress: false,
          changeMenuStatus: action.error ? {} : action.payload
        };
    case MENU_VARIANT_GROUPS:
        return {
          ...state,
          menuVariantGroupsInProgress: false,
          menuVariantGroups: action.error ? {} : action.payload
        };
    case CHANGE_MENU_VARIANT_GROUP_STATUS:
        return {
          ...state,
          changeMenuVariantGroupStatusInProgress: false,
          changeMenuVariantGroupStatus: action.error ? {} : action.payload
        };
    case CHANGE_MENU_VARIANT_STATUS:
        return {
          ...state,
          changeMenuVariantStatusInProgress: false,
          changeMenuVariantStatus: action.error ? {} : action.payload
        };
    case MENU_VARIANTS:
        return {
          ...state,
          menuVariantsInProgress: false,
          menuVariants: action.error ? {} : action.payload
        };
    case GET_TOP_MENUS:
      return {
        ...state,
        inTopDishProgress: false,
        topMenus: action.error ? {} : action.payload
      };
    case UPDATE_TOP_MENUS:
      return {
        ...state,
        isUpdateMenuProgress: false,
        updateTopMenus: action.error ? {} : action.payload
      };
    case MENUS_UNLOADED:
      return {
        ...state,
        isUpdateMenuProgress: false,
        updateTopMenus:  {},
        topMenuSlotErrors: {}
      };
    case UPDATE_TOP_MENU_SLOT:
      return {
        ...state,
        isUpdateMenuSlotsProgress: false,
        updateTopMenuSlots: action.error ? {} : action.payload,
        topMenuSlotErrors: action.error ? action.payload.error : {},
      };
    case GET_ORDERS:
      return {
        ...state,
        isOrderProgress: false,
        orders: action.error ? {} : action.payload
      };
    case GET_ORDERS_LIST:
        return {
          ...state,
          isOrdersListProgress: false,
          ordersList: action.error ? {} : action.payload
        };
    case GET_USER_ORDER_DETAILS:
          return {
            ...state,
            isGetUserOrderDetailProgress: false,
            userOrderDetails: action.error ? {} : action.payload
          };
    case CHANGE_ORDER_STATUS:
      return {
        ...state,
        isOrderChangeStatusProgress: false,
        orderStatusUpdated: action.error ? {} : action.payload,
        orderStatusUpdatedErrors: action.error ? action.payload.error : {},
      };
    case CONFIRM_ORDERS:
        return {
          ...state,
          isOrderConfirmProgress: false,
          confirmOrders: action.error ? {} : action.payload,
        };
    case COMPUTE_CONFIRM:
        return {
          ...state,
          computeConfirmInProgress: false,
          computeConfirm: action.error ? {} : action.payload,
        };
    case CHANGE_ORDER_STATUS_VALUE:
        return {
          ...state,
          orderStatusChangeInProgress: false,
          orderStatusChange: action.error ? {} : action.payload,
        };
    case RESTAURANT_VIEW:
        return {
          ...state,
          restaurantViewInProgress: false,
          restaurantView: action.error ? {} : action.payload,
        };
    case RESTAURANT_UPDATE:
        return {
          ...state,
          restaurantUpdateInProgress: false,
          restaurantUpdate: action.error ? {} : action.payload,
        };
    case ASYNC_START:
      if (action.subtype === GET_ALL_MENUS) {
        return { ...state, inAllDishProgress: true };
      }
      if (action.subtype === CHANGE_MENU_STATUS) {
        return { ...state, changeMenuStatusInProgress: true };
      }
      if (action.subtype === GET_TOP_MENUS) {
        return { ...state, inTopDishProgress: true };
      }
      if (action.subtype === WALK_IN_STATS_LOADED) {
        return { ...state, inWalkInStatsProgress: true };
      }
      if (action.subtype === REVENUE_STATS_LOADED) {
        return { ...state, inRevenueStatsProgress: true };
      }
      if (action.subtype === SESSION_STATS_LOADED) {
        return { ...state, inSessionStatsProgress: true };
      }
      if (action.subtype === GENDER_STATS_LOADED) {
        return { ...state, inGenderStatsProgress: true };
      }
      if (action.subtype === AGE_GROUP_STATS_LOADED) {
        return { ...state, inAgeGroupStatsProgress: true };
      }
      if (action.subtype === UPDATE_TOP_MENUS) {
        return { ...state, isUpdateMenuProgress: true };
      }
      if (action.subtype === UPDATE_TOP_MENU_SLOT) {
        return { ...state, isUpdateMenuSlotsProgress: true };
      }
      if (action.subtype === GET_ORDERS) {
        return { ...state, isOrderProgress: true };
      }
      if (action.subtype === GET_ORDERS_LIST) {
        return { ...state, isOrdersListProgress: true };
      }
      if (action.subtype === GET_USER_ORDER_DETAILS) {
        return { ...state, isGetUserOrderDetailProgress: true };
      }
      if (action.subtype === CHANGE_ORDER_STATUS) {
        return { ...state, isOrderChangeStatusProgress: true };
      }
      if (action.subtype === CONFIRM_ORDERS) {
        return { ...state, isOrderConfirmProgress: true };
      }
      if (action.subtype === COMPUTE_CONFIRM) {
        return { ...state, computeConfirmInProgress: true };
      }
      if (action.subtype === MENU_VARIANT_GROUPS) {
        return { ...state, menuVariantGroupsInProgress: true };
      }
      if (action.subtype === CHANGE_MENU_VARIANT_GROUP_STATUS) {
        return { ...state, changeMenuVariantGroupStatusInProgress: true };
      }
      if (action.subtype === CHANGE_MENU_VARIANT_STATUS) {
        return { ...state, changeMenuVariantStatusInProgress: true };
      }
      if (action.subtype === RESTAURANT_VIEW) {
        return { ...state, restaurantViewInProgress: true };
      }
      if (action.subtype === RESTAURANT_UPDATE) {
        return { ...state, restaurantUpdateInProgress: true };
      }
      if (action.subtype === MENU_VARIANTS) {
        return { ...state, menuVariantsInProgress: true };
      }
      return { ...state };
    default:
      return state;
  }
};
