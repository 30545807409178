import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from '../reducers/auth';
import common from '../reducers/common';
import home from '../reducers/home';
import profile from '../reducers/profile';
import restaurant from '../reducers/restaurant';
import users from '../reducers/users';
import hoppoints from '../reducers/hoppoints';
import { LOGOUT } from '../constants/actionTypes';

import { createBrowserHistory as createHistory } from 'history';
import SecureLS from "secure-ls";

export const history = createHistory();
const lStorage = new SecureLS();

const appReducer = combineReducers({
  auth,
  common,
  home,
  profile,
  restaurant,
  users,
  hoppoints,
  router: connectRouter(history),
});


const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    const { router, common, restaurant } = state;
    state = { router, common, restaurant };
    lStorage.clear()
  }
  return appReducer(state, action)
}

export default rootReducer;
