export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const UPDATE_FIELD_AUTH = "UPDATE_FIELD_AUTH";

export const HOME_PAGE_LOADED = "HOME_PAGE_LOADED";
export const HOME_PAGE_UNLOADED = "HOME_PAGE_UNLOADED";

export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const REGISTER_PAGE_UNLOADED = "REGISTER_PAGE_UNLOADED";
export const IS_LOGGEDIN = "IS_LOGGEDIN";

export const VENDOR_PROFILE_LOADED = "VENDOR_PROFILE_LOADED";
export const RESTAURANT_LOADED = "RESTAURANT_LOADED";
export const CURRENT_RESTAURANT = "CURRENT_RESTAURANT";
export const RESTAURANT_UNLOADED = "RESTAURANT_UNLOADED";
export const VENDOR_PROFILE_UNLOADED = "VENDOR_PROFILE_UNLOADED";
export const HOPPOINTS_VIEW_SUMMARY_LOADED = "HOPPOINTS_VIEW_SUMMARY_LOADED";
export const HOPPOINTS_VIEW_SUMMARY_UNLOADED = "HOPPOINTS_VIEW_SUMMARY_UNLOADED";
export const HOPPOINTS_TRANSACTIONS_LOADED = "HOPPOINTS_TRANSACTIONS_LOADED";
export const HOPPOINTS_TRANSACTIONS_UNLOADED = "HOPPOINTS_TRANSACTIONS_UNLOADED";

export const WALK_IN_STATS_LOADED = "WALK_IN_STATS_LOADED";
export const REVENUE_STATS_LOADED = "REVENUE_STATS_LOADED";
export const GENDER_STATS_LOADED = "GENDER_STATS_LOADED";
export const AGE_GROUP_STATS_LOADED = "AGE_GROUP_STATS_LOADED";
export const SESSION_STATS_LOADED = "SESSION_STATS_LOADED";
export const WALK_IN_STATS_UNLOADED = "WALK_IN_STATS_UNLOADED";
export const REVENUE_STATS_UNLOADED = "REVENUE_STATS_UNLOADED";
export const GENDER_STATS_UNLOADED = "GENDER_STATS_UNLOADED";
export const AGE_GROUP_STATS_UNLOADED = "AGE_GROUP_STATS_UNLOADED";
export const SESSION_STATS_UNLOADED = "SESSION_STATS_UNLOADED";

export const GET_TOP_MENUS = "GET_TOP_MENUS";
export const GET_ALL_MENUS = "GET_ALL_MENUS";
export const CHANGE_MENU_STATUS = "CHANGE_MENU_STATUS";
export const UPDATE_TOP_MENUS = "UPDATE_TOP_MENUS";
export const MENUS_UNLOADED = "MENUS_UNLOADED";
export const UPDATE_TOP_MENU_SLOT = "UPDATE_TOP_MENU_SLOT";

export const MENU_VARIANT_GROUPS = "MENU_VARIANT_GROUPS";
export const CHANGE_MENU_VARIANT_GROUP_STATUS = "CHANGE_MENU_VARIANT_GROUP_STATUS";
export const CHANGE_MENU_VARIANT_STATUS = "CHANGE_MENU_VARIANT_STATUS";
export const RESTAURANT_VIEW = "RESTAURANT_VIEW";
export const RESTAURANT_UPDATE = "RESTAURANT_UPDATE";
export const MENU_VARIANTS = "MENU_VARIANTS";

export const GET_CRM = "GET_CRM";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";

export const GET_ORDERS_LIST = "GET_ORDERS_LIST";

export const SET_UTILITIES = "SET_UTILITIES";
export const SET_ORDERS = "SET_ORDERS";
export const GET_ORDERS = "GET_ORDERS";
export const GET_USER_ORDER_DETAILS = "GET_USER_ORDER_DETAILS";
export const GET_NOTIFY_DATA = "GET_NOTIFY_DATA";
export const SET_VISITS = "SET_VISITS";

export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
export const CHANGE_ORDER_STATUS_VALUE = "CHANGE_ORDER_STATUS_VALUE";
export const CONFIRM_ORDERS = "CONFIRM_ORDERS";
export const COMPUTE_CONFIRM = "COMPUTE_CONFIRM";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_VISITS = "GET_VISITS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
