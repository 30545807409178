import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';

import "./slidebar.css";

import { Link, withRouter } from "react-router-dom";
import * as routeConfig from '../../config/routeConfig';
import {history} from '../../config/storeConfig';
class Sidebar extends React.Component {
  render() {
    let userId; 
    const isActiveClass = pathName => {
      return this.props.router &&
        this.props.router.location.pathname === pathName
        ? "active"
        : "";
    };
    if(this.props.vendorProfile && this.props.vendorProfile.details){
      userId =  this.props.vendorProfile.details.id;
    }
    return (
      <div className="nav-side-menu">
        <div className="brand" />
        <i
          className="fa fa-bars toggle-btn"
          data-toggle="collapse"
          data-target="#menu-content"
        />
        <div className="menu-list">
          <ul className="menu-content collapse out">
            <li className={isActiveClass("/dashboard")}>
              <Link to="/dashboard" className="nav-link">
                <i className="fa fa-home" /> Home
              </Link>
            </li>
            {/* <li className={isActiveClass("/dishes")}>
              <Link to="/dishes" className="nav-link">
                <i className="fa fa-cutlery" /> Top Dishes
              </Link>
            </li> */}
            <li className={isActiveClass("/orders")}>
              <Link to="/orders" className="nav-link">
                <i className="fa fa-file-text" /> New Orders
              </Link>
            </li>
            <li className={isActiveClass("/order-list")}>
              <Link to={`/order-list`} className="nav-link">
                <i className="icmn-file-text2" style={{paddingRight:15 + 'px'}} /> Order Lists
              </Link>
            </li>
            <li className={isActiveClass(routeConfig.globalRoutes.MenusAndAddonsManagement)}>
              <Link to={routeConfig.globalRoutes.MenusAndAddonsManagement} className="nav-link">
                <i className="icmn-file-text2" style={{paddingRight:15 + 'px'}} /> Menus & Addons
              </Link>
            </li>
            {/* <li className={isActiveClass("/crm")}>
              <Link to="/crm" className="nav-link">
                <i className="fa fa-users" /> CRM
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="bottom-menu-list">
          <div className="menu-list">
            <ul className="menu-content collapse out">
              {/* <li>
                <Link to="/dashboard" className="nav-link">
                  <i className="fa fa-cog" /> Setting
                </Link>
              </li> */}
              <li className={isActiveClass("/logout")}>
                <Link to="/logout" className="nav-link">
                  <i className="fa fa-sign-out" /> Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    ordersData: state.common.ordersData,
    vendorProfile: state.profile.vendorProfile,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(Sidebar));
