let host = window.location.host;
let hostName = host.split(':');
const baseUrl = `${window.location.protocol}//${hostName[0]}`;

const  globalVariable = Object.freeze({
  production: true,
  BASE_API_URL: `${baseUrl}/hopticks`,
  RESOURCE_URL: `${baseUrl}/resources`,
  DEFAULT_AVATOR: `${baseUrl}/resources/images/default-image.jpg`,
  MIDDLEWARE_URL: `${baseUrl}:3001/api`,
  // MIDDLEWARE_URL: `${baseUrl}/middleware/api`,
  MIDDLEWARE_SOCKET_URL: `${baseUrl}:3001`,
  // MIDDLEWARE_SOCKET_URL: `${baseUrl}/middleware`,
  DEV_ONESIGNAL_USERS_APP_ID:"d39b3621-b9a8-4425-b1d0-a8cbd5c9cc91",
  DEV_ONESIGNAL_USERS_SUBDOMAIN_NAME:"http://localhost:3006",
  DEV_ONESIGNAL_PARTNERS_APP_ID:"d39b3621-b9a8-4425-b1d0-a8cbd5c9cc91",
  DEV_ONESIGNAL_PARTNERS_SUBDOMAIN_NAME:"http://localhost:3006",

  PROD_ONESIGNAL_USERS_APP_ID:"8f8bdc7e-ac9f-4357-b2d5-8bec6df4081b",
  PROD_ONESIGNAL_USERS_SUBDOMAIN_NAME:"https://dashboard.hopsticks.com",
  PROD_ONESIGNAL_PARTNERS_APP_ID: "e7a3377f-9120-4961-9304-b8a190777679",
  PROD_ONESIGNAL_PARTNERS_SUBDOMAIN_NAME:"https://dashboard.hopsticks.com"

});

export default  globalVariable;
