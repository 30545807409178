import React from 'react'
import "./login.css";
import { Form, Input, Button } from "antd";

import {
	LOGIN,
	LOGIN_PAGE_UNLOADED
} from '../../constants/actionTypes';

import agent from '../../agent';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
const FormItem = Form.Item;

class Login extends React.Component {

	constructor(props){
		super(props);
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, credentials) => {
		  if (!err) {
			this.props.onSubmit(credentials);
		  }
		});
	  };

	componentWillUnmount() {
		this.props.onUnload();
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div className="login-page-container">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-sm-12 col-md-6 col-lg-5 col-xl-5">
							<div className="login-form-container">
								<div className="login-welcome-title">Welcome to Hopsticks Dashboard!</div>
								<div className="login-sub-title">Manage your Business with Ease</div>
								<div className="login-form-content">
									<div className="login-form-header">
										<div className="login-form-header-logo">
											<Link to="/dashboard" className="nav-link">
												<img className="img-fluid" src={require('../../resources/images/logo.png')} alt="" /></Link>
											<span className="login-form-header-logo-text">for Business</span>
										</div>
									</div>
									<div className="login-form-inner-content">
										<Form onSubmit={this.handleSubmit}>
											<FormItem>
												{getFieldDecorator("username", {
													rules: [
														{
															type: "email",
															message: "Please input valid E-mail!"
														},
														{
															required: true,
															message:
																"Please input your Email!"
														}
													]
												})(
													<Input size="large" placeholder="Username" />
												)}
											</FormItem>
											<FormItem>
												{getFieldDecorator("password", {
													rules: [
														{
															required: true,
															message:
																"Please input your Password!"
														}
													]
												})(
													<Input.Password size="large" placeholder="Password" />
												)}
											</FormItem>
											<Button htmlType="submit" className="button-lg font-400 ripple-btn-pink pink-button-md mt-1" loading={this.props.inProgress} >
												Login
        									</Button>
											
										</Form>
									</div>
								</div>
								<div className="login-form-bottom-set">
									<div className="row">
										<div className="col text-center">
											<div className="login-form-bottom-link">
												<Link to="/dashboard" className="nav-link">
													Forgot your Password?
											</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


const mapStateToProps = state => {
	return {
		inProgress: state.auth.inProgress
	}
};

const mapDispatchToProps = dispatch => ({
	onSubmit: (requestBody) =>
		dispatch({ type: LOGIN, payload: agent.Auth.login(requestBody) }),
	onUnload: () =>
		dispatch({ type: LOGIN_PAGE_UNLOADED })
});

const WrappedNormalForm = Form.create({ name: "normal_sign_in_form" })(Login);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalForm);