import React from "react";
import { connect } from "react-redux";
import renderMergedProps from "./renderMergedProps";
import { Route } from "react-router-dom";
import { APP_LOAD } from "../constants/actionTypes";
import Page from "../pages";


/**
 * Ensure the user is authenticated before they can view this route
 *
 * @class ProtectedRoute
 * @extends {React.Component}
 */

const mapStateToProps = state => {
    return {
        appLoaded: state.common.appLoaded,
        appName: state.common.appName,
        currentUser: state.common.currentUser,
        redirectTo: state.common.redirectTo,
        isLoggedIn: state.common.isLoggedIn
    }
};

const mapDispatchToProps = dispatch => ({
    onLoad: (payload, token) =>
        dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
});

class DefaultRoutes extends React.Component {
    constructor(props) {
        super(props)
    }


    componentWillMount() {
        this.props.onLoad();
    }

    render() {
        const { isLoggedIn, component: Component, ...rest } = this.props;

        return (
            <Page {...rest} render={routeProps => {
                return renderMergedProps(Component, routeProps, { ...rest });
            }} />
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DefaultRoutes);