import socketIOClient from "socket.io-client";

import globalVariable from "./generalConfig";
// module.exports = global.config = {
//     i18n: {
//         welcome: {
//             en: "Welcome",
//             fa: "خوش آمدید",
//         }
//         // rest of your translation object
//     },
//     socket: socketIOClient(globalVariable.MIDDLEWARE_SOCKET_URL, {
//         autoConnect: false
//     })
//     // other global config variables you wish
// };

export default global.config = {
    socket: socketIOClient(globalVariable.MIDDLEWARE_SOCKET_URL, {
        autoConnect: false
    })
};