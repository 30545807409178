import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PageHeader from '../components/Header/header';
import PageSidebar from '../components/Sidebar/sidebar';
import { APP_LOAD, REDIRECT } from "../constants/actionTypes";
import renderMergedProps from "./renderMergedProps";
import Page from "../pages";
/**
 * Ensure the user is authenticated before they can view this route
 *
 * @class ProtectedRoute
 * @extends {React.Component}
 */

const mapStateToProps = state => {
    return {
        appLoaded: state.common.appLoaded,
        appName: state.common.appName,
        currentUser: state.common.currentUser,
        redirectTo: state.common.redirectTo,
        isLoggedIn: state.common.isLoggedIn
    }
};

const mapDispatchToProps = dispatch => ({
    onLoad: (payload, token) =>
        dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
});

class PublicRoutes extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.props.onLoad();
    }
    render() {
        const { isLoggedIn, component: Component, ...rest } = this.props;
        const isAuth = localStorage.getItem('userLoggedInStatus') !== '' ? JSON.parse(localStorage.getItem('userLoggedInStatus')) : false;
        
        return (
            <Page {...rest} render={ routeProps => {
                return isAuth || isLoggedIn? (
                    <Redirect to={{
                        pathname: '/dashboard',
                        state: { from: routeProps.location }
                    }}/>
                ) : (
                    renderMergedProps(Component, routeProps, {...rest})
                );
            }}/>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PublicRoutes);