// Routes Configuration
export const globalRoutes = Object.freeze({
  login: '/login',
  register: '/register',
  dashboard: '/dashboard',
  orders: '/orders/',
  MenusAndAddonsManagement: '/menus-and-addons-management',
  ordersList: '/order-list',
  dishes: '/dishes',
  CRM: '/crm',
  transactions: '/transactions',
  logout: '/logout',
  notFound: '/not-found',
  noRestaurants: '/no-restaurants',
  orderModel: '/order-model',
  zeroHourDeal: '/zero-hour',
  zeroHourForm: '/zero-hours-deals-form',
});
