export const columnChartConfig = {
  walkInOptions: {
    title: "Walk-in stats",
    vAxis: { title: "No of walkins" },
    hAxis: { title: "Hour" },
    seriesType: "bars",
    series: { 1: { type: "line", color: "#493479", areaOpacity: 1 } },
    colors: ["#eb3d73"],
    sliceVisibilityThreshold: 0,
    bar: {
      groupWidth: 30
    }
  },
  revenueOptions: {
    title: "Revenue stats",
    vAxis: { title: "Revenue amount in INR" },
    hAxis: { title: "Hour" },
    seriesType: "bars",
    series: { 1: { type: "line", color: "#493479", areaOpacity: 1 } },
    colors: ["#eb3d73"],
    sliceVisibilityThreshold: 0,
    bar: {
      groupWidth: 30
    }
  }
};