import React, { Component }  from 'react';

const WalkInChartLoader = () => (
  <div className="top-graph content-placeholder bar-chart-placeholder">
    <div className="pb-chart">
      <div className="pb-bar-45" />
      <div className="pb-bar-100" />
      <div className="pb-bar-63" />
      <div className="pb-bar-11" />
      <div className="pb-bar-46" />
      <div className="pb-bar-88" />
      <div className="pb-bar-35" />
      <div className="pb-bar-11" />
      <div className="pb-bar-78" />
      <div className="pb-bar-91" />
      <div className="pb-bar-55" />
      <div className="pb-bar-16" />
    </div>
  </div>
);

const RevenueChartLoader = () => (
  <div className="top-graph content-placeholder bar-chart-placeholder">
    <div className="pb-chart">
      <div className="pb-bar-45" />
      <div className="pb-bar-100" />
      <div className="pb-bar-63" />
      <div className="pb-bar-11" />
      <div className="pb-bar-46" />
      <div className="pb-bar-88" />
      <div className="pb-bar-35" />
      <div className="pb-bar-11" />
      <div className="pb-bar-78" />
      <div className="pb-bar-91" />
      <div className="pb-bar-55" />
      <div className="pb-bar-16" />
    </div>
  </div>
);

const SessionChartLoader = () => (
  <div className="pie-chart-placeholder content-placeholder">
    <div className="pie-container">
      <div id="One" className="pie" />
      <div id="Two" className="pie" />
      <div id="Three" className="pie" />
      <div id="Four" className="pie" />
    </div>
    <div className="stats">
      <ul>
        <li data-name="Chrome" className="p-bg" />
        <li data-name="Firefox" className="p-bg" />
        <li data-name="Internet Explorer" className="p-bg" />
        <li data-name="Safari" className="p-bg" />
      </ul>
    </div>
  </div>
);

const AgeGroupChartLoader = () => (
  <div className="pie-chart-placeholder content-placeholder">
    <div className="pie-container">
      <div id="One" className="pie" />
      <div id="Two" className="pie" />
      <div id="Three" className="pie" />
      <div id="Four" className="pie" />
    </div>
    <div className="stats">
      <ul>
        <li data-name="Chrome" className="p-bg" />
        <li data-name="Firefox" className="p-bg" />
        <li data-name="Internet Explorer" className="p-bg" />
        <li data-name="Safari" className="p-bg" />
      </ul>
    </div>
  </div>
);

const GenderChartLoader = () => (
  <div className="pie-chart-placeholder content-placeholder">
    <div className="pie-container">
      <div id="One" className="pie" />
      <div id="Two" className="pie" />
      <div id="Three" className="pie" />
      <div id="Four" className="pie" />
    </div>
    <div className="stats">
      <ul>
        <li data-name="Chrome" className="p-bg" />
        <li data-name="Firefox" className="p-bg" />
        <li data-name="Internet Explorer" className="p-bg" />
        <li data-name="Safari" className="p-bg" />
      </ul>
    </div>
  </div>
);

export { WalkInChartLoader, SessionChartLoader, AgeGroupChartLoader, GenderChartLoader, RevenueChartLoader}