import Audic from 'audic';
import {notification} from 'antd';
import _ from 'lodash';
import AppReUsableComponents from '..';

// this func triggers sound and notification when it gets initialized
const soundNotification = (data={}) => {
    // sound notification declaration
    audioNotification(data);
    // text notification declaration
    textNotification(data);
};

const audioNotification = data => {
    const Audio = {
        url: require('./assets/sounds/bell.mp3'),
        // definig the availability of the audioNotification true = ON and false = OFF
        status: true, 
        ..._.get(data, 'audio', {})
    };
    AppReUsableComponents.general.callbackIfTrue(_.get(Audio, 'status'), ()=>{
        const audioUrl = _.get(Audio, 'url');
        const AudicSoundPlayer = new Audic(audioUrl);
        AudicSoundPlayer.play();
    });
};

const textNotification = data => {
    const availableNotificationTypes = ['success', 'info', 'warning', 'error'];
    const Notification = {
        status: true,
        ..._.get(data, 'notification', {}),
        config: {
            message: 'Notification',
            description: 'A new order has been received.',
            ..._.get(data, 'notification.config', {})
        }
    };
    AppReUsableComponents.general.callbackIfTrue(_.get(Notification, 'status'), ()=>{
        let notificationType = _.get(Notification, 'type', null);
        notificationType = _.includes(availableNotificationTypes, notificationType) ? notificationType: _.nth(availableNotificationTypes, 0);
        const notificationConfig = _.get(Notification, 'config');
        notification[notificationType](notificationConfig);
    });
};

export default soundNotification;