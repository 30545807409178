import React from "react";
import { connect } from "react-redux";
import "./hourDeal.css";
import { Select, Input, Modal, Button, notification, Table, Switch, Collapse, label, Form, Radio, DatePicker, TimePicker  } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import agent from "../../agent";
import globalVariable from "../../config/generalConfig";
import moment from 'moment';
const FormItem = Form.Item
const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
function onChange(date, dateString) {
  console.log(date, dateString);
}
class zeroHourForm extends React.Component {
  state = {
    value: 1,
  };

  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      value: e.target.value,
    });
  };
    render() {
        return (
            <div className="hour-deal-form-container">
            <div className="container">
              <div className="hour-deal-form">
                <div className="row">
                  <div className="col-lg-4">
                  <FormItem>
                    <label className="deal-label">Choose menu item</label>
                    <Select defaultValue="burger" style={{ width: 100 + '%' }} onChange={handleChange}>
                      <Option value="burger">Burger</Option>
                      <Option value="shawarma">Shawarma</Option>
                      <Option value="grill">Grill</Option>
                      <Option value="biriyani">Biriyani</Option>
                    </Select>
                  </FormItem>
                  </div>
                  <div className="col-lg-4">
                  <FormItem>
                    <label className="deal-label">Deal Type</label>
                    <Radio.Group onChange={this.onChange} value={this.state.value}>
                      <Radio value={1}>Price</Radio>
                      <Radio value={2}>Discount</Radio>
                      <Radio value={3}>1+1</Radio>
                    </Radio.Group>
                  </FormItem>
                  </div>
                  <div className="col-lg-4">
                  <FormItem>
                    <label className="deal-label">Price</label>
                    <Input placeholder="ex: Rs:200" style={{ width: 150 + 'px' }} />
                  </FormItem>
                  <FormItem className="d-none">
                    <label className="deal-label">Discount </label>
                    <Input placeholder="ex: 15%" style={{ width: 150 + 'px' }} />
                  </FormItem>
                  <FormItem className="d-none">
                    <label className="deal-label">1+1</label>
                    <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked />
                  </FormItem>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                  <FormItem>
                    <label className="deal-label">Start Date</label>
                    <DatePicker onChange={onChange} style={{ width: 100 + '%' }} />
                  </FormItem>
                  </div>
                  <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <FormItem>
                        <label className="deal-label">Start Time</label>
                        <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} style={{ width: 100 + '%' }} />
                      </FormItem>
                    </div>
                    <div className="col-lg-6">
                      <FormItem>
                        <label className="deal-label">End Time</label>
                        <TimePicker onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}  style={{ width: 100 + '%' }} />
                      </FormItem>
                    </div>
                  </div>
                  </div>
                  <div className="col-lg-4">
                  <FormItem>
                    <label className="deal-label">Available Quantity</label>
                    <Input placeholder="ex: 8" style={{ width: 100 + '%' }} />
                  </FormItem>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <Button className="btn btn-lg btn-submit" type="primary">Submit</Button>
                  </div>
                </div>
              </div>
            </div>
            </div>
        );
    }
}
export default zeroHourForm