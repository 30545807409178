export const doughnutChartConfig = {
    data: [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7] // CSS-style declaration
    ],
    data1: [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7] // CSS-style declaration
    ],
    data2: [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7] // CSS-style declaration
    ],
    ageGrupOptions: {
        pieHole: 0.4,
        is3D: false,
        slices: {0: {color: '#dd2959'}, 1:{color: '#f64071'}, 2:{color: '#ff7398'}, 3: {color: '#fea9c0'}, 4:{color: '#ffd7e2'}},
        sliceVisibilityThreshold: 0,
        chartArea: {width: 300, height: 300}
    },
    genderOptions: {
        pieHole: 0.4,
        is3D: false,
        slices: {0: {color: '#4e3879'}, 1:{color: '#f64071'}, 2:{color: '#989991'}},
        sliceVisibilityThreshold: 0,
        chartArea: {width: 300, height: 300}
    },
    timeOfDayOptions: {
        pieHole: 0.4,
        is3D: false,
        slices: {0: {color: '#989991'}, 1:{color: '#f64071'}, 2:{color: '#4e3879'}},
        sliceVisibilityThreshold: 0,
        chartArea: {width: 300, height: 300}
    }
}