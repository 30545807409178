import React from "react";
import {Link} from "react-router-dom";
import "./error.css";

class NoRestaurants extends React.Component {
  render() {
    return (
      <div className="error-page-container">
        <div className="container">
          <div className="error-inner-content">
            <div className="error-img">
              <img
                className="img-fluid"
                src={require("../../resources/images/404-img.png")}
              />
            </div>
            <div className="error-page-title">Whoops!</div>
            <div className="error-page-text">
              You don't have any restaurants. Please contact the administrator.{" "}
              <Link to={'/logout'}>Click here</Link> to logout.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoRestaurants;
