import Moment from "moment";

export function capitalizeWord(value) {
  if (typeof value !== "string") return "";
  return value
    .toLowerCase()
    .split(",")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(", ");
}

export function formatDate(value, format = "DD/MM/YYYY") {
  return Moment(value).format(format);
}

export function getCurrentDateTime(format = "DD/MM/YYYY") {
  return Moment().format(format);
}

export function convertArrayCommaSeperated(
  arrayObjects,
  itemName,
  separator = ", "
) {
  if (!arrayObjects) return "";
  return Array.prototype.map
    .call(arrayObjects, function(item) {
      return capitalizeWord(item[itemName]);
    })
    .join(separator)
    .replace(/,(?!.*,)/gim, " &");
}

export function toSubtractDate(value) {
  if (value) {
    const currentDate = new Date();
    const timestamp = currentDate.setHours(currentDate.getHours() - value);
    return timestamp ? new Date(timestamp).toISOString() : "";
  } else {
    return new Date().toISOString();
  }
}

export function secondsToTime(secs) {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const timeObject = {
    h: hours ? hours : '00',
    m: minutes ? minutes : '00',
    s: seconds ? seconds :'00'
  };

  return timeObject;
}
