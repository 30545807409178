import React from 'react';
import { Table, Switch, Input, message } from 'antd';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';

import agent from "../../../agent";
import {
    GET_ALL_MENUS, CHANGE_MENU_STATUS
} from "../../../constants/actionTypes";

const { Search } = Input;
  

class MenusManagement extends React.Component {
    constructor(props) {
		super(props);
		this.onChangeSearchValue = debounce(this.onChangeSearchValue, 1200);
	}
    state={
        menusList: [],
        processing: false,
        searchMenu:'',
        menuListApiParams: {limit: 1000, sort: 'updated_at.desc'}
    }
    columns = [
        {
          title: 'Item',
          dataIndex: 'name',
        },
        {
          title: 'Price',
          dataIndex: 'price',
        },
        {
          title: 'Availability',
          key: 'availability',
          render: (text, record) => (
            <span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={!!+_.get(record, 'status', 0)}
                  onChange={checked=>this.onChangeMenuAvailability(checked, _.get(record, 'id', null))}
                />
            </span>
          ),
        },
    ];
    componentDidMount(){
        this.fetchRestaurentMenus();
    }
    fetchRestaurentMenus = () => {
        this.setState({processing: true});
        this.props.getRestaurentMenus({..._.get(this, 'state.menuListApiParams', {}), status:0}, (success, data)=>{
            let buildMenusList = _.get(data, 'items', []);
            this.props.getRestaurentMenus({..._.get(this, 'state.menuListApiParams', {}), status:1}, (success, data)=>{
                buildMenusList = _.concat(buildMenusList, _.get(data, 'items', []));
                this.setState({menusList: buildMenusList});
                this.setState({processing: false});
            });
        });
    }
    updateMenuAvailability = (availability, menuId) =>{
        let modifyMenuList = _.get(this, 'state.menusList',[]);
        const menuIndex = _.findIndex([...modifyMenuList], {id: menuId});
    
        if(menuIndex>-1){
            modifyMenuList[menuIndex]={...modifyMenuList[menuIndex],status: Number(availability)};
            this.setState({menusList: modifyMenuList});
        }
    }
    onChangeMenuAvailability = (checked, menuId) => {
        this.updateMenuAvailability(checked, menuId);
        this.setState({processing: true});
        this.props.changeRestaurentMenuStatus({id: menuId, status: Number(checked), status_glossary: "Restaurent have been changed the menu status"}, (success, data)=>{

        const defaultMessage = 'Menu status changed successfully.';
            if(success){
                message.success(_.get(data, 'message', defaultMessage));
            }else {
                // this.updateMenuAvailability(!checked, menuId);
            }
            this.setState({processing: false});
        });
    }
    buildTableDataSource = (menuList, searchValue) => {
        console.log('search value: ', searchValue);
        if(_.isEmpty(searchValue)){
            return menuList;
        }else {
            return _.filter(menuList, menuItem=>{
                const menuItemName = _.toLower(_.get(menuItem, 'name', ""));
                searchValue = _.toLower(searchValue);
                console.log(`_.includes(${menuItemName}), ${searchValue}: ${ _.includes(menuItemName, searchValue)}`);
                return _.includes(menuItemName, searchValue);
            });
        }
    }
    onChangeSearchValue = value => {
        value = _.trim(value);
        this.setState({searchMenu: value});
    }
    mainComponent=()=>{
        return (
            <React.Fragment>
                <div className="full-width">
                    <div className="row justify-content-end mb-3">
                        <div className="col-3">
                            <Search placeholder="Search here" onSearch={value => console.log(value)} style={{ width: '100%' }} allowClear onChange={value=>this.onChangeSearchValue(_.get(value, 'target.value', null))} />
                        </div>
                    </div>
                </div>
                <div className="full-width">
                <Scrollbars style={{height: 400 }}>
                    <Table loading={_.get(this, 'state.processing', false)} rowKey='id' dataSource={this.buildTableDataSource(_.get(this,'state.menusList', []), _.get(this, 'state.searchMenu', ''))} columns={this.columns} pagination={false} />
                </Scrollbars>
                </div>
            </React.Fragment>
        );
    }
    render(){
        return this.mainComponent();
    }
}
const mapStateToProps = state => ({restaurant: _.get(state, 'restaurant', {})});

const mapDispatchToProps = dispatch => ({
    getRestaurentMenus: (queryParams, callback) => dispatch({ type: GET_ALL_MENUS, payload: agent.Retaurant.menus(queryParams), callback: callback }),
    changeRestaurentMenuStatus: (bodyParams, callback) => dispatch({ type: CHANGE_MENU_STATUS, payload: agent.Retaurant.changeMenuStatus(bodyParams), callback: callback })
});

export default connect(mapStateToProps, mapDispatchToProps)(MenusManagement);