import React from "react";

import {
  LOGOUT,
  LOGIN_PAGE_UNLOADED,
  APP_LOAD
} from "../../constants/actionTypes";

import agent from "../../agent";
import { connect } from "react-redux";
class Logout extends React.Component {
  constructor() {
    super();
  }

  componentWillMount() {
    this.props.onLoad();
    this.props.logout();
  }

  componentWillUnmount() {
    // var OneSignal = window.OneSignal || [];
    // OneSignal.push(function() {
	  //    OneSignal.deleteTag("id");
    //    OneSignal.deleteTag("name");
    //    OneSignal.deleteTag("city_id");
    // });
    // this.props.onUnload();
  }

  render() {
    return (
      <div className="login-page-container">
      Loading...
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
        dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  logout: () =>
    dispatch({ type: LOGOUT, payload: agent.Auth.logout() }),
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED })
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
