import React from "react";
import { connect } from "react-redux";
import "./orders.css";
import { Select, Input, Modal, Button, notification } from "antd";
import queryString from "query-string"
import { Link } from "react-router-dom";
import { store } from "../../config/storeConfig";
import { push, replace } from "react-router-redux";
import moment from 'moment';
import _ from 'lodash';

import SecureLS from "secure-ls";
import randomColor from "randomcolor";
import { Random } from "random-js";
import {
  convertArrayCommaSeperated,
  toSubtractDate
} from "../../utils/Helpers";
import {
  SET_UTILITIES,
  SET_ORDERS,
  GET_ORDERS,
  GET_NOTIFY_DATA,
  SET_VISITS,
  CHANGE_ORDER_STATUS,
  CLEAR_ERRORS,
  GET_VISITS,
  GET_USER_DETAILS,
  GET_USER_ORDER_DETAILS,
} from "../../constants/actionTypes";
import UtilitiesModal from "./utilities-modal";
import VisitModal from "./visit-modal";
import OrderModal from "./order-modal";
import agent from "../../agent";
import OrderNotification from "./notification";
import globalVariable from "../../config/generalConfig";
import Scrollbars from "react-custom-scrollbars";

let reconnection = true;
let reconnectionDelay = 5000;
let reconnectionTry = 0;

const lStorage = new SecureLS();
const random = new Random();
const randomValue = random.integer(1, 1000);

class Orders extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
  }

  state = {
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
    endpoint: globalVariable.MIDDLEWARE_SOCKET_URL,
    orderUtilities: [],
    utilitiesModal: false,
    selectedUtilitiesData: {},
    sortBy: 'created_at.desc',
    orders: [],
    isNotificationEnabled: false,
    notificationData: [],
    currentUtilityIndex: null,
    visits: [],
    orderMeta: {},
    selectedOrderData: {},
    visitsData: {},
    visitsMeta: {},
    selectedVisitData: {},
    tableId: "",
    currentTime: moment().format('HH:mm'),
  };

  setUtilitiesModal = (utilitiesModal, data, index) => {
    this.setState({
      utilitiesModal
    });
    if (data) {
      this.setState({
        selectedUtilitiesData: data,
        currentUtilityIndex: index
      });
    }
  };

  setVisitModal = async (visitModal, data) => {
    this.setState({
      visitModal
    });
    if (data) {
      await this.setState({
        selectedVisitData: data
      });
      this.onGetUserDetails();
    }
    if (data && data.data && data.data.data && data.data.data.data) {
      await this.setState({
        selectedVisitData: data.data.data.data
      });
      this.onGetUserDetails();
    }
  };

  setOrderModal = async (orderModal, data) => {
    this.setState({
      orderModal
    });
    if (data) {
      await this.setState({
        selectedOrderData: data
      });
      this.onGetUserOrderDetails(data);
    }
    if (data && data.data && data.data.data && data.data.data.data) {
      await this.setState({
        selectedVisitData: data.data.data.data
      });
      this.onGetUserOrderDetails(data.data.data.data);
    }
  };
  // Default Call
  async componentDidMount() {
    console.log('order page is loaded for a while.', global.config.socket.connected);
    this.interval = setInterval(() => {
      //  new orders details will retrieved for every 10 secs
      this.onGetOrders();
    }, 10000);
    this._isMounted = true;
    await this.setState({
      orderUtilities:
        lStorage.get("utilities").length > 0 ? lStorage.get("utilities") : []
    });

    if (
      this.props.currentRestaurant &&
      this.props.currentRestaurant.details &&
      this.props.currentRestaurant.details.id
    ) {
      this.onGetOrders();
      this.onGetVisits();
    }
    if (this.props.tableId) {
      this.onGetTableId();
    }
    this.routesClient();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  routesClient = () => {
    const socket = global.config.socket;
    // console.log("connected");
    socket.on("utilitiesClient", data => {
      // notify user
      if (
        this.props.currentRestaurant &&
        this.props.currentRestaurant.details &&
        this.props.currentRestaurant.details.id
      ) {
        if (data.sender_id === this.props.currentRestaurant.details.id) {
          this.props.onSetNotifyData({ ...data, timeout: 3000 });
          let orderUtilities =
            lStorage.get("utilities").length > 0
              ? [...lStorage.get("utilities")]
              : [];
          if (data !== undefined) {
            const color = randomColor();
            const orderUtilitiesTemp = [
              ...orderUtilities,
              { ...data, color: color, timeout: 3000 }
            ];
            lStorage.set("utilities", orderUtilitiesTemp);
            this.props.onSetUtilityData(orderUtilitiesTemp);
          }
        }
      }
    });

    socket.on("ordersClient", data => {
      console.log('New Order Have Been Recieved - ', data);

      // notify user
      if (
        this.props.currentRestaurant &&
        this.props.currentRestaurant.details &&
        this.props.currentRestaurant.details.id
      ) {
        this.onGetOrders();
        if (data.sender_id === this.props.currentRestaurant.details.id) {
          if (data !== undefined) {
            const tempInfo = { ...data.data };
            const tempMeta = { ...data.data.data.meta };
            const tempOrder = { ...data.data.data.order };
            const tempUser = { ...data.data.data.user };
            const combinedOrderInfo = {
              ...tempOrder,
              user: tempUser,
              meta: tempMeta
            };
            const finalResult = {
              data: {
                ...tempInfo,
                data: { data: { ...combinedOrderInfo } },
                timeout: 3000
              }
            };

            // this.props.onSetNotifyData(finalResult);
            const ordersTemp = [{ ...finalResult }, ...this.state.orders];
            console.log('new order have been created');
            this.props.onSetOrderData(ordersTemp);
          }
        }
      }
    });

    socket.on("visitsClient", data => {
      // notify user
      if (
        this.props.currentRestaurant &&
        this.props.currentRestaurant.details &&
        this.props.currentRestaurant.details.id
      ) {
        if (data.sender_id === this.props.currentRestaurant.details.id) {
          if (data !== undefined) {
            const tempInfo = { ...data.data };
            const tempVisit = { ...data.data.data.visit };
            const tempUser = { ...data.data.data.user };
            const combinedOrderInfo = { ...tempVisit, user: tempUser };
            const finalResult = {
              data: {
                ...tempInfo,
                data: { data: { ...combinedOrderInfo } },
                timeout: 3000
              }
            };
            this.props.onSetNotifyData(finalResult);
            const visitsTemp = [{ ...finalResult }, ...this.state.visits];
            this.props.onSetVisitsData(visitsTemp);
          }
        }
      }
    });

    // socket.on("disconnect", () => {
    //   // console.log("client disconnected");
    //   if (reconnection === true) {
    //     setTimeout(() => {
    //       // console.log("client trying reconnect");
    //       this.connectClient();
    //     }, reconnectionDelay);
    //   }
    // });
  };
  // Each Update
  async componentDidUpdate(prevProps) {
    const { orders, utilitiesData, ordersData, visitsData, tableIdData, orderStatusUpdated, visits, confirmOrders, currentRestaurant, notifyData } = this.props;
    // console.log(prevProps.orders, orders)
    if (prevProps.utilitiesData !== utilitiesData) {
      if (utilitiesData && utilitiesData.length > 0) {
        await this.setState({
          orderUtilities: [...utilitiesData]
        });
      }
    }

    if (
      prevProps.ordersData !== ordersData &&
      ordersData
    ) {
      await this.setState({
        orders: [...ordersData]
      });
    }

    if (
      prevProps.visitsData !== visitsData &&
      visitsData
    ) {
      await this.setState({
        visits: [...visitsData]
      });
    }
    if (
      prevProps.tableIdData !== tableIdData &&
      tableIdData
    ) {
      await this.setState({
        tableId: [...tableIdData]
      });
    }
    if (
      prevProps.orderStatusUpdated !== orderStatusUpdated &&
      orderStatusUpdated
    ) {
      await this.setState({
        orders: [],
        ordersData: []
      });
    }
    if (prevProps.orders !== orders && (orders &&
      orders.items &&
      orders.items.length == 0)) {
      await this.setState({
        orders: [],
        ordersData: []
      });
    }
    if (
      prevProps.orders !== orders &&
      (orders &&
        orders.items &&
        orders.items.length > 0)
      // || (this.props.orders.length > prevProps.orders.length) 
    ) {
      await this.setState({
        orders: orders.items,
        // orders: [...this.state.orders, ...this.props.orders.items],
        ordersData: orders.meta && orders.meta
      });
    }
    if (
      prevProps.visits !== visits &&
      (visits &&
        visits.items &&
        visits.items.length > 0)
    ) {
      await this.setState({
        visits: [...this.state.visits, ...visits.items],
        visitsMeta: visits.meta && visits.meta
      });
    }
    // if ((prevProps.userOrderDetails && 
    //   prevProps.userOrderDetails.details && 
    //   prevProps.userOrderDetails.details.status) != 
    //   this.props.userOrderDetails &&
    //   this.props.userOrderDetails.details &&
    //   this.props.userOrderDetails.details.status) {
    //   // this.onGetOrders();
    // }
    if (prevProps.orderStatusUpdated != orderStatusUpdated) {
      if (orderStatusUpdated && orderStatusUpdated.details &&
        orderStatusUpdated.details.id) {
        this.onGetOrders();
      }
    }
    if (prevProps.confirmOrders != confirmOrders) {
      if (confirmOrders && confirmOrders.details &&
        confirmOrders.details.id) {
        this.onGetOrders();
      }
    }

    if (
      prevProps.currentRestaurant !== currentRestaurant &&
      currentRestaurant &&
      currentRestaurant.details
      &&
      currentRestaurant.details.id
    ) {
      this.onGetOrders();
      this.onGetVisits();
    }
    if (this.props.tableId) {
      this.onGetTableId();
    }

    if (prevProps.notifyData !== notifyData) {
      if (
        notifyData &&
        Object.keys(notifyData).length > 0
      ) {
        await this.setState({
          isNotificationEnabled: true,
          notificationData: [
            notifyData,
            ...this.state.notificationData
          ]
        });
      }
    }
  }
  // Orders
  onGetOrders = async () => {
    let requestParams = {
      sort: this.state.sortBy,
      restaurant: _.get(this, 'props.currentRestaurant.details.id', null),
      expand: "user,discounts",
      start_date: toSubtractDate(4),
      // status: "1,2,5,8,9",
      status: "1,7,8",
      limit: 300
    };
    this.props.onGetOrders(agent.Retaurant.orders(requestParams));
  };
  // SortBy Select
  onChangeSort = async (value) => {
    await this.setState({ sortBy: value });
    this.onGetOrders();
  }

  onGetVisits = () => {
    let requestParams = {
      sort: "entered_at.desc",
      restaurant: this.props.currentRestaurant.details.id,
      expand: "user,discounts",
      user_type: 1,
      entered_at: toSubtractDate(4)
    };
    this.props.onGetVisits(agent.Users.visits(requestParams));
  };
  // Search Table
  onGetTableId = (e) => {
    const tableId = e.target.value;
    this.setState({ tableId })
  };

  handleKeyPressTableId = (e) => {
    const tableId = e.target.value;
    if (Object.keys(tableId).length > 0) {
      let requestParams = { expand: "user", table: tableId, };
      const string = queryString.parse(this.props.location.search);
      string.table = tableId;
      store.dispatch(replace({
        pathname: this.props.location.pathname,
        search: queryString.stringify(string)
      }));
      this.props.onGetOrders(agent.Retaurant.orders(requestParams));
    }
  };
  //  view user details
  onGetUserDetails = () => {
    if (this.state.selectedVisitData && this.state.selectedVisitData.id) {
      let requestParams = {
        id: this.state.selectedVisitData.user.id,
        expand: "account,details,allergies,food_preference"
      };
      this.props.onGetUserDetails(agent.Users.view(requestParams));
    }
  };
  //  view orders details
  onGetUserOrderDetails = (value) => {
    if (value && value.id) {
      let requestParams = {
        id: value.id,
        expand: "user,details,items,discounts,transactions,"
      };
      this.props.onGetUserOrderDetails(agent.Retaurant.view(requestParams));
    }
    let requestP = {
      id: _.get(value, 'user.id', null),
      expand: "account,details,allergies,food_preference"
    };
    this.props.onGetUserDetails(agent.Users.view(requestP));

  };

  onRemovedUtilityItems = async items => {
    await this.setState({
      orderUtilities: [...items]
    });
    this.setUtilitiesModal(false);
  };

  onNotificationClose = () => {
    this.setState({
      notificationData: []
    });
  };

  onRemoteNotificationItem = async currentNotifyItemIndex => {
    if (currentNotifyItemIndex > -1) {
      const tempNotifyData = [...this.state.notificationData];
      tempNotifyData.splice(currentNotifyItemIndex, 1);
      await this.setState({
        notificationData: [...tempNotifyData]
      });
    }
  };

  onSubmitOrder = requestParams => {
    const urlParams = {};
    urlParams.id =
      this.state.selectedOrderData && this.state.selectedOrderData.id;
    this.props.onChangeOrderStatus(
      agent.Retaurant.changeOrderStatus(requestParams, urlParams)
    );
  };

  render() {
    const { tableId, orders } = this.state;
    // console.log(orders);
    const { Option } = Select;
    let pp = [];
    const incomingRequestLength =
      this.state.orderUtilities.length + this.state.visits.length;
    return (
      <div className="">
        <div className="orders-page-container">
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-6 col-xl-6">
              <div className="section-title">Orders</div>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6">
              <div className="rating-and-count-filter-set">
                <div className="order-sort-filter">
                  <button className="rating-filter-icon">
                    <i className="fa fa-filter"></i>
                  </button>
                </div>
                <div className="order-sort-filter">
                  <span className="filter-label">Sort by</span>
                  <Select
                    showSearch
                    style={{ width: 134 }}
                    placeholder="Table Number"
                    onChange={value => this.onChangeSort(value)}
                  >
                    <Option value="total.desc">Price High to Low</Option>
                    <Option value="total.asc" >Price Low to High</Option>
                    <Option value="created_at.asc">Recent Orders</Option>
                  </Select>
                </div>
                <div className="order-sort-filter">
                  <span className="filter-label">Table Id</span>
                  <Input
                    type="text"
                    value={tableId}
                    style={{ width: 134 }}
                    placeholder="Table Id"
                    onChange={e => this.onGetTableId(e)}
                    onKeyPress={this.handleKeyPressTableId}
                  />
                </div>
              </div>
            </div>
          </div>
          <Scrollbars style={{ height: 'calc(100vh - 190px)' }}>
            <div className="full-width">
              <div className="row">
                {((!this.props.isOrderProgress && !this.props.currentRestaurantInProgress) && this.state.orders.length === 0) && <div className="loading-text">No Orders Found</div>}
                {(this.props.isOrderProgress || this.props.currentRestaurantInProgress) && (<div className="loading-text"><i>Loading...</i></div>)}
                {/*orders*/}
                {this.state.orders.length > 0 &&
                  this.state.orders.map((value, index) => {
                    let orderInfo = {};
                    let orderMeta = {};
                    if (value.data && value.data.data && value.data.data.data) {
                      orderInfo = value.data.data.data;
                      orderMeta = value.data.meta;
                    } else {
                      orderInfo = value;
                      orderMeta = { ...this.state.ordersData };
                    }
                    let userPhoto = "";
                    if (
                      orderMeta &&
                      orderMeta.photo &&
                      orderInfo.user &&
                      orderInfo.user.photo
                    ) {
                      userPhoto = `${orderMeta.photo.path}/${orderMeta.photo.folders.user}/${orderMeta.photo.sizes.small}/${orderInfo.user.photo}`;
                    }
                    // if(orderInfo.order_time >= this.state.currentTime){
                    // console.log('order time', orderInfo);
                    // let orders = [];
                    // orders.push(orderInfo);
                    // orders.map((orderInfo,i)=>{
                    return (
                      <div key={index} className="orders-set">
                        <div
                          className={`orders-box ${orderInfo.status !== 2 ||
                              this.props.userOrderDetails &&
                              this.props.userOrderDetails.details &&
                              this.props.userOrderDetails.details.status !== 2 &&
                              this.props.userOrderDetails.details.id === orderInfo.id
                              ? "orders-box mt-3" : "orders-box-active mt-3"
                            }`}
                            style={{background:orderInfo.status === 8 || orderInfo.status===7 ? "#f5222d2b":orderInfo.order_status===0?"#e8f5e8":"white"}}
                        >
                          <div onClick={() => this.setOrderModal(true, value)} className="d-flex w-100">
                            <div className="order-user-icon">
                              {(() => {
                                if (orderInfo.type === 0) {
                                  return (<i className="icmn-fork"></i>
                                  )
                                } else if (orderInfo.type === 1) {
                                  return (
                                    <i className="icmn-take-away"></i>
                                  )
                                } else {
                                  return (
                                    <i className="icmn-shipping"></i>
                                  )
                                }
                              })()}
                            </div>
                            <div className="orders-user-detail">
                              <div className="orders-user-name">
                                {(() => {
                                  if (
                                    orderInfo.user &&
                                    orderInfo.user.first_name &&
                                    orderInfo.user.last_name
                                  ) {
                                    return (
                                      orderInfo.user.first_name +
                                      " " +
                                      orderInfo.user.last_name
                                    );
                                  } else if (
                                    orderInfo.user &&
                                    orderInfo.user.first_name
                                  ) {
                                    return orderInfo.user.first_name;
                                  }
                                })()}
                              </div>
                              <div>
                                  ({orderInfo.order_id})
                              </div>
                              <div className="user-visit-count" style={{color:orderInfo.status === 8 && "black"}}>
                                {orderInfo.user && orderInfo.user.no_of_visits}{" "}
                                Visits
                              </div>
                              <div>
                                {moment(orderInfo.created_at).format('MMM Do, h:mm a')}
                              </div>
                            </div>
                            <div className="order-count-set">
                              <div className="order-count">
                                {orderInfo && orderInfo.table}
                              </div>
                            </div>
                          </div>
                          {(() => {
                            if (value && value.status && value.status === 1) {
                              return (
                                <button type="button" className="order-status-btn">
                                  <i className="icmn-tick" aria-hidden="true"></i>
                                </button>
                              )
                            }
                            else if (!_.isEmpty(_.toString(_.get(value, 'status', null))) && _.isEqual(_.get(value, 'status'), 8)) {
                              return (
                                <button type="button" className="order-status-btn-danger">
                                  <i className="icmn-cross" aria-hidden="true"></i>
                                </button>
                              );
                            }
                            else if (!_.isEmpty(_.toString(_.get(value, 'status', null))) && _.isEqual(_.get(value, 'status'), 9)) {
                              return (
                                <button type="button" className="order-status-btn">
                                  <i className="icmn-food" aria-hidden="true"></i>
                                </button>
                              );
                            }
                            else if (value && value.status && value.status === 5 ||
                              this.props.userOrderDetails &&
                              this.props.userOrderDetails.details &&
                              this.props.userOrderDetails.details.status === 5 &&
                              this.props.userOrderDetails.details.id === value.id) {
                              return (
                                <button type="button" className="order-status-btn timer">
                                  <i className="icmn-clock1" aria-hidden="true"></i>
                                </button>
                              )
                            }
                          })()}
                        </div>
                      </div>
                    );
                    // })


                    // }else {
                    //   return (
                    //     <div key={index} className="orders-set">
                    //       {(()=>{
                    //         if(orderInfo.status !== 2 || 
                    //           this.props.userOrderDetails && 
                    //           this.props.userOrderDetails.details &&
                    //           this.props.userOrderDetails.details.status !== 2 && 
                    //           this.props.userOrderDetails.details.id === orderInfo.id){
                    //             return(
                    //             <div className="orders-box  orders-box mt-3">
                    //         <div onClick={() => this.setOrderModal(true, value)}>
                    //           <div className="order-user-icon">
                    //             {(() => {
                    //               if (orderInfo.type === 0) {
                    //                 return (<i className="icmn-fork"></i>
                    //                 )
                    //               } else if (orderInfo.type === 1) {
                    //                 return (
                    //                   <i className="icmn-take-away"></i>
                    //                   )
                    //               } else {
                    //                 return (
                    //                   <i className="icmn-shipping"></i> 
                    //                 )
                    //               }
                    //             })()}
                    //           </div>
                    //           <div className="orders-user-detail">
                    //             <div className="orders-user-name">
                    //               {(() => {
                    //                 if (
                    //                   orderInfo.user &&
                    //                   orderInfo.user.first_name &&
                    //                   orderInfo.user.last_name
                    //                 ) {
                    //                   return (
                    //                     orderInfo.user.first_name +
                    //                     " " +
                    //                     orderInfo.user.last_name
                    //                   );
                    //                 } else if (
                    //                   orderInfo.user &&
                    //                   orderInfo.user.first_name
                    //                 ) {
                    //                   return orderInfo.user.first_name;
                    //                 }
                    //               })()}
                    //             </div>
                    //             <div className="user-visit-count">
                    //               {orderInfo.user && orderInfo.user.no_of_visits}{" "}
                    //               Visits
                    //           </div>
                    //           </div>
                    //           <div className="order-count-set">
                    //             <div className="order-count">
                    //               {orderInfo && orderInfo.table}
                    //             </div>
                    //           </div>
                    //         </div>
                    //         {(()=>{if(value && value.status && value.status === 1){
                    //           return(
                    //             <button type="button" className="order-status-btn">
                    //             <i className="icmn-tick" aria-hidden="true"></i>
                    //           </button>
                    //           )
                    //         }else if (value && value.status && value.status === 5 ||
                    //           this.props.userOrderDetails && 
                    //           this.props.userOrderDetails.details &&
                    //           this.props.userOrderDetails.details.status === 5 && 
                    //           this.props.userOrderDetails.details.id === value.id  ){
                    //           return(
                    //             <button type="button" className="order-status-btn timer">
                    //             <i className="icmn-clock1" aria-hidden="true"></i>
                    //           </button>
                    //           )
                    //         }
                    //         })()}
                    //       </div>
                    //           )}})()}

                    //     </div>
                    // );
                    // }
                  })}
                {/*orders*/}
              </div>
            </div>
          </Scrollbars>
        </div>
        <div
          className={`order-incom-request-content ${incomingRequestLength > 0 ? "open" : "close"
            }-notification animated`}
        >
          <div className="order-incom-request-content-title">
            Incoming Customers & Bell Requests
          </div>
          {this.state.visits.length > 0 &&
            this.state.visits.map((value, index) => {
              let userMeta = {};
              if (
                value &&
                value.data &&
                value.data.data &&
                value.data.data.meta
              ) {
                userMeta = value.data.data.meta;
              } else {
                userMeta = this.state.visitsMeta;
              }
              let userInfo = {};
              if (
                value &&
                value.data &&
                value.data.data &&
                value.data.data.data &&
                value.data.data.data.user
              ) {
                userInfo = value.data.data.data.user;
              } else {
                userInfo = value && value.user;
              }
              let userPhoto = "";
              if (userMeta && userMeta.photo && userInfo && userInfo.photo) {
                userPhoto = `${userMeta.photo.path}/${userMeta.photo.folders.user}/${userMeta.photo.sizes.small}/${userInfo.photo}`;
              }
              return (
                <div key={index} className="incoming-customer-badge-content">
                  <div
                    className="incoming-customer-badge"
                    onClick={() => this.setVisitModal(true, value)}
                  >
                    <div
                      className="incoming-customer-img-badge"
                      style={{ backgroundImage: `url(${userPhoto})` }}
                    ></div>
                    <div className="incoming-customer-name-badge">
                      {(() => {
                        if (
                          userInfo &&
                          userInfo.first_name &&
                          userInfo.last_name
                        ) {
                          return userInfo.first_name + " " + userInfo.last_name;
                        } else if (
                          userInfo &&
                          userInfo.user &&
                          userInfo.first_name
                        ) {
                          return userInfo.first_name;
                        }
                      })()}
                    </div>
                  </div>
                </div>
              );
            })}

          {this.state.orderUtilities.length > 0 &&
            this.state.orderUtilities.map((value, index) => {
              return (
                <div
                  key={index}
                  className="bell-request-count"
                  onClick={() => this.setUtilitiesModal(true, value, index)}
                >

                  <img
                    className="img-fluid"
                    src={`${value.data.meta.photo.path}/${value.data.meta.photo.folder}/${value.data.data[0].photo}`}
                  />
                  <span

                    className="bell-request-type-circle"
                  >
                    {value.data.table}{" "}
                  </span>
                </div>
              );
            })}
        </div>
        <UtilitiesModal
          {...this.state}
          setUtilitiesModal={value => this.setUtilitiesModal(value)}
          onRemovedUtilityItems={value => this.onRemovedUtilityItems(value)}
        />
        <VisitModal
          {...this.state}
          setVisitModal={value => this.setVisitModal(value)}
          userDetails={this.props.userDetails}
          selectedVisitData={this.state.selectedVisitData}
        />
        <OrderModal
          {...this.state}
          // onClick={() => this.onGetOrders()}
          setOrderModal={value => this.setOrderModal(value)}
          userDetails={this.props.userOrderDetails}
          customerDetails={this.props.userDetails}
          currentRestaurant={this.props.currentRestaurant}
          selectedVisitData={this.state.selectedOrderData}
          onSubmitOrder={type => this.onSubmitOrder(type)}
          isOrderChangeStatusProgress={this.props.isOrderChangeStatusProgress}
          orderStatusUpdated={this.props.orderStatusUpdated}
          selectedOrderData={this.state.selectedOrderData}
          onGetOrders={() => this.onGetOrders()}
          orderStatusUpdatedErrors={this.props.orderStatusUpdatedErrors}
          onClearErrors={() => this.props.onClearErrors()}
          orders={this.props.orders}
        />
        {this.state.isNotificationEnabled && (
          <OrderNotification
            notificationData={this.state.notificationData}
            onNotificationClose={() => this.onNotificationClose()}
            onRemoteNotificationItem={index =>
              this.onRemoteNotificationItem(index)
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    currentRestaurant: state.profile.currentRestaurant,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    utilitiesData: state.common.utilitiesData,
    ordersData: state.common.ordersData,
    visitsData: state.common.visitsData,
    notifyData: state.common.notifyData,
    orders: state.restaurant.orders,
    visits: state.users.visits,
    userDetails: state.users.userDetails,
    isOrderChangeStatusProgress: state.restaurant.isOrderChangeStatusProgress,
    orderStatusUpdated: state.restaurant.orderStatusUpdated,
    orderStatusUpdatedErrors: state.restaurant.orderStatusUpdatedErrors,
    isOrderProgress: state.restaurant.isOrderProgress,
    userOrderDetails: state.restaurant.userOrderDetails,
    confirmOrders: state.restaurant.confirmOrders,


  };
};

const mapDispatchToProps = dispatch => ({
  onSetUtilityData: data =>
    dispatch({ type: SET_UTILITIES, utilitiesData: data }),
  onSetOrderData: data => dispatch({ type: SET_ORDERS, ordersData: data }),
  onSetVisitsData: data => dispatch({ type: SET_VISITS, visitsData: data }),
  onGetOrders: payload => dispatch({ type: GET_ORDERS, payload }),
  onGetVisits: payload => dispatch({ type: GET_VISITS, payload }),
  onGetUserDetails: payload => dispatch({ type: GET_USER_DETAILS, payload }),
  onGetUserOrderDetails: payload => dispatch({ type: GET_USER_ORDER_DETAILS, payload }),
  onSetNotifyData: data =>
    dispatch({ type: GET_NOTIFY_DATA, notifyData: data }),
  onChangeOrderStatus: payload =>
    dispatch({ type: CHANGE_ORDER_STATUS, payload }),
  onClearErrors: () => dispatch({ type: CLEAR_ERRORS })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);