import React from "react";
import { connect } from "react-redux";

import Chart from "react-google-charts";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./dashboard.css";
import globalVariable from "../../config/generalConfig";

import { columnChartConfig } from "./columnChartConfig";
import { doughnutChartConfig } from "./doughnutChartConfig";

import {
  WALK_IN_STATS_LOADED,
  REVENUE_STATS_LOADED,
  GENDER_STATS_LOADED,
  AGE_GROUP_STATS_LOADED,
  SESSION_STATS_LOADED,
} from "../../constants/actionTypes";
import agent from "../../agent";

import { formatDate, capitalizeWord } from "../../utils/Helpers";

import {
  WalkInChartLoader,
  RevenueChartLoader,
  GenderChartLoader,
  AgeGroupChartLoader,
  SessionChartLoader,
} from "./chart-loader";

import OneSignal from "react-onesignal";

class Dashboard extends React.Component {
  state = {
    walkInChart: { type: 0, start_date: null, end_date: null },
    revenueChart: { type: 0, start_date: null, end_date: null },
    genderChart: { type: 0, start_date: null, end_date: null },
    ageGroupChart: { type: 0, start_date: null, end_date: null },
    sessionChart: { type: 0, start_date: null, end_date: null },
  };

  componentDidMount() {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      console.log("granted");
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("granted");
        }
      });
    } else if (Notification.permission === "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("granted");
        }
      });
    }

    if (
      this.props.vendorRestaurant &&
      this.props.vendorRestaurant.items &&
      this.props.vendorRestaurant.items.length > 0
    ) {
      const requestParams = { type: 0 };
      this.props.onWalkInStats(agent.Retaurant.walkInStats(requestParams));
      this.props.OnRevenueStats(agent.Retaurant.revenueStats(requestParams));
      this.props.OnGenderStats(agent.Retaurant.genderStats(requestParams));
      this.props.OnAgeGroupStats(agent.Retaurant.ageGroupStats(requestParams));
      this.props.OnSessionStats(agent.Retaurant.sessionStats(requestParams));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.hoppointsDetails !== this.props.hoppointsDetails &&
      this.props.vendorRestaurant &&
      this.props.vendorRestaurant.items &&
      this.props.vendorRestaurant.items.length > 0
    ) {
      const requestParams = { type: 0 };
      this.props.onWalkInStats(agent.Retaurant.walkInStats(requestParams));
      this.props.OnRevenueStats(agent.Retaurant.revenueStats(requestParams));
      this.props.OnGenderStats(agent.Retaurant.genderStats(requestParams));
      this.props.OnAgeGroupStats(agent.Retaurant.ageGroupStats(requestParams));
      this.props.OnSessionStats(agent.Retaurant.sessionStats(requestParams));
    }

    if (
      prevProps.currentRestaurant !== this.props.currentRestaurant &&
      this.props.currentRestaurant &&
      this.props.currentRestaurant.details &&
      this.props.currentRestaurant.details.id
    ) {
      let currentThis = this;
      // var OneSignal = window.OneSignal || [];

      //   OneSignal.push(function () {
      //     OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' };
      //     OneSignal.SERVICE_WORKER_PATH = 'push/onesignal/OneSignalSDKWorker.js'
      //     OneSignal.SERVICE_WORKER_UPDATER_PATH = 'push/onesignal/OneSignalSDKWorker.js'
      //     OneSignal.init(initConfig);
      // });

      if (globalVariable.production) {
        console.log("production",globalVariable.production)
      
        OneSignal.init({
          appId: globalVariable.PROD_ONESIGNAL_PARTNERS_APP_ID,
          subdomainName: globalVariable.PROD_ONESIGNAL_PARTNERS_SUBDOMAIN_NAME,
          notifyButton: {
            enable: true,
          },
        });
      } else {
        console.log("not production",globalVariable.production)
        OneSignal.init({
          appId: globalVariable.DEV_ONESIGNAL_PARTNERS_APP_ID,
          subdomainName: globalVariable.DEV_ONESIGNAL_PARTNERS_SUBDOMAIN_NAME,
          allowLocalhostAsSecureOrigin: true,
          notifyButton: {
            enable: true,
          },
        });
      }
      //  await OneSignal.getUserId().then(function(userId) {
      //   console.log("OneSignal User ID:", userId);
      //   // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
      // });
      OneSignal.sendTag(
        "id",
        currentThis.props.currentRestaurant.details.id,
        function (tagsSent) {
          console.log("id", tagsSent);
        }
      );
       OneSignal.sendTag("name", currentThis.props.currentRestaurant.details.name, function(tagsSent) {
        console.log("name",tagsSent)

        // Callback called when tags have finished sending
      });
      OneSignal.sendTag("city_id", currentThis.props.currentRestaurant.details.city.id, function(tagsSent) {
        console.log("city_id",tagsSent)
        // Callback called when tags have finished sending
      });
      OneSignal.on('notificationDisplay', function(event) {
        console.warn('OneSignal notification displayed:', event);
      });
    }
  }

  onSubmitFiterRequestChart = async (type, chartType) => {
    await this.setState({
      [chartType]: {
        type: type,
        start_date: this.state[chartType].start_date,
        end_date: this.state[chartType].end_date,
      },
    });
    const requestParams = { ...this.state[chartType] };
    chartType === "walkInChart" &&
      this.props.onWalkInStats(agent.Retaurant.walkInStats(requestParams));
    chartType === "revenueChart" &&
      this.props.OnRevenueStats(agent.Retaurant.revenueStats(requestParams));
    chartType === "genderChart" &&
      this.props.OnGenderStats(agent.Retaurant.genderStats(requestParams));
    chartType === "ageGroupChart" &&
      this.props.OnAgeGroupStats(agent.Retaurant.ageGroupStats(requestParams));
    chartType === "sessionChart" &&
      this.props.OnSessionStats(agent.Retaurant.sessionStats(requestParams));
  };

  onHandleDateRangeEvent = async (event, picker, chartType) => {
    await this.setState({
      [chartType]: {
        start_date: formatDate(picker.startDate, "YYYY-MM-DD"),
        end_date: formatDate(picker.endDate, "YYYY-MM-DD"),
      },
    });
  };

  render() {
    const walkInText =
      this.state.walkInChart.type === 0
        ? "Daily"
        : this.state.walkInChart.type === 1
          ? "Weekly"
          : this.state.walkInChart.type === 2
            ? "Monthly"
            : this.state.walkInChart.type === 3
              ? "Yearly"
              : "Custom";
    let walkInStatschartOutput = [];
    if (this.props.walkInStats && this.props.walkInStats.items) {
      walkInStatschartOutput = [[walkInText, "No of walkins"]];
      for (let value of this.props.walkInStats.items) {
        if (value.type === "day") {
          value.label = value.label == 0 ? "Sun" : value.label;
          value.label = value.label == 1 ? "Mon" : value.label;
          value.label = value.label == 2 ? "Tue" : value.label;
          value.label = value.label == 3 ? "Wed" : value.label;
          value.label = value.label == 4 ? "Thu" : value.label;
          value.label = value.label == 5 ? "Fri" : value.label;
          value.label = value.label == 6 ? "Sat" : value.label;
        }
        if (value.type === "month") {
          value.label = value.label == 1 ? "Jan" : value.label;
          value.label = value.label == 2 ? "Feb" : value.label;
          value.label = value.label == 3 ? "Mar" : value.label;
          value.label = value.label == 4 ? "Apr" : value.label;
          value.label = value.label == 5 ? "May" : value.label;
          value.label = value.label == 6 ? "June" : value.label;
          value.label = value.label == 7 ? "July" : value.label;
          value.label = value.label == 8 ? "Aug" : value.label;
          value.label = value.label == 9 ? "Sep" : value.label;
          value.label = value.label == 10 ? "Oct" : value.label;
          value.label = value.label == 11 ? "Nov" : value.label;
          value.label = value.label == 12 ? "Dec" : value.label;
        }
        if (value.type === "year") {
          value.label = value.label.toString();
        }
        if (parseInt(value.value) > 0) {
          walkInStatschartOutput.push([value.label, parseInt(value.value)]);
        }
      }
    }
    const revenueText =
      this.state.revenueChart.type === 0
        ? "Daily"
        : this.state.revenueChart.type === 1
          ? "Weekly"
          : this.state.revenueChart.type === 2
            ? "Monthly"
            : this.state.revenueChart.type === 3
              ? "Yearly"
              : "Custom";
    let revenueStatschartOutput = [];
    if (this.props.revenueStats && this.props.revenueStats.items) {
      revenueStatschartOutput = [[revenueText, "Revenue amount in INR"]];
      for (let value of this.props.revenueStats.items) {
        if (value.type === "day") {
          value.label = value.label == 0 ? "Sun" : value.label;
          value.label = value.label == 1 ? "Mon" : value.label;
          value.label = value.label == 2 ? "Tue" : value.label;
          value.label = value.label == 3 ? "Wed" : value.label;
          value.label = value.label == 4 ? "Thu" : value.label;
          value.label = value.label == 5 ? "Fri" : value.label;
          value.label = value.label == 6 ? "Sat" : value.label;
        }
        if (value.type === "month") {
          value.label = value.label == 1 ? "Jan" : value.label;
          value.label = value.label == 2 ? "Feb" : value.label;
          value.label = value.label == 3 ? "Mar" : value.label;
          value.label = value.label == 4 ? "Apr" : value.label;
          value.label = value.label == 5 ? "May" : value.label;
          value.label = value.label == 6 ? "June" : value.label;
          value.label = value.label == 7 ? "July" : value.label;
          value.label = value.label == 8 ? "Aug" : value.label;
          value.label = value.label == 9 ? "Sep" : value.label;
          value.label = value.label == 10 ? "Oct" : value.label;
          value.label = value.label == 11 ? "Nov" : value.label;
          value.label = value.label == 12 ? "Dec" : value.label;
        }
        if (value.type === "year") {
          value.label = value.label.toString();
        }
        if (parseInt(value.value) > 0) {
          revenueStatschartOutput.push([value.label, parseInt(value.value)]);
        }
      }
    }

    let genderStatschartOutput = [["Hour", "Value"]];
    if (this.props.genderStats && this.props.genderStats.items) {
      for (let value of this.props.genderStats.items) {
        if (parseInt(value.value) > 0) {
          genderStatschartOutput.push([
            capitalizeWord(value.label.toString()),
            parseInt(value.value),
          ]);
        }
      }
    }
    let ageGroupStatschartOutput = [["Hour", "Value"]];
    if (this.props.ageGroupStats && this.props.ageGroupStats.items) {
      for (let value of this.props.ageGroupStats.items) {
        if (parseInt(value.value) > 0) {
          value.label = value.label === 0 ? "<15" : value.label;
          value.label = value.label === 1 ? "15 - 18" : value.label;
          value.label = value.label === 2 ? "18 - 25" : value.label;
          value.label = value.label === 3 ? "25 - 30" : value.label;
          value.label = value.label === 4 ? "30 - 40" : value.label;
          value.label = value.label === 5 ? "<40" : value.label;
          value.label = value.label === -1 ? "Unknown" : value.label;
          ageGroupStatschartOutput.push([
            capitalizeWord(value.label.toString()),
            parseInt(value.value),
          ]);
        }
      }
    }
    let sessionStatschartOutput = [["Hour", "Value"]];
    if (this.props.sessionStats && this.props.sessionStats.items) {
      for (let value of this.props.sessionStats.items) {
        if (parseInt(value.value) > 0) {
          sessionStatschartOutput.push([
            capitalizeWord(value.label.toString()),
            parseInt(value.value),
          ]);
        }
      }
    }

    const types = ["D", "W", "M", "Y"];

    const walkInConfigOpitons = columnChartConfig.walkInOptions;
    walkInConfigOpitons.hAxis.title = walkInText;

    const revenueConfigOpitons = columnChartConfig.revenueOptions;
    revenueConfigOpitons.hAxis.title = revenueText;

    return (
      <div className="store-analytics-container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="top-graph-set">
              <div className="top-graph-title-set">
                <div className="top-graph-title pull-left">
                  Walk-in Statistics
                </div>
              </div>
              <div className="top-graph-filter-set">
                <div className="top-graph-filter">
                  {types.map((value, index) => {
                    return (
                      <button
                        key={index}
                        className={
                          this.state.walkInChart.type === index
                            ? "filter-btn active"
                            : "filter-btn"
                        }
                        onClick={() =>
                          this.onSubmitFiterRequestChart(index, "walkInChart")
                        }
                      >
                        {value}
                      </button>
                    );
                  })}
                </div>
                <DateRangePicker
                  onApply={() =>
                    this.onSubmitFiterRequestChart(4, "walkInChart")
                  }
                  onEvent={(event, picker) =>
                    this.onHandleDateRangeEvent(event, picker, "walkInChart")
                  }
                >
                  <button className="range-btn ml-1">Range</button>
                </DateRangePicker>
              </div>
              <div className="top-graph">
                {walkInStatschartOutput.length > 1 &&
                  !this.props.inWalkInStatsProgress && (
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      loader={<div>Loading...</div>}
                      data={walkInStatschartOutput}
                      options={walkInConfigOpitons}
                    />
                  )}
                {walkInStatschartOutput.length === 1 &&
                  !this.props.inWalkInStatsProgress && (
                    <div
                      className="row align-items-center text-center bg-white"
                      style={{ height: 300 + "px", color: "#999999" }}
                    >
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        No data available for current selection.
                      </div>
                    </div>
                  )}
              </div>
              {this.props.inWalkInStatsProgress && <WalkInChartLoader />}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="top-graph-set">
              <div className="top-graph-title-set">
                <div className="top-graph-title pull-left">Revenue</div>
              </div>
              <div className="top-graph-filter-set">
                <div className="top-graph-filter">
                  {types.map((value, index) => {
                    return (
                      <button
                        key={index}
                        className={
                          this.state.revenueChart.type === index
                            ? "filter-btn active"
                            : "filter-btn"
                        }
                        onClick={() =>
                          this.onSubmitFiterRequestChart(index, "revenueChart")
                        }
                      >
                        {value}
                      </button>
                    );
                  })}
                </div>
                <DateRangePicker
                  onApply={() =>
                    this.onSubmitFiterRequestChart(4, "revenueChart")
                  }
                  onEvent={(event, picker) =>
                    this.onHandleDateRangeEvent(event, picker, "revenueChart")
                  }
                >
                  <button className="range-btn ml-1">Range</button>
                </DateRangePicker>
              </div>
              <div className="top-graph">
                {revenueStatschartOutput.length > 1 &&
                  !this.props.inRevenueStatsProgress && (
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      loader={<div>Loading...</div>}
                      data={revenueStatschartOutput}
                      options={revenueConfigOpitons}
                    />
                  )}
                {revenueStatschartOutput.length === 1 &&
                  !this.props.inRevenueStatsProgress && (
                    <div
                      className="row align-items-center text-center bg-white"
                      style={{ height: 300 + "px", color: "#999999" }}
                    >
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        No data available for current selection.
                      </div>
                    </div>
                  )}
              </div>
              {this.props.inRevenueStatsProgress && <RevenueChartLoader />}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 my-4">
            <div className="bottom-graph-box">
              <div className="bottom-graph-title-set">
                <div className="bottom-graph-title pull-left">Gender</div>
              </div>
              <div className="top-graph-filter-set">
                <div className="top-graph-filter">
                  {types.map((value, index) => {
                    return (
                      <button
                        key={index}
                        className={
                          this.state.genderChart.type === index
                            ? "filter-btn active"
                            : "filter-btn"
                        }
                        onClick={() =>
                          this.onSubmitFiterRequestChart(index, "genderChart")
                        }
                      >
                        {value}
                      </button>
                    );
                  })}
                </div>
                <DateRangePicker
                  onApply={() =>
                    this.onSubmitFiterRequestChart(4, "genderChart")
                  }
                  onEvent={(event, picker) =>
                    this.onHandleDateRangeEvent(event, picker, "genderChart")
                  }
                >
                  <button className="range-btn ml-1">Range</button>
                </DateRangePicker>
              </div>
              <div className="bottom-graph-content">
                <div className="bottom-graph">
                  {!this.props.inGenderStatsProgress && (
                    <div className="top-graph">
                      <Chart
                        chartType="PieChart"
                        width="100%"
                        height="200px"
                        loader={<div>Loading...</div>}
                        data={genderStatschartOutput}
                        options={doughnutChartConfig.genderOptions}
                      />
                    </div>
                  )}
                  {this.props.inGenderStatsProgress && <GenderChartLoader />}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 my-4">
            <div className="bottom-graph-box">
              <div className="bottom-graph-title-set">
                <div className="bottom-graph-title pull-left">Age Group</div>
              </div>
              <div className="top-graph-filter-set">
                <div className="top-graph-filter">
                  {types.map((value, index) => {
                    return (
                      <button
                        key={index}
                        className={
                          this.state.ageGroupChart.type === index
                            ? "filter-btn active"
                            : "filter-btn"
                        }
                        onClick={() =>
                          this.onSubmitFiterRequestChart(index, "ageGroupChart")
                        }
                      >
                        {value}
                      </button>
                    );
                  })}
                </div>
                <DateRangePicker
                  onApply={() =>
                    this.onSubmitFiterRequestChart(4, "ageGroupChart")
                  }
                  onEvent={(event, picker) =>
                    this.onHandleDateRangeEvent(event, picker, "ageGroupChart")
                  }
                >
                  <button className="range-btn ml-1">Range</button>
                </DateRangePicker>
              </div>
              <div className="bottom-graph-content">
                <div className="bottom-graph">
                  {!this.props.inAgeGroupStatsProgress && (
                    <div className="top-graph">
                      <Chart
                        chartType="PieChart"
                        width="100%"
                        height="200px"
                        loader={<div>Loading...</div>}
                        data={ageGroupStatschartOutput}
                        options={doughnutChartConfig.ageGrupOptions}
                      />
                    </div>
                  )}
                  {this.props.inAgeGroupStatsProgress && (
                    <AgeGroupChartLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 my-4">
            <div className="bottom-graph-box">
              <div className="bottom-graph-title-set">
                <div className="bottom-graph-title pull-left">Time Of Day</div>
              </div>
              <div className="top-graph-filter-set">
                <div className="top-graph-filter">
                  {types.map((value, index) => {
                    return (
                      <button
                        key={index}
                        className={
                          this.state.sessionChart.type === index
                            ? "filter-btn active"
                            : "filter-btn"
                        }
                        onClick={() =>
                          this.onSubmitFiterRequestChart(index, "sessionChart")
                        }
                      >
                        {value}
                      </button>
                    );
                  })}
                </div>
                <DateRangePicker
                  onApply={() =>
                    this.onSubmitFiterRequestChart(4, "sessionChart")
                  }
                  onEvent={(event, picker) =>
                    this.onHandleDateRangeEvent(event, picker, "sessionChart")
                  }
                >
                  <button className="range-btn ml-1">Range</button>
                </DateRangePicker>
              </div>
              <div className="bottom-graph-content">
                <div className="bottom-graph">
                  {!this.props.inSessionStatsProgress && (
                    <div className="top-graph">
                      <Chart
                        chartType="PieChart"
                        width="100%"
                        height="200px"
                        loader={<div>Loading Chart</div>}
                        data={sessionStatschartOutput}
                        options={doughnutChartConfig.timeOfDayOptions}
                      />
                    </div>
                  )}
                  {this.props.inSessionStatsProgress && <SessionChartLoader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    walkInStats: state.restaurant.walkInStats,
    revenueStats: state.restaurant.revenueStats,
    genderStats: state.restaurant.genderStats,
    ageGroupStats: state.restaurant.ageGroupStats,
    sessionStats: state.restaurant.sessionStats,
    inWalkInStatsProgress: state.restaurant.inWalkInStatsProgress,
    inRevenueStatsProgress: state.restaurant.inRevenueStatsProgress,
    inAgeGroupStatsProgress: state.restaurant.inAgeGroupStatsProgress,
    inGenderStatsProgress: state.restaurant.inGenderStatsProgress,
    inSessionStatsProgress: state.restaurant.inSessionStatsProgress,
    currentRestaurant: state.profile.currentRestaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onWalkInStats: (payload) => dispatch({ type: WALK_IN_STATS_LOADED, payload }),
  OnRevenueStats: (payload) =>
    dispatch({ type: REVENUE_STATS_LOADED, payload }),
  OnGenderStats: (payload) => dispatch({ type: GENDER_STATS_LOADED, payload }),
  OnAgeGroupStats: (payload) =>
    dispatch({ type: AGE_GROUP_STATS_LOADED, payload }),
  OnSessionStats: (payload) =>
    dispatch({ type: SESSION_STATS_LOADED, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
