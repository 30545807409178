import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { debounce } from 'lodash'

import "./transactions.css";
import { Select, Input, Checkbox, Button, Modal, Form, Spin, InputNumber, notification } from "antd";
import agent from "../../agent";
import {
  MENUS_UNLOADED,
	GET_TRANSACTIONS
} from "../../constants/actionTypes";
import { capitalizeWord, formatDate } from "../../utils/Helpers";
import TransactionsTable from "./transactions-table";

const FormItem = Form.Item;

class Transactions extends React.Component {
  constructor(props){
    super(props);
    this.onFilterTransactions = debounce(this.onFilterTransactions, 800);
  }
  state = {
    transactionsFilterValues: { id_type:1, expand: "restaurant" },
    notificationModalVisible: false,
    receivers: [],
    pageCount: 1,
    pageLimit: 10,
    loadedTransactions: [],
    isLoadMore: false,
    isFilter: false
  };

  setNotificationModalVisible(notificationModalVisible) {
    this.setState({ notificationModalVisible });
  }

  componentDidMount() {
    if (this.props.currentRestaurant && this.props.currentRestaurant.details) {
      let requestParams = { id_type:1, expand: "restaurant" };
      this.props.onGetTransactions(agent.Hoppoints.transactions(requestParams));
    }
    }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.currentRestaurant !== this.props.currentRestaurant &&
      this.props.currentRestaurant &&
      this.props.currentRestaurant.details
    ) {
      let requestParams = { id_type:1, expand: "restaurant" };
      this.props.onGetTransactions(agent.Hoppoints.transactions(requestParams));
      this.setState({
        currentRestaurant: this.props.currentRestaurant.details
      });
    }
    if (
      prevProps.transactions !== this.props.transactions &&
      this.props.transactions &&
      this.props.transactions.items
    ) {
      if(!this.state.isLoadMore) {
        await this.setState({
          loadedTransactions: [],
        });
      }
      let newTransactions = [];
      this.props.transactions.items.map((value, index) => {
        newTransactions.push(value);
      });
      await this.setState({
        loadedTransactions: [...this.state.loadedTransactions, ...newTransactions],
        isLoadMore: false,
        isFilter: false
      });
    }
    if (
      prevProps.notificationData !== this.props.notificationData &&
      this.props.notificationData &&
      this.props.notificationData.details
    ) {
      let requestParams = { id_type:1, expand: "restaurant" };
      this.props.onGetTransactions(agent.Hoppoints.transactions(requestParams));
      this.setNotificationModalVisible(false);
      this.setState({
        receivers: []
      });
    }
    if(prevProps.transactionsErrors !== this.props.transactionsErrors &&
      this.props.transactionsErrors && this.props.transactionsErrors.errors) {
      this.props.transactionsErrors.errors.map((value, index) => {
        value.rules.map((value, index) => {
          return notification.error({message: value.message})
        })
      })
    }
  }

  onFilterTransactions = async (fieldName, value) => {
      await this.setState(prevState => {
        return {
          transactionsFilterValues: { ...prevState.transactionsFilterValues, [fieldName]: value },
          isLoadMore: false,
          isFilter: true
        };
      });

      let requestParams = {
        ...this.state.transactionsFilterValues
      };
      if (requestParams.create_at) {
        requestParams.create_at = formatDate(
          requestParams.create_at,
          "YYYY/MM/DD"
        );
      }
      this.props.onGetTransactions(agent.Hoppoints.transactions(requestParams));
  };

  onAddReceiversForNotifiaction = async receiverId => {
    await this.setState({
      receivers: [...this.state.receivers, receiverId]
    });
  };

  onSendNotification = e => {
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      if (!err) {
        data.receivers = [...this.state.receivers];
        this.props.onSendNotification(data);
      }
    });
  };

  onLoadMoreItemsToDishes = async () => {
    if (
      this.props.transactions &&
      this.props.transactions.meta &&
      this.props.transactions.meta.total !== this.state.loadedTransactions.length
    ) {
      await this.setState({
        pageCount: this.state.pageCount + 1,
        isLoadMore: true,
        isFilter: false
      });
      let requestParams = {
        expand: "user",
        limit: this.state.pageLimit,
        page: this.state.pageCount
      };
      this.props.onGetTransactions(agent.Hoppoints.transactions(requestParams));
    }
  };

  render() {
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="transactions-page-container">
        <div className="section-title">Transactions</div>
        <div className="customer-table-filter-set">
          <div className="customer-filter-set">
          <span className="filter-label">Type</span>
          <Select
            showSearch
            style={{ width: 189 }}
            placeholder="Type"
            optionFilterProp="children"
            onChange={value => this.onFilterTransactions("type", value)}
          >
          <Option value="0">Debit</Option>
          <Option value="1">Credit</Option>
          </Select>
          </div>
          <div className="customer-filter-set">
            <span className="filter-label">Date</span>
            <DatePicker
              selected={this.state.transactionsFilterValues.create_at}
              onChange={date => this.onFilterTransactions("create_at", date)}
              dateFormat="yyyy/MM/dd"
              placeholderText="YYYY/MM/DD"
              customInput={<Input style={{ width: 169 + "px",textAlign:"left" }} />}
            />
          </div>

          <div className="customer-filter-set">

          </div>
        </div>
        <div className="customer-table">
          <div className="pull-left">
            <div className="customer-count-text">
              Showing {this.props.transactions &&
              this.props.transactions.items &&
              this.props.transactions.items.length > 0
                ? this.props.transactions.items.length
                : 0}{" "} of {this.props.transactions &&
                              this.props.transactions.meta &&
                              this.props.transactions.meta.total}
            </div>
          </div>
          <div className="table-responsive">
            <TransactionsTable
            {...this.props}
            stateData={this.state.loadedTransactions}
            onAddReceiversForNotifiaction={this.onAddReceiversForNotifiaction}
            receivers={this.state.receivers}
            />
            {this.props.transactions &&
              this.props.transactions.meta &&
              this.props.transactions.meta.total !== this.state.loadedTransactions.length && (
                <div
                  className="dish-load-more"
                  onClick={() => this.onLoadMoreItemsToDishes()}
                >
                  <button
                    type="button"
                    className="button-md font-700 text-upper ripple-btn-green btn-green mt-1"
                  >
                    Load More...
                  </button>
                </div>
              )}
          </div>
        </div>
        <Modal
          className="common-modal-content buy-more-modal-container"
          title=""
          centered
          visible={this.state.notificationModalVisible}
          onOk={() => this.setNotificationModalVisible(false)}
          onCancel={() => this.setNotificationModalVisible(false)}
          footer={[
            <Button
              disabled={this.props.inSendNotificationProgress}
              className="outline-secondary-btn-lg ripple-btn-secondary"
              key="back"
              onClick={() => this.setNotificationModalVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              disabled={false}
              loading={this.props.inSendNotificationProgress}
              className="pink-button-lg ripple-btn-pink"
              key="submit"
              type="primary"
              onClick={this.onSendNotification}
            >
              Send
            </Button>
          ]}
        >
          <div className="modal-container">
            <div className="modal-text">Notification Modal</div>
            <div
              className="modal-mid-content-form input-container"
              style={{ fontSize: "16px" }}
            >
              <Form>
                <FormItem>
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your title"
                      }
                    ]
                  })(<Input size="large" placeholder="Title" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("message", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your message!"
                      }
                    ]
                  })(<Input.TextArea size="large" placeholder="Message" />)}
                </FormItem>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    inTransactionsProgress: state.hoppoints.inTransactionsProgress,
    currentRestaurant: state.profile.currentRestaurant,
    transactions: state.hoppoints.transactions,
    notificationData: state.users.notificationData,
    inSendNotificationProgress: state.users.inSendNotificationProgress,
    transactionsErrors: state.users.transactionsErrors
  };
};

const mapDispatchToProps = dispatch => ({
  onGetTransactions: payload => dispatch({ type: GET_TRANSACTIONS, payload }),
  onUnload: () => dispatch({ type: MENUS_UNLOADED })
});

const WrappedNormalForm = Form.create({ name: "normal_notification_form" })(
  Transactions
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalForm);
