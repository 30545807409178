import _ from 'lodash';
import {Howl} from 'howler';

import AppReUsableComponents from "..";
import { notification } from 'antd';
// import addNotification from 'react-push-notification';

var sound;

export const connectSocket = (props={}, connectedCallback=(data)=>{
  console.log("socket connectedCallback:",data)

 }) =>{


    const socket = global.config.socket;
    // const sound = new Howl({
    //     src: [require("../sound-notification/assets/sounds/bell.mp3")],
    //     // autoplay: false,
    //     loop: true,
    //     // html5: true,
    //     volume: 20,
    // });

    if(!socket.connected){
        socket.open();
        socket.on("connect", () => {
            console.log('Socket connected successfully.', socket.connected);
            connectedCallback({connected: true});
        });
    }

    let ordersData = {};
    socket.on("ordersClient", data => {
        console.log(data.data.data.order);
        let restaurantId = localStorage.getItem("restaurantId");
        console.log(restaurantId)
        if ( data && data.data && data.data.data.order && data.data.data.order.restaurant === restaurantId &&(data.data.data.order.status == 1 || data.data.data.order.status == 8 || data.data.data.order.status == 7)) {
          if (!_.isEqual(ordersData, data)) {
            let soundChange;
            if (data.data.data.order.status == 1) {
              soundChange = require("../sound-notification/assets/sounds/order_created_sound.mp3");
              const notification = new Notification(
                "Hopsticks : New Order Received",
                {
                  body: "You have received a new order. Please mark Preparing.",
                  icon: require("./favicon.ico"),
                }
              );
            } else {
              const notification = new Notification(
                "Hopsticks : Order Cancelled",
                {
                  body: `Order #${data.data.data.order.order_id} is cancelled. Please don't prepare.`,
                  icon: require("./favicon.ico"),
                }
              );
              soundChange = require("../sound-notification/assets/sounds/order_cancelled_sound.mp3");
            }

            sound = new Howl({
              src: [soundChange],
              // autoplay: false,
              loop: true,
              // html5: true,
              volume: 2,
            });

            console.log(
              "Sound notification and message notification have been pop."
            );
            ordersData = data;
            sound.play();
            // notification.info({
            //   message: 'Notification',
            //   description: 'A new order has been received.'
            // });
            // addNotification({
            //     title: 'Notification',
            //     // subtitle: 'This is a subtitle',
            //     message: 'A new order has been received.',
            //     onClick: () => { sound.stop() },
            //     theme: 'red', //optional, default: undefined
            //     duration: 1000000, //optional, default: 5000,
            //     backgroundTop: 'green', //optional, background color of top container.
            //     backgroundBottom: 'darkgreen', //optional, background color of bottom container.
            //     colorTop: 'green', //optional, font color of top container.
            //     colorBottom: 'darkgreen', //optional, font color of bottom container.
            //     closeButton: 'Go away', //optional, text or html/jsx element for close text. Default: Close,
            //     native: true, //optional, makes the push notification a native OS notification
            //     icon: require('./info.png'),
            //     vibrate: true,
            // });

            // notification.onclick = (e) => {
            //     sound.stop();
            // };

            setTimeout(() => {
              sound.stop();
            }, 300000);
          }
        }
    });
    socket.on("reconnect_attempt", function(reason) {
      console.log(`socket reconnect attempt reason: ${reason}`);
    });

    socket.on("connect_error", function(reason) {
      console.log('Socket connect_error:', reason);
      connectedCallback({connected: false})
    });

    socket.on("disconnect", function(reason) {
      console.log('Socket disconnect:', reason);
      connectedCallback({connected: false})
    });

    socket.on("connect_timeout", function(reason) {
      console.log('Socket connect_timeout:', reason);
      connectedCallback({connected: false})
    });

    socket.on("reconnect", function(reason) {
      console.log('Socket reconnect:', reason);
      // connectedCallback({connected: false})
    });

    socket.on("reconnecting", function(reason) {
      console.log('Socket reconnecting:', reason);
      // connectedCallback({connected: false})
    });

    socket.on("reconnect_error", function(reason) {
      console.log('Socket reconnect_error:', reason);
      connectedCallback({connected: false})
    });

}

export const handleSound=()=>{
  if(sound){
    sound.stop();
  }
}

