import React from "react";
import {Link} from "react-router-dom";
import "./error.css";

class Page404 extends React.Component {
  render() {
    return (
      <div className="error-page-container">
        <div className="container">
          <div className="error-inner-content">
            <div className="error-img">
              <img
                className="img-fluid"
                src={require("../../resources/images/404-img.png")}
              />
            </div>
            <div className="error-page-title">Whoops!</div>
            <div className="error-page-text">
              It looks like someone ate the contents of this page. Much as we
              would love to serve it to you, we’d suggest you to{" "}
              <Link to={'/dashboard'}>go home</Link> and try a different link.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page404;
