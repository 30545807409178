import React, { PureComponent } from 'react';

class HeaderLoader extends PureComponent { 
  render () {
    return (
      <div className="row header-placeholder content-placeholder">
					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
						<div className="header-left">
							<div className="d-flex align-items-center">
								<div className="avatar-placeholder p-bg"></div>
								<div className="header-name-add-placeholder ml-2">
									<div className="d-flex">
										<div className="h-p-name p-bg"></div>
										<div className="h-p-name-edit p-bg ml-2"></div>
									</div>
									<div className="h-p-address p-bg my-2"></div>
									<div className="h-p-contact p-bg"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
					<div className="header-right d-flex align-items-center">
						<div className="d-flex float-md-left float-lg-left float-xl-right">
							<div className="count-set">
								<div className="count-icon p-bg"></div>
								<div className="count-type-set">
									<div className="count-text p-bg"></div>
									<div className="count-type-text p-bg"></div>
								</div>
							</div>
							<div className="count-set">
								<div className="count-icon p-bg"></div>
								<div className="count-type-set">
									<div className="count-text p-bg"></div>
									<div className="count-type-text p-bg"></div>
								</div>
							</div>
							<div className="count-set">
								<div className="count-icon p-bg"></div>
								<div className="count-type-set">
									<div className="count-text p-bg"></div>
									<div className="count-type-text p-bg"></div>
									<button type="button" className="outline-secondary-btn-sm mt-1 p-bg"></button>
								</div>
							</div>
							<div className="count-set">
								<div className="count-icon p-bg"></div>
								<div className="count-type-set">
									<div className="count-text p-bg"></div>
									<div className="count-type-text p-bg"></div>
									<button type="button" className="outline-secondary-btn-sm mt-1 p-bg"></button>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
    );
  }
}

export default HeaderLoader;
