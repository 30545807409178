import React, { Component } from "react";

import { Scrollbars } from "react-custom-scrollbars";

class OrderNotification extends Component {
  onNotificationClose = () => {
    this.props.onNotificationClose();
  };

  onRemoteNotificationItem = async index => {
    await this.props.onRemoteNotificationItem(index);
  };

  componentDidMount() {}

  render() {
    return (
      <div
        className={
          this.props.notificationData && this.props.notificationData.length > 0
            ? "notification-container open-notification animated"
            : "notification-container close-notification animated"
        }
      >
        <div className="notification-content-title">
          New notification
          <button
            type="button"
            className="notification-content-close-btn"
            onClick={() => this.onNotificationClose()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div id="notification-scroll-container">
          <Scrollbars style={{ height: 320 }}>
            <div className="notification-box-container">
              {this.props.notificationData &&
                this.props.notificationData.length > 0 &&
                this.props.notificationData.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={(() => {
                        setTimeout(() => {
                          return (
                            `notification-box ${
                              value.data.type === "utility_request"
                                ? "utilities"
                                : value.data.type
                            } hide-notification animated`
                          )
                        }, value.timeout)
                        return (
                          `notification-box ${
                            value.data.type === "utility_request"
                              ? "utilities"
                              : value.data.type
                          } show-notification animated`
                        )
                      })()}
                    >
                      <button
                        type="button"
                        className="notification-close-btn"
                        onClick={() => this.onRemoteNotificationItem(index)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                      <div className="notification-box-inner">
                        <div className="notification-box-icon">
                          {value.data.type === "order" ? (
                            <img
                              src={require("../../resources/images/order-icon.png")}
                            />
                          ) : value.data.type === "utility_request" ? (
                            <img
                              src={require("../../resources/images/utilities-icon.png")}
                            />
                          ) : (
                            <img
                              src={require("../../resources/images/visit-icon.png")}
                            />
                          )}
                        </div>
                        <div className="notification-box-text">
                          {value.data.message}
                        </div>
                        <div className="notification-order-table">
                          {(() => {
                            if (
                              value.data &&
                              value.data.data &&
                              value.data.data.data &&
                              value.data.data.data.table
                            ) {
                              return value.data.data.data.table;
                            }
                            if (value && value.data && value.data.table) {
                              return value.data.table;
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default OrderNotification;
