import React from "react";
import { connect } from "react-redux";
import "./order-model.css";
import { Select, Input, Modal, Button, notification, Table, Switch, Collapse, DatePicker, TimePicker } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import agent from "../../agent";
import globalVariable from "../../config/generalConfig";
import moment from 'moment';
const { TextArea } = Input;
function onChange(time, timeString) {
  console.log(time, timeString);
}
class OrderModel extends React.Component {
    state = {
        modal1Visible: false,
        modal2Visible: false,
    };

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    render() {
        return (
            <div className="container">
    		<div>
        <Button type="primary" onClick={() => this.setModal2Visible(true)}>
          Order Details
        </Button>
        <Modal
          className="order-details-container"
          title="Order Details"
          width="75%"
          centered
          visible={this.state.modal2Visible}
          onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
          footer={null}
        >
          <div className="row">
          <div className="customer-details-model-header">
            <div className="customer-details-model-title">Order Details</div>
            <div className="user-info-set-container">
              <div className="user-info-set">
                <span className="user-info-name"><b>Ila </b></span>
                <span className="user-info-details">9677831857 | Puducherry</span>
              </div>
              <div className="user-info-set">
                <span className="user-info-name"><b>June 4th 2020 Expected Take-away: 20:59</b></span>
                <span className="user-info-details">Order Type: <b>Take-away</b>  </span>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row p-15">
                <div className="col-4"><b>Item</b></div>
                <div className="col-2 text-right"><b>Qty</b></div>
                <div className="col-2 text-right"><b>Rate</b></div>
                <div className="col-2 text-right"><b>Amount</b></div>
                <div className="col-2"></div>
              </div>
          <div className="w-100 menu-item-scroll" id="accordion" role="tablist" aria-multiselectable="true">
  <div className="card">
    <div className="card-header" role="tab" id="headingOne">
      <div className="row align-items-center">
        <div className="col-4 menu-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Broccoli Crepe
          </a>
        </div>
        <div className="col-2 text-right menu-price pr-0">2X</div>
        <div className="col-2 text-right menu-price pr-0">Rs.200</div>
        <div className="col-2 text-right menu-price pr-0">Rs.400</div>
        <div className="col-2 text-right">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
          />
        </div>
      </div>
    </div>

    <div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
      {/*Menu Item 1*/}
            <div className="col-12">
               <div className="row mt-2">
                 <div className="col-12 menu-taxes">Option</div>
               </div>
               <div className="row mt-2">
                 <div className="col-7 menu-variant mb-2">Cheese</div>
                 <div className="col-3 text-right menu-price pr-0 mb-2">Rs.25.00</div>
                 <div className="col-7 menu-variant mb-2">Avocado</div>
                 <div className="col-3 text-right menu-price pr-0 mb-2">Rs.50.00</div>
               </div>
               <div className="row mt-2">
                 <div className="col-12 menu-taxes">Add-Ons</div>
               </div>
               <div className="row mt-2">
                 <div className="col-7 menu-variant  mb-2">Cheese</div>
                 <div className="col-3 text-right menu-price pr-0 mb-2">Rs.25.00</div>
                 <div className="col-2 text-right mb-2">
                   <Switch
                     checkedChildren={<CheckOutlined />}
                     unCheckedChildren={<CloseOutlined />}
                     defaultChecked
                   />
                 </div>
                 <div className="col-7 menu-variant  mb-2">Avocado</div>
                 <div className="col-3 text-right menu-price pr-0 mb-2">Rs.50.00</div>
                 <div className="col-2 text-right mb-2">
                   <Switch
                     checkedChildren={<CheckOutlined />}
                     unCheckedChildren={<CloseOutlined />}
                     defaultChecked
                   />
                 </div>
               </div>
            </div>
            {/*Menu Item 1*/}
    </div>
  </div>
  <div className="card">
    <div className="card-header" role="tab" id="headingTwo">
      <div className="row align-items-center">
        <div className="col-4 menu-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
            Spicy Paneer Crepe
          </a>
        </div>
        <div className="col-2 text-right menu-price pr-0">1X</div>
        <div className="col-2 text-right menu-price pr-0">Rs.230</div>
        <div className="col-2 text-right menu-price pr-0">Rs.340</div>
        <div className="col-2 text-right">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
          />
        </div>
      </div>
    </div>
    <div id="collapseTwo" className="collapse" role="tabpanel" aria-labelledby="headingTwo">
      {/*Menu Item 1*/}
            <div className="col-12">
               <div className="row mt-2">
                 <div className="col-12 menu-taxes">Option</div>
               </div>
               <div className="row mt-2">
                 <div className="col-7 menu-variant mb-2">Cheese</div>
                 <div className="col-3 text-right menu-price mb-2">Rs.25.00</div>
                 <div className="col-7 menu-variant mb-2">Avocado</div>
                 <div className="col-3 text-right menu-price mb-2">Rs.50.00</div>
               </div>
               <div className="row mt-2">
                 <div className="col-12 menu-taxes">Add-Ons</div>
               </div>
               <div className="row mt-2">
                 <div className="col-7 menu-variant  mb-2">Cheese</div>
                 <div className="col-3 text-right menu-price  mb-2">Rs.25.00</div>
                 <div className="col-2 text-right mb-2">
                   <Switch
                     checkedChildren={<CheckOutlined />}
                     unCheckedChildren={<CloseOutlined />}
                     defaultChecked
                   />
                 </div>
                 <div className="col-7 menu-variant  mb-2">Avocado</div>
                 <div className="col-3 text-right menu-price  mb-2">Rs.50.00</div>
                 <div className="col-2 text-right mb-2">
                   <Switch
                     checkedChildren={<CheckOutlined />}
                     unCheckedChildren={<CloseOutlined />}
                     defaultChecked
                   />
                 </div>
               </div>
            </div>
            {/*Menu Item 1*/}
    </div>
  </div>
  <div className="card">
    <div className="card-header" role="tab" id="headingThree">
      <div className="row align-items-center">
        <div className="col-4 menu-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
            Avocado Crepe
          </a>
        </div>
        <div className="col-2 text-right menu-price pr-0">1X</div>
        <div className="col-2 text-right menu-price pr-0">Rs.60</div>
        <div className="col-2 text-right menu-price pr-0">Rs.170</div>
        <div className="col-2 text-right">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
          />
        </div>
      </div>
    </div>
    <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree">
      {/*Menu Item 1*/}
            <div className="col-12">
               <div className="row mt-2">
                 <div className="col-12 menu-taxes">Option</div>
               </div>
               <div className="row mt-2">
                 <div className="col-7 menu-variant mb-2">Cheese</div>
                 <div className="col-3 text-right menu-price mb-2">Rs.25.00</div>
                 <div className="col-7 menu-variant mb-2">Avocado</div>
                 <div className="col-3 text-right menu-price mb-2">Rs.50.00</div>
               </div>
               <div className="row mt-2">
                 <div className="col-12 menu-taxes">Add-Ons</div>
               </div>
               <div className="row mt-2">
                 <div className="col-7 menu-variant  mb-2">Cheese</div>
                 <div className="col-3 text-right menu-price  mb-2">Rs.25.00</div>
                 <div className="col-2 text-right mb-2">
                   <Switch
                     checkedChildren={<CheckOutlined />}
                     unCheckedChildren={<CloseOutlined />}
                     defaultChecked
                   />
                 </div>
                 <div className="col-7 menu-variant  mb-2">Avocado</div>
                 <div className="col-3 text-right menu-price  mb-2">Rs.50.00</div>
                 <div className="col-2 text-right mb-2">
                   <Switch
                     checkedChildren={<CheckOutlined />}
                     unCheckedChildren={<CloseOutlined />}
                     defaultChecked
                   />
                 </div>
               </div>
            </div>
            {/*Menu Item 1*/}
    </div>
  </div>
</div>
          </div>
          <div className="col-lg-5">
              <div className="row  mt-0">
                 <div className="col-6 text-left footer-menu-taxes mb-2">Sub Total</div>
                 <div className="col-2 text-right footer-menu-price mb-2"></div>
                 <div className="col-4 text-right footer-menu-price mb-2">Rs.275.00</div>
                 <div className="col-6 text-left footer-menu-taxes mb-2">Discount @ 15%</div>
                 <div className="col-2 text-right footer-menu-price mb-2">(-)</div>
                 <div className="col-4 text-right footer-menu-price mb-2">Rs.198.00</div>
                 <div className="col-6 text-left footer-menu-taxes mb-2">HopPoints Redeem Request</div>
                 <div className="col-2 text-right footer-menu-price mb-2">(-)</div>
                 <div className="col-4 text-right footer-menu-price mb-2">Rs.90.00</div>
                 <div className="col-6 text-left footer-menu-taxes mb-2">GST</div>
                 <div className="col-2 text-right footer-menu-price mb-2">(+)</div>
                 <div className="col-4 text-right footer-menu-price mb-2">Rs.35.06</div>
                 <div className="col-6 text-left footer-menu-taxes mb-2">Packing charges</div>
                 <div className="col-2 text-right footer-menu-price mb-2">(+)</div>
                 <div className="col-4 text-right footer-menu-price mb-2">Rs.76.50</div>
                 <div className="col-6 text-left footer-menu-taxes mb-2">Delivery charges</div>
                 <div className="col-2 text-right footer-menu-price mb-2">(+)</div>
                 <div className="col-4 text-right footer-menu-price mb-2">Rs.76.50</div>
                 <div className="col-6 text-left footer-menu-total mb-2">Total</div>
                 <div className="col-2 text-right footer-menu-price mb-2">(+)</div>
                 <div className="col-4 text-right footer-menu-total mb-2">Rs.1275.00</div>
               </div>
               <div className="border-divided"></div>
               <div className="row  mt-2">
                 <div className="col-12 text-left footer-menu-taxes mb-2">
                 Special Instruction : 
                 <span className="text-blue ml-1">Don't add Butter to Burger</span>
                 <span className="text-red  ml-1 d-none">Don't add Butter to Burger</span>
                 </div>
                 <div className="col-12 text-left">
                   <div className="row">
                     <div className="col-6 mb-3">
                       <div className="footer-menu-taxes">Number of Guests</div>
                     </div>
                     <div className="col-6 mb-3">
                       <div className="footer-menu-taxes">6</div>
                     </div>
                     <div className="col-6 mt-1">
                       <div className="form-group footer-menu-taxes">
                           <DatePicker disabled />
                       </div>
                     </div>
                     <div className="col-6 mt-1">
                       <div className="guest-time-picker ">
                         <TimePicker className='min-width-box' style={{ width: '100%' }} onChange={onChange} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
                       </div>
                     </div>
                     <div className="col-12">
                       <div className="form-group footer-menu-taxes">
                         <TextArea rows={2} onChange={this.onCommentsChange} placeholder="Note to Guest" />
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="col-12 text-left footer-menu-taxes mb-2">
                   <span><u>WARNING :</u></span> <span>Customer is allergic to</span> <span className="badge">Fish</span> <span className="badge">Gluten</span> <span className="badge">Peanut</span>
                   <span>Customer prefers</span> <span className="badge">Veg</span> <span className="badge">Jain</span> <span className="badge">Egg</span>
                 </div>
                 <div className="col-12 text-center footer-menu-taxes mb-2 mt-2">
                   <button className="btn btn-deny text-uppercase mr-2">Deny</button>
                   <button className="btn btn-reorder text-uppercase mr-2">Confirm order</button>
                   <button className="btn btn-accept text-uppercase mr-2">Accept</button>
                   <button className="btn btn-waiting text-uppercase d-none">Waiting for payment</button>
                 </div>
                 <div className="col-12 text-center mb-2 mt-2">
                   <div className="row">
                     <div className="col-12 text-uppercase footer-text-paid">Paid!</div>
                   </div>
                   <div className="row align-items-center justify-content-center">
                     <div className="col-4 text-left footer-payment">Payment Mode</div>
                     <div className="col-4 text-left footer-payment">Google Pay</div>
                   </div>
                   <div className="row align-items-center justify-content-center mb-3">
                     <div className="col-4 text-left footer-payment">Transaction ID</div>
                     <div className="col-4 text-left footer-payment">45376GTR3B76JD</div>
                   </div>
                   <div className="row align-items-center justify-content-center">
                     <button className="btn btn-print text-uppercase">Print Kot</button>
                   </div>
                 </div>
               </div>
          </div>

          </div>
        </Modal>
      </div>
    	</div>
        );
    }
}
export default OrderModel