import React from "react";
import { connect } from "react-redux";
import "../order-model/order-model.css";
import {
  Select,
  Input,
  Modal,
  Button,
  notification,
  Table,
  Switch,
  Collapse,
  Form,
  Spin,
  Icon,
  DatePicker,
  TimePicker,
  Radio,
} from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";
import agent from "../../agent";
import globalVariable from "../../config/generalConfig";
import {
  CONFIRM_ORDERS,
  CHANGE_ORDER_STATUS,
  GET_ORDERS,
  GET_USER_ORDER_DETAILS,
  COMPUTE_CONFIRM,
  CHANGE_ORDER_STATUS_VALUE,
} from "../../constants/actionTypes";
import AppReUsableComponents from "../../components/re-usable";
import { handleSound } from "../../components/re-usable/socket-connection";
const dateFormatList = ["DD-MM-YYYY", "DD/MM/YY"];
const FormItem = Form.Item;
const { TextArea } = Input;
const { confirm } = Modal;
class OrderModel extends React.Component {
  state = {
    current_selected_customer_order_details: {},
    food_preference: {
      veg: 0,
      jain: 1,
      egg: 2,
      vegan: 3,
    },
    deny_modal_data: {
      confirm: false,
      status_glossary: "We are unable to service at the moment.",
      status_glossary_not_provided_message:
        "Restaraunt did n't provide any comments about the cancellation of order",
    },
    modal_spinner: false,
    delivery_type: [
      { label: "Self Delivery", value: 0 },
      { label: "Assisted Delivery", value: 1 },
    ],
    visible: false,
    kotModalVisible: false,
  };

  setOrderModal = (value) => {
    this.props.setOrderModal(value);
    this.hanldeStop();
  };

  previousOrderDetails = {};
  componentDidUpdate(prevsProps, prevState) {
    if (
      !_.isEqual(
        _.get(prevsProps, "userDetails.details", {}),
        _.get(this, "props.userDetails.details", {})
      ) ||
      (!_.isEmpty(_.get(this, "props.userDetails.details", {})) &&
        _.isEmpty(
          _.get(this, "state.current_selected_customer_order_details", {})
        ))
    ) {
      this.props.form.resetFields();
      let modifiedUserOrderDetails = _.isEmpty(
        localStorage.getItem("modifiedUserOrderDetails")
      )
        ? []
        : JSON.parse(localStorage.getItem("modifiedUserOrderDetails"));
      const orderDetailsIndex = _.findIndex(modifiedUserOrderDetails, [
        "id",
        _.get(this, "props.userDetails.details.id"),
      ]);
      console.log(
        "Order modal current order details: ",
        _.get(this, "props.userDetails.details")
      );
      console.log(
        "Order modal current current_selected_customer_order_details: ",
        _.get(this, "state.current_selected_customer_order_details", {})
      );
      if (
        orderDetailsIndex > -1 &&
        _.isEqual(_.get(this, "props.userDetails.details.status"), 2)
      ) {
        this.setState(
          {
            current_selected_customer_order_details:
              modifiedUserOrderDetails[orderDetailsIndex],
          },
          () =>
            this.setFormItemValue(
              _.get(this, "state.current_selected_customer_order_details")
            )
        );
      } else {
        this.setState(
          {
            current_selected_customer_order_details: _.get(
              this,
              "props.userDetails.details"
            ),
          },
          () =>
            this.setFormItemValue(
              _.get(this, "state.current_selected_customer_order_details")
            )
        );
      }
    }
  }
  getSpecificCoreOrderDetails = (order_details, key) => {
    switch (key) {
      // set current selected main items count
      case "selected_items_count":
        return _.size(_.filter(_.get(order_details, "items"), "is_available"));
      case "gst_calculation":
        let individual_item_gst = _.map(
          _.filter(_.get(order_details, "items"), "is_available"),
          (item) =>
            _.multiply(
              _.divide(
                _.subtract(
                  _.toNumber(_.get(item, "sub_total", 0)),
                  _.add(
                    _.toNumber(_.get(item, "discount_amount", null)),
                    _.toNumber(_.get(item, "redeem_amount", null))
                  )
                ),
                100
              ),
              _.add(
                _.toNumber(_.get(item, "cgst", 0)),
                _.toNumber(_.get(item, "sgst", 0))
              )
            )
        );
        return {
          value: _.sum(individual_item_gst),
          percent: _.multiply(
            _.divide(_.toNumber(_.get(order_details, "sub_total", 0)), 100),
            _.sum(individual_item_gst)
          ),
        };
      default:
        return null;
    }
  };
  orderTopConatinerDetailsSet = (order) => {
    return !_.isEmpty(order) ? (
      <div
        className="customer-details-model-header"
        key={_.get(order, "id", "order_id")}
      >
        <div className="customer-details-model-title">Order Details</div>
        <div className="user-info-set-container">
          <div className="user-info-set">
            <span className="user-info-name">
              <b>
                {_.join(
                  _.compact(
                    _.values(
                      _.pick(_.get(order, "user", {}), [
                        "first_name",
                        "last_name",
                      ])
                    )
                  ),
                  " "
                )}
              </b>
            </span>
            <span className="user-info-details">
              {_.get(order, "user.phone", "")}{" "}
              {!_.isEmpty(_.get(order, "location_text", ""))
                ? `| ${_.capitalize(_.get(order, "location_text"))}`
                : ""}
            </span>
          </div>
          <div className="user-info-set">
            <span className="user-info-name">
              {_.get(order, "type") != 1 &&
                !_.isEmpty(_.get(order, "order_requested_time", "")) ? (
                <b>
                  {moment(_.get(order, "created_at")).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </b>
              ) : (
                <b>
                  {moment(_.get(order, "created_at")).format("MMMM Do YYYY")}{" "}
                  Expected Take-away: {_.get(order, "order_requested_time", "")}
                </b>
              )}
            </span>
            <span className="user-info-details">
              Order Type:{" "}
              {(() => {
                if (_.isEqual(_.get(order, "type"), 0)) {
                  return <b>Dine-in</b>;
                } else if (_.isEqual(_.get(order, "type"), 1)) {
                  return <b>Take-away</b>;
                } else if (_.isEqual(_.get(order, "type"), 2)) {
                  return <b>Delivery</b>;
                }
              })()}{" "}
              {!_.isEmpty(_.get(order, "order_id"))
                ? `| OrderId: ${_.get(order, "order_id")}`
                : ""}
            </span>
          </div>
        </div>
      </div>
    ) : null;
  };

  onChangeOrderDetails = (
    itemData = {},
    otherOrderDetails = {},
    api = false
  ) => {
    let items = _.get(
      this,
      "state.current_selected_customer_order_details.items",
      []
    );
    let order_details = _.get(
      this,
      "state.current_selected_customer_order_details",
      {}
    );

    if (
      !_.isEmpty(items) &&
      _.has(itemData, "item") &&
      _.has(itemData, "addon")
    ) {
      let itemIndex = _.findIndex(items, ["id", _.get(itemData, "item")]);
      let addonIndex = _.findIndex(items[itemIndex].addons, [
        "id",
        _.get(itemData, "addon"),
      ]);
      items[itemIndex].addons[addonIndex].is_available = _.get(
        itemData,
        "value"
      );
    } else if (!_.isEmpty(items) && _.has(itemData, "item")) {
      let itemIndex = _.findIndex(items, ["id", _.get(itemData, "item")]);
      items[itemIndex].is_available = _.get(itemData, "value");
    }

    this.setState((prevState) => ({
      current_selected_customer_order_details: {
        ..._.get(prevState, "current_selected_customer_order_details"),
        items,
        ...otherOrderDetails,
      },
    }));
    let filteredItems = [];
    let sendForReorder = false;
    _.forEach(items, (item) => {
      if (_.isEqual(_.get(item, "is_available"), true)) {
        let object = {};
        object.id = _.get(item, "id");
        if (_.has(item, "addons")) {
          object.addons = _.map(
            _.filter(_.get(item, "addons"), (addon) =>
              _.isEqual(_.get(addon, "is_available", true), true)
            ),
            "id"
          );
        }
        filteredItems.push(object);
      }
      if (
        !sendForReorder &&
        (!_.isEqual(_.get(item, "is_available"), true) ||
          (_.has(item, "addons") &&
            _.size(
              _.map(
                _.filter(_.get(item, "addons"), (addon) =>
                  _.isEqual(_.get(addon, "is_available", true), false)
                ),
                "id"
              )
            ) > 0))
      ) {
        sendForReorder = true;
      }
    });

    if (api) {
      return { items: filteredItems, ...otherOrderDetails };
    }

    if (
      !sendForReorder &&
      !_.isEmpty(_.get(otherOrderDetails, "order_time", null)) &&
      !_.isEqual(
        _.get(otherOrderDetails, "order_time"),
        _.get(this, "props.userDetails.details.order_time")
      )
    ) {
      sendForReorder = true;
    }
    if (
      !sendForReorder &&
      !_.isEmpty(_.get(otherOrderDetails, "restaurant_comments", null)) &&
      !_.isEqual(
        _.get(otherOrderDetails, "restaurant_comments"),
        _.get(this, "props.userDetails.details.restaurant_comments")
      )
    ) {
      sendForReorder = true;
    }

    this.props.onComputeConfirmOrder(
      {
        id: _.get(order_details, "id"),
        expand: "discounts,restaurant,items,user,transactions",
      },
      { items: filteredItems, ...otherOrderDetails },
      (success, data) => {
        success
          ? this.storeModifiedUserOrderDetails(data, { sendForReorder })
          : this.storeModifiedUserOrderDetails(
            {
              order: _.get(
                this,
                "state.current_selected_customer_order_details"
              ),
            },
            { sendForReorder }
          );
      }
    );
  };
  storeModifiedUserOrderDetails = (data, additionalParams = {}) => {
    this.setState(
      (prevState) => ({
        current_selected_customer_order_details: {
          ..._.get(prevState, "current_selected_customer_order_details"),
          ..._.omit(_.get(data, "order"), [
            "discounts",
            "restaurant",
            "user",
            "transactions",
            "order_time",
            "restaurant_comments",
          ]),
          ...additionalParams,
        },
      }),
      () => {
        this.setFormItemValue(
          _.get(this, "state.current_selected_customer_order_details")
        );

        let modifiedUserOrderDetails = _.isEmpty(
          localStorage.getItem("modifiedUserOrderDetails")
        )
          ? []
          : JSON.parse(localStorage.getItem("modifiedUserOrderDetails"));
        const orderDetailsIndex = _.findIndex(modifiedUserOrderDetails, [
          "id",
          _.get(this, "state.current_selected_customer_order_details.id"),
        ]);
        if (orderDetailsIndex > -1) {
          modifiedUserOrderDetails[orderDetailsIndex] = _.get(
            this,
            "state.current_selected_customer_order_details"
          );
        } else {
          modifiedUserOrderDetails.push(
            _.get(this, "state.current_selected_customer_order_details")
          );
        }
        localStorage.setItem(
          "modifiedUserOrderDetails",
          JSON.stringify(modifiedUserOrderDetails)
        );
      }
    );
  };
  orderListContainerSet = (order) => {
    return !_.isEmpty(order) ? (
      <div className="col-lg-7">
        <div className="row p-15">
          <div className="col-4">
            <b>Item</b>
          </div>
          <div className="col-2 text-right">
            <b>Qty</b>
          </div>
          <div className="col-2 text-right">
            <b>Rate</b>
          </div>
          <div className="col-2 text-right">
            <b>Amount</b>
          </div>
          <div className="col-2"></div>
        </div>
        <div
          className="w-100 menu-item-scroll"
          id="accordion"
          role="tablist"
          aria-multiselectable="true"
        >
          {/* <Form layout="horizontal"> */}
          {(() => {
            if (_.isEqual(_.get(order, "status"), 2)) {
              return _.get(order, "items", []).map((item, item_id) => (
                <div className="card" key={item_id}>
                  <div className="card-header" role="tab" id="headingOne">
                    <div className="row align-items-center">
                      <div className="col-4 menu-title">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href={`#collapseOne${item_id}`}
                          aria-expanded="true"
                          aria-controls={`collapseOne${item_id}`}
                        >
                          {item.name}
                        </a>
                      </div>
                      <div className="col-2 text-right menu-price">
                        {item.quantity}x
                      </div>
                      <div className="col-2 text-right menu-price pr-0">
                        Rs.
                        {item && item.option && item.option.price
                          ? item.option.price
                          : item.price}
                      </div>
                      <div className="col-2 text-right menu-price pr-0">
                        Rs.{item.sub_total}
                      </div>
                      <div className="col-2 text-right">
                        {order && order.status === 2 ? (
                          <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={_.get(item, "is_available")}
                            onChange={(value) => {
                              this.onChangeOrderDetails({
                                item: _.get(item, "id"),
                                value,
                              });
                            }}
                          // defaultChecked={this.state.orderCheck}
                          // onChange={(checked) => { this.onItemChange(checked); this.retrive(checked, item_id, item, order) }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    id={`collapseOne${item_id}`}
                    className="collapse show"
                    role="tabpanel"
                    aria-labelledby={`headingOne${item_id}`}
                  >
                    {/*Menu Item 1*/}
                    <div className="col-12">
                      {(() => {
                        if (item && item.option) {
                          return (
                            <span>
                              <div className="row mt-2">
                                <div className="col-12 menu-taxes">Option</div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-7 menu-variant mb-2">
                                  {item.option.name}
                                </div>
                                <div className="col-3 text-right menu-price mb-2 pr-0">
                                  Rs.{item.option.price}
                                </div>
                              </div>
                            </span>
                          );
                        }
                      })()}
                      {item && item.addons ? (
                        <div className="row mt-2">
                          <div className="col-12 menu-taxes">Add-Ons</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {(() => {
                        // for(var i=0;i>=order.length;i++){
                        if (item && item.addons) {
                          return (
                            item &&
                            item.addons &&
                            item.addons.map((addon, addon_id) => {
                              if (order && order.status === 2) {
                                return (
                                  <div className="row mt-2" key={addon_id}>
                                    <div className="col-7 menu-variant  mb-2">
                                      {addon.name}
                                    </div>
                                    <div className="col-3 text-right menu-price pr-0 mb-2">
                                      Rs.{addon.price ? addon.price : 0}
                                    </div>
                                    <div className="col-2 mb-2 text-right">
                                      <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={_.get(
                                          addon,
                                          "is_available",
                                          true
                                        )}
                                        disabled={!_.get(item, "is_available")}
                                        onChange={(value) => {
                                          this.onChangeOrderDetails({
                                            item: _.get(item, "id"),
                                            addon: _.get(addon, "id"),
                                            value,
                                          });
                                        }}
                                      // onChange={(checked) => this.addons(checked, addon_id, addon, item.addons, item.id, order)}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })
                          );
                        }
                        // }
                      })()}
                    </div>
                    {/*Menu Item 1*/}
                  </div>
                </div>
              ));
            } else if (order && order.status != 2) {
              /*Order status changes*/
              return (
                order.items &&
                order.items.map((item, item_id) => {
                  if (item && item.is_available === true) {
                    return (
                      <div className="card" key={item_id}>
                        <div className="card-header" role="tab" id="headingOne">
                          <div className="row align-items-center">
                            <div className="col-4 menu-title">
                              <a
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href={`#collapseOne${item_id}`}
                                aria-expanded="true"
                                aria-controls={`collapseOne${item_id}`}
                              >
                                {item.name}
                              </a>
                            </div>
                            <div className="col-2 text-right menu-price">
                              {item.quantity}x
                            </div>
                            <div className="col-2 text-right menu-price pr-0">
                              Rs.{item.price}
                            </div>
                            <div className="col-2 text-right menu-price pr-0">
                              Rs.{item.sub_total}
                            </div>
                            <div className="col-2"></div>
                          </div>
                        </div>
                        <div
                          id={`collapseOne${item_id}`}
                          className="collapse show"
                          role="tabpanel"
                          aria-labelledby={`headingOne${item_id}`}
                        >
                          {/*Menu Item 1*/}
                          <div className="col-12">
                            {(() => {
                              if (item && item.option) {
                                return (
                                  <span>
                                    <div className="row mt-2">
                                      <div className="col-12 menu-taxes">
                                        Option
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-7 menu-variant mb-2">
                                        {item.option.name}
                                      </div>
                                      <div className="col-3 text-right menu-price pr-0 mb-2">
                                        Rs.{item.option.price}
                                      </div>
                                    </div>
                                  </span>
                                );
                              }
                            })()}
                            {item && item.addons ? (
                              <div className="row mt-2">
                                <div className="col-12 menu-taxes">Add-Ons</div>
                              </div>
                            ) : (
                              ""
                            )}
                            {(() => {
                              if (item && item.addons) {
                                return item.addons.map((addon, addon_id) => {
                                  if (addon && addon.is_available === true) {
                                    return (
                                      <div className="row mt-2" key={addon_id}>
                                        <div className="col-7 menu-variant  mb-2">
                                          {addon.name}
                                        </div>
                                        <div className="col-3 text-right menu-price pr-0 mb-2">
                                          Rs.{addon.price ? addon.price : 0}
                                        </div>
                                        <div className="col-2 mb-2"></div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="row mt-2" key={addon_id}>
                                        <div className="col-7 menu-variant text-line-through mb-2">
                                          {addon.name}
                                        </div>
                                        <div className="col-3 text-right menu-price text-line-through pr-0 mb-2">
                                          Rs.{addon.price ? addon.price : 0}
                                        </div>
                                        <div className="col-2 mb-2"></div>
                                      </div>
                                    );
                                  }
                                });
                              }
                            })()}
                          </div>
                          {/*Menu Item 1*/}
                        </div>
                      </div>
                    );
                  }
                })
              );
            } else {
              return (
                <div className="example">
                  <Spin />
                </div>
              );
            }
          })()}
          {/* </Form> */}
        </div>
      </div>
    ) : null;
  };

  orderCostContainerSet = (order) => {
    return (
      <React.Fragment>
        {/* Sub total section */}
        <div className="row  mt-0">
          <div className="col-6 text-left footer-menu-taxes mb-2">
            Sub Total
          </div>
          <div className="col-2 text-right footer-menu-price mb-2"></div>
          <div className="col-4 text-right footer-menu-price mb-2">
            Rs.{parseFloat(_.get(order, "sub_total", 0)).toFixed(2)}
          </div>
        </div>

        {/* GST section */}
        {(() => {
          const gst = this.getSpecificCoreOrderDetails(
            order,
            "gst_calculation"
          );
          return !_.isEmpty(_.toString(_.get(order, "sub_total", null))) ? (
            <div className="row  mt-0">
              <div className="col-6 text-left footer-menu-taxes mb-2">GST</div>
              <div className="col-2 text-right footer-menu-price mb-2">(+)</div>
              <div className="col-4 text-right footer-menu-price mb-2">
                Rs.{parseFloat(_.get(gst, "value", 0)).toFixed(2)}
              </div>
            </div>
          ) : null;
        })()}

        {/* Packing charges section */}
        {!_.isEmpty(_.toString(_.get(order, "packing_charges", null))) ? (
          <div className="row  mt-0">
            <div className="col-6 text-left footer-menu-taxes mb-2">
              Packing charges
            </div>
            <div className="col-2 text-right footer-menu-price mb-2">(+)</div>
            <div className="col-4 text-right footer-menu-price mb-2">
              Rs.{parseFloat(_.get(order, "packing_charges")).toFixed(2)}
            </div>
          </div>
        ) : null}

        {/* Delivery charges section */}
        {_.toNumber(_.get(order, "delivery_charges", null)) > 0 ? (
          <div className="row  mt-0">
            <div className="col-6 text-left footer-menu-taxes mb-2">
              Delivery charges
            </div>
            <div className="col-2 text-right footer-menu-price mb-2">(+)</div>
            <div className="col-4 text-right footer-menu-price mb-2">
              Rs.{parseFloat(_.get(order, "delivery_charges")).toFixed(2)}
            </div>
          </div>
        ) : null}

        {/* Discount section */}
        {!_.isEmpty(_.toString(_.get(order, "discount_amount", null))) ? (
          <div className="row  mt-0">
            <div className="col-6 text-left footer-menu-taxes mb-2">
              Discount
            </div>
            <div className="col-2 text-right footer-menu-price mb-2">(-)</div>
            <div className="col-4 text-right footer-menu-price mb-2">
              Rs.{parseFloat(_.get(order, "discount_amount")).toFixed(2)}
            </div>
          </div>
        ) : null}

        {/* Redeem amount section */}
        {!_.isEmpty(_.toString(_.get(order, "redeem_amount", null))) ? (
          <div className="row  mt-0">
            <div className="col-6 text-left footer-menu-taxes mb-2">
              HopPoints Redeem Request
            </div>
            <div className="col-2 text-right footer-menu-price mb-2">(-)</div>
            <div className="col-4 text-right footer-menu-price mb-2">
              Rs.{parseFloat(_.get(order, "redeem_amount")).toFixed(2)}
            </div>
          </div>
        ) : null}

        {/* Total section */}
        {!_.isEmpty(_.toString(_.get(order, "total", null))) ? (
          <div className="row  mt-0">
            <div className="col-6 text-left footer-menu-taxes mb-2">Total</div>
            <div className="col-2 text-right footer-menu-price mb-2"></div>
            <div className="col-4 text-right footer-menu-price mb-2">
              Rs.{parseFloat(_.get(order, "total")).toFixed(2)}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  order_time_validator = (rule, value, callback) => {
    let start_time = moment().format("HH:mm");
    let order_time = moment(value).format("HH:mm");
    if (start_time >= order_time) {
      callback("Set time should  be greater than the current time");
    } else {
      if (!_.isEmpty(value)) {
        this.onChangeOrderDetails({}, { order_time });
      }
      callback();
    }
  };
  hanldeStop = () => {
    handleSound();
  };

  rightBottomContainerSet = (order) => {
    const { getFieldDecorator } = this.props.form;
    const customerDetails = _.get(this, "props.customerDetails.details", {});
    const gst = this.getSpecificCoreOrderDetails(order, "gst_calculation");

    return !_.isEmpty(order) ? (
      <div className="row  mt-2">
        {/* Special Instruction Section */}
        <div className="col-12 text-left footer-menu-taxes mb-2">
          Special Instruction :
          <span className="text-blue ml-1">
            {!_.isEmpty(_.get(order, "comments", null))
              ? _.get(order, "comments")
              : "-"}
          </span>
        </div>

        <div className="col-12 text-left">
          <div className="row">
            {/* Number of Guests Section */}
            <div className="col-6 mb-3">
              <div className="footer-menu-taxes">
                Number of Guests :{" "}
                {!_.isEmpty(_.toString(_.get(order, "table_for", null)))
                  ? _.get(order, "table_for")
                  : "-"}
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="footer-menu-taxes"></div>
            </div>
            {/* Order created date Section */}
            <div className="col-6 mt-1">
              <div className="form-group footer-menu-taxes">
                <FormItem>
                  {getFieldDecorator("date")(
                    <DatePicker disabled format={dateFormatList} />
                  )}
                </FormItem>
              </div>
            </div>

            <div className="col-6">
              <div className="guest-time-picker ">
                {
                  <FormItem>
                    {getFieldDecorator("order_time", {
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "please select time",
                        },
                        { validator: this.order_time_validator },
                      ],
                    })(
                      <TimePicker
                        format="HH:mm"
                        className="min-width-box"
                        style={{ width: "100%" }}
                        disabled={
                          _.isEqual(_.get(order, "status", null), 2)
                            ? false
                            : true
                        }
                      />
                    )}
                  </FormItem>
                }
              </div>
            </div>

            <div className="col-12">
              <div className="form-group footer-menu-taxes">
                {_.isEqual(_.get(order, "type"), 2) &&
                  _.isEqual(_.get(order, "status"), 2) ? (
                  <FormItem label="Delivery Type:">
                    {getFieldDecorator("delivery_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please select the delivery type!",
                        },
                      ],
                    })(
                      <Radio.Group
                        onChange={(event) => {
                          this.onChangeOrderDetails(
                            {},
                            { delivery_type_1: _.get(event, "target.value") }
                          );
                        }}
                        disabled={
                          _.isEqual(_.get(order, "status", null), 2)
                            ? false
                            : true
                        }
                      >
                        {_.map(
                          _.get(this, "state.delivery_type"),
                          (item, key) => (
                            <Radio value={item.value} key={key}>
                              {item.label}
                            </Radio>
                          )
                        )}
                      </Radio.Group>
                    )}
                  </FormItem>
                ) : null}
                {_.isEqual(_.get(order, "type"), 2) &&
                  _.indexOf([1, 5, 8, 9], _.get(order, "status")) > -1 ? (
                  <div>
                    Delivery Type:{" "}
                    <span className="font-weight-light">
                      {_.get(
                        _.find(_.get(this, "state.delivery_type"), [
                          "value",
                          _.get(order, "delivery_type", null),
                        ]),
                        "label",
                        "-"
                      )}
                    </span>
                  </div>
                ) : null}
                <FormItem>
                  {getFieldDecorator("restaurant_comments")(
                    <TextArea
                      onBlur={(event) => {
                        this.onChangeOrderDetails(
                          {},
                          { restaurant_comments: _.get(event, "target.value") }
                        );
                      }}
                      rows={2}
                      placeholder="Note to Guest"
                      disabled={
                        _.isEqual(_.get(order, "status", null), 2)
                          ? false
                          : true
                      }
                    />
                  )}
                </FormItem>
              </div>
            </div>
          </div>
        </div>

        {/* Custsomer Allergies and food preference section */}
        <div className="col-12 text-left footer-menu-taxes mb-2">
          {!_.isEmpty(_.get(customerDetails, "details.allergies", null)) ||
            !_.isEmpty(
              _.get(customerDetails, "details.food_preference", null)
            ) ? (
            <span>
              <u>WARNING : </u>
            </span>
          ) : (
            ""
          )}
          <span>
            {!_.isEmpty(
              _.get(customerDetails, "details.food_preference", null)
            ) ? (
              <div className="b-1">
                CUSTOMER FOOD PREFERENCE:{" "}
                {_.map(
                  _.get(customerDetails, "details.food_preference"),
                  (value, key) => (
                    <span className="badge" key={key}>
                      {
                        _.invert(_.get(this, "state.food_preference", {}))[
                        _.toInteger(value)
                        ]
                      }
                    </span>
                  )
                )}
              </div>
            ) : (
              ""
            )}{" "}
            {!_.isEmpty(_.get(customerDetails, "details.allergies", null)) ? (
              <div className="b-1">
                CUSTOMER IS ALLERGIC TO :{" "}
                {_.map(
                  _.get(customerDetails, "details.allergies"),
                  (allergy, allergy_id) => (
                    <span className="badge" key={allergy_id}>
                      {_.get(allergy, "name", "allergy name is not mentioned")}
                    </span>
                  )
                )}
              </div>
            ) : (
              ""
            )}
          </span>
        </div>

        {/* Transactions and order accept section */}
        <div className="col-12 text-center footer-menu-taxes mb-2 mt-2">
          {(() => {
            if (
              _.isEqual(_.get(order, "status"), 1) ||
              _.isEqual(_.get(order, "status"), 8) ||
              _.isEqual(_.get(order, "status"), 9)
            ) {
              return !_.isEmpty(_.get(order, "transactions", null))
                ? _.map(_.get(order, "transactions"), (trans, trans_id) => (
                  <div
                    className="col-12 text-center mb-2 mt-2"
                    key={trans_id}
                  >
                    {/* {console.log("list data", trans)} */}
                    <div className="row">
                      <div
                        className="col-12 text-uppercase footer-text-paid"
                        style={{
                          color:
                            order.status === 7 || order.status === 8
                              ? "red"
                              : "",
                        }}
                      >
                        {order.status === 7 || order.status === 8
                          ? "Cancelled"
                          : "Paid!"}
                      </div>
                    </div>

                    <div className="row align-items-center justify-content-center">
                      <div className="col-6 text-left footer-payment">
                        Payment Mode
                      </div>
                      <div className="col-6 text-left footer-payment">
                        {order.payment_mode === 1 ?
                          <strong>COD</strong>
                          :
                          <strong>{_.get(trans, 'payment_object.method', "-")}</strong>
                        }
                      </div>
                    </div>

                    <div className="row align-items-center justify-content-center mb-3 mt-2">
                      <div className="col-6 text-left footer-payment">
                        Transaction ID
                      </div>
                      <div className="col-6 text-left footer-payment">
                        <strong>
                          {_.get(trans, 'payment_object.id', "-")}
                        </strong>
                      </div>
                    </div>
                    {order.status !== 8 && (
                      <>
                        {order.status !== 7 &&
                          <div className="row align-items-center justify-content-center no-printme">
                            {this.renderOrderStatusBtn(order)}
                            {/* <button className="btn btn-print text-uppercase ml-5 mb-3" onClick={()=>this.hanldeStop()}>Prepare</button> */}
                            <button
                              className="btn btn-print text-uppercase ml-5 mb-3"
                              onClick={() => this.showModal()}
                            >
                              Print Bill
                            </button>
                            <button
                              className="btn btn-print text-uppercase ml-5 mb-3"
                              onClick={() => this.showKOTModal()}
                            >
                              Print Kot
                            </button>
                            <Modal
                              className="order-detail-modal"
                              title={[
                                <div
                                  className="customer-details-model-header"
                                  key={Math.random()}
                                >
                                  <div className="customer-details-model-title text-center">
                                    Hopsticks
                                  </div>
                                  <div className="user-info-set-kot-container">
                                    <div className="user-info-set">
                                      <span className="user-info-details">
                                        <strong>
                                          {!_.isEmpty(_.get(order, "order_id"))
                                            ? ` Order Id : ${_.get(
                                              order,
                                              "order_id"
                                            )}`
                                            : ""}
                                        </strong>
                                      </span>
                                      <span className="user-info-details">
                                        <strong>
                                          {_.get(order, "type") != 1 &&
                                            !_.isEmpty(
                                              _.get(
                                                order,
                                                "order_requested_time",
                                                ""
                                              )
                                            ) ? (
                                            <b>
                                              {moment(
                                                _.get(order, "created_at")
                                              ).format(
                                                "MMMM Do YYYY, h:mm:ss a"
                                              )}
                                            </b>
                                          ) : (
                                            <b>
                                              {moment(
                                                _.get(order, "created_at")
                                              ).format("MMMM Do YYYY")}
                                              {", "}Expected Take-away:{" "}
                                              {_.get(
                                                order,
                                                "order_requested_time",
                                                ""
                                              )}
                                            </b>
                                          )}
                                        </strong>
                                      </span>
                                      <span className="user-info-details">
                                        <strong>KOT</strong>
                                      </span>
                                      <span className="user-info-details">
                                        <strong>
                                          Order Type :{" "}
                                          {(() => {
                                            if (
                                              _.isEqual(_.get(order, "type"), 0)
                                            ) {
                                              return <b>Dining</b>;
                                            } else if (
                                              _.isEqual(_.get(order, "type"), 1)
                                            ) {
                                              return <b>Takeaway</b>;
                                            } else if (
                                              _.isEqual(_.get(order, "type"), 2)
                                            ) {
                                              return <b>Delivery</b>;
                                            }
                                          })()}{" "}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>,
                              ]}
                              width="400px"
                              visible={this.state.kotModalVisible}
                              onOk={this.kotHandleOk}
                              onCancel={this.kotHandleCancel}
                              footer={null}
                            >
                              <table className="print-bill-table">
                                <thead>
                                  <tr>
                                    <th>
                                      <strong>Item</strong>
                                    </th>
                                    <th className="text-right">
                                      <strong>Qty</strong>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order.items &&
                                    order.items.map((ord, ord_id) => {
                                      return (
                                        <tr key={Math.random()}>
                                          <td>
                                            <strong>{ord.name}</strong>
                                          </td>
                                          <td className="text-right">
                                            <strong>{ord.quantity}x</strong>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                              {/* <p class="centered">Thanks for your purchase!</p> */}
                              <div className="mt-4">
                                <button
                                  id="btnPrint"
                                  className="btn btn-primary text-uppercase hidePrint"
                                  onClick={() => this.printOrderModal()}
                                >
                                  Print
                                </button>
                              </div>
                            </Modal>
                            <Modal
                              className="order-detail-modal"
                              width="400px"
                              title={[
                                //  console.log("order", order),

                                <div
                                  className="customer-details-model-header"
                                  key={_.get(order, "id", "order_id")}
                                >
                                  <div className="customer-details-model-title text-center">
                                    Hopsticks
                                  </div>
                                  <div className="user-info-set-bill-container">
                                    <div className="user-info-set">
                                      <span className="user-info-details fw-bold">
                                        {!_.isEmpty(_.get(order, "order_id"))
                                          ? ` Order Id : ${_.get(
                                            order,
                                            "order_id"
                                          )}`
                                          : ""}
                                      </span>
                                      <span className="user-info-details">
                                        {_.get(order, "type") != 1 &&
                                          !_.isEmpty(
                                            _.get(
                                              order,
                                              "order_requested_time",
                                              ""
                                            )
                                          ) ? (
                                          <b>
                                            {moment(
                                              _.get(order, "created_at")
                                            ).format("MMMM Do YYYY, h:mm:ss a")}
                                          </b>
                                        ) : (
                                          <b>
                                            {moment(
                                              _.get(order, "created_at")
                                            ).format("MMMM Do YYYY")}
                                            {", "}Expected Take-away:{" "}
                                            {_.get(
                                              order,
                                              "order_requested_time",
                                              ""
                                            )}
                                          </b>
                                        )}
                                      </span>
                                      <span className="user-info-details fw-bold">
                                        Order Type :{" "}
                                        {(() => {
                                          if (
                                            _.isEqual(_.get(order, "type"), 0)
                                          ) {
                                            return <b>Dining</b>;
                                          } else if (
                                            _.isEqual(_.get(order, "type"), 1)
                                          ) {
                                            return <b>Takeaway</b>;
                                          } else if (
                                            _.isEqual(_.get(order, "type"), 2)
                                          ) {
                                            return <b>Delivery</b>;
                                          }
                                        })()}{" "}
                                      </span>
                                      <span className="user-info-name">
                                        <b>
                                          {_.join(
                                            _.compact(
                                              _.values(
                                                _.pick(
                                                  _.get(order, "user", {}),
                                                  ["first_name", "last_name"]
                                                )
                                              )
                                            ),
                                            " "
                                          )}
                                        </b>
                                      </span>
                                      <span className="user-info-details">
                                        {!_.isEmpty(_.get(order, "comments"))
                                          ? `Special Instruction : ${_.get(
                                            order,
                                            "comments"
                                          )}`
                                          : ""}
                                      </span>
                                      <span className="user-info-details">
                                        {!_.isEmpty(_.get(order, "table_for"))
                                          ? `No.of Guests : ${_.get(
                                            order,
                                            "table_for"
                                          )}`
                                          : ""}
                                      </span>
                                      {/* <span className="user-info-details">
                                       {_.get(order, 'user.phone', "")}{" "}{!_.isEmpty(_.get(order, 'location_text', "")) ? `| ${_.capitalize(_.get(order, 'location_text'))}` : ""}
                                   </span> */}
                                    </div>
                                  </div>
                                </div>,
                              ]}
                              visible={this.state.visible}
                              onOk={this.handleOk}
                              onCancel={this.handleCancel}
                              footer={null}
                            >
                              <table className="print-bill-table">
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Item</b>
                                    </th>
                                    <th className="text-right">
                                      <b>Qty</b>
                                    </th>
                                    <th className="text-right">
                                      <b>Rate</b>
                                    </th>
                                    <th className="text-right">
                                      <b>Amount</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order.items &&
                                    order.items.map((ord, ord_id) => {
                                      return (
                                        <React.Fragment key={ord_id}>
                                          <tr>
                                            <td className="fw-bold">
                                              {ord.name}
                                            </td>
                                            <td className="text-right fw-bold">
                                              {ord.quantity}x
                                            </td>
                                            <td className="text-right fw-bold">
                                              Rs.
                                              {parseFloat(ord.price).toFixed(2)}
                                            </td>
                                            <td className="text-right fw-bold">
                                              Rs.
                                              {parseFloat(
                                                ord.sub_total
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                          {_.get(ord, "option") &&
                                            _.get(ord, "option") != null ? (
                                            <tr key={_.get(ord, "option.id")}>
                                              <td className="pl-2 fw-bold">
                                                {_.get(ord, "option.name")}
                                              </td>
                                              <td className="text-right fw-bold"></td>
                                              <td className="text-right fw-bold"></td>
                                              <td className="text-right fw-bold">
                                                Rs.
                                                {parseFloat(
                                                  _.get(ord, "option.price")
                                                ).toFixed(2)}
                                              </td>
                                            </tr>
                                          ) : (
                                            ""
                                          )}
                                          {_.get(ord, "addons") &&
                                            _.get(ord, "addons") != null &&
                                            _.get(ord, "addons").length > 0
                                            ? _.get(ord, "addons").map(
                                              (add, add_id) => {
                                                if (
                                                  add.is_available != false
                                                ) {
                                                  return (
                                                    <tr key={add_id}>
                                                      <td className="pl-2 fw-bold">
                                                        {add.name}
                                                      </td>
                                                      <td className="text-right fw-bold"></td>
                                                      <td className="text-right fw-bold"></td>
                                                      <td className="text-right fw-bold">
                                                        Rs.
                                                        {parseFloat(
                                                          add.price
                                                        ).toFixed(2)}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              }
                                            )
                                            : ""}
                                        </React.Fragment>
                                      );
                                    })}
                                  <tr>
                                    <td></td>
                                    <td className="text-right "></td>
                                    <td className="text-right fw-bold">Tax</td>
                                    <td className="text-right fw-bold">
                                      Rs.
                                      {parseFloat(
                                        _.get(gst, "value", 0)
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                  {!_.isEmpty(
                                    _.toString(
                                      _.get(order, "packing_charges", null)
                                    )
                                  ) ? (
                                    <tr>
                                      <td></td>
                                      <td className="text-right "></td>
                                      <td className="text-right fw-bold">
                                        Packing Charges
                                      </td>
                                      <td className="text-right fw-bold">
                                        {" "}
                                        Rs.
                                        {parseFloat(
                                          _.get(order, "packing_charges")
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  ) : null}
                                  {_.toNumber(
                                    _.get(order, "delivery_charges", null)
                                  ) > 0 ? (
                                    <tr>
                                      <td></td>
                                      <td className="text-right "></td>
                                      <td className="text-right fw-bold">
                                        Delivery Charges
                                      </td>
                                      <td className="text-right fw-bold">
                                        Rs.
                                        {parseFloat(
                                          _.get(order, "delivery_charges")
                                        ).toFixed(2)}
                                      </td>
                                    </tr>
                                  ) : null}
                                  <tr>
                                    <td></td>
                                    <td className="text-right "></td>
                                    <td className="text-right font-weight-bold">
                                      TOTAL
                                    </td>
                                    <td className="text-right font-weight-bold">
                                      Rs.{parseFloat(order.total).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {/* <p class="centered">Thanks for your purchase!</p> */}
                              <div>
                                <button
                                  id="btnPrint"
                                  className="btn btn-primary text-uppercase fw-bold hidePrint"
                                  onClick={() => this.printOrderModal()}
                                >
                                  Print
                                </button>
                              </div>
                            </Modal>
                            <div></div>
                          </div>
                        }
                      </>
                    )}
                  </div>
                ))
                : null;
            } else {
              return this.renderModelButtons(order);
            }
          })()}
        </div>
      </div>
    ) : null;
  };

  renderModelButtons = (order) => {
    let orderBtn = { class: "", text: "", status: true };

    if (_.isEqual(_.get(order, "status"), 0)) {
      orderBtn.class = "btn btn-waiting text-uppercase";
      orderBtn.text = "CANCELLED BY USER";
    } else if (_.isEqual(_.get(order, "status"), 5)) {
      orderBtn.class = "btn btn-waiting text-uppercase";
      orderBtn.text = "Waiting for payment";
    } else if (_.isEqual(_.get(order, "status"), 2)) {
      orderBtn.class = "btn btn-reorder text-uppercase mr-2";
      orderBtn.status =
        !this.getSpecificCoreOrderDetails(order, "selected_items_count") > 0 ||
        !_.isEmpty(this.props.form.getFieldError("order_time"));
      orderBtn.text = _.get(order, "sendForReorder", false)
        ? "Send for Reorder"
        : "Confirm order";
    } else if (_.isEqual(_.get(order, "status"), 7)) {
      orderBtn.class = "btn btn-waiting text-uppercase";
      orderBtn.text = "CANCELLED BY USER";
    }
    return (
      <div>
        <button
          className={_.get(orderBtn, "class")}
          disabled={_.get(orderBtn, "status")}
          onClick={() => this.OnTriggerConfirmOrderBtn(order)}
        >
          {_.get(orderBtn, "text")}
        </button>
        {_.isEqual(_.get(order, "status"), 2) ? (
          <button
            className="btn btn-deny text-uppercase mr-2"
            onClick={() => this.OnTriggerDenyOrderBtn(order)}
          >
            Deny
          </button>
        ) : null}
      </div>
    );
  };

  renderOrderStatusBtn = (order) => {
    let orderBtn = { class: "", text: "", status: null };
    if (_.isEqual(_.get(order, "order_status"), 0)) {
      orderBtn.class = "btn btn-preparing text-uppercase mb-3";
      orderBtn.text = "Preparing";
      // status 8 indicates that order is preparing
      orderBtn.status = 8;
    } else if (_.isEqual(_.get(order, "order_status"), 8)) {
      orderBtn.class = "btn btn-preparing text-uppercase mb-3";
      orderBtn.text = "Order Ready";
      // status 9 indicates that order is ready
      orderBtn.status = 9;
    }
    else if (_.isEqual(_.get(order, "order_status"), 9)) {
      orderBtn.class = "btn btn-preparing text-uppercase mb-3";
      orderBtn.text = "Order Picked Up";
      // status 9 indicates that order is ready
      orderBtn.status = 10;
    }
    if (!_.isEqual(_.get(orderBtn, "status"), null))
      return (
        <button
          className={_.get(orderBtn, "class")}
          onClick={() => {
            confirm({
              keyboard: false,
              maskClosable: false,
              title: `Do you want to change the order status to ${orderBtn.text}?`,
              okText: "Yes",
              cancelText: "No",
              cancelButtonProps: {
                disabled: _.get(
                  this,
                  "props.restaurant.orderStatusChangeInProgress",
                  false
                ),
              },
              onOk: () => {
                this.setState({ modal_spinner: true });
                this.hanldeStop();
                this.props.onChangeOrderStatusValue(
                  { id: _.get(order, "id") },
                  {
                    status: _.get(orderBtn, "status"),
                    status_glossary: `The order status had been changed to ${orderBtn.text}`,
                  },
                  (success, data) => {
                    if (success) {
                      this.props.onGetUserOrderDetails(
                        {
                          id: _.get(data, "details.id"),
                          expand: "user,details,items,discounts,transactions",
                        },
                        () => {
                          this.setState({ modal_spinner: false });
                        }
                      );
                    } else {
                      this.setState({ modal_spinner: false });
                    }
                  }
                );
              },
            });
          }}
        >
          {_.get(orderBtn, "text")}
        </button>
      );
    else return null;
  };

  setFormItemValue = (order_details) => {
    let form_data = {
      date: !_.isEmpty(_.get(order_details, "order_date", null))
        ? moment(_.get(order_details, "order_date"))
        : moment(),
      order_time: moment(_.get(order_details, "order_time"), "HH:mm"),
      restaurant_comments: _.toString(
        _.get(order_details, "restaurant_comments", null)
      ),
    };

    if (
      _.has(order_details, "delivery_type_1") &&
      _.isEqual(_.get(order_details, "type"), 2)
    ) {
      form_data.delivery_type = _.get(order_details, "delivery_type_1", 0);
    }
    this.props.form.setFieldsValue(form_data);
  };

  isFormModified = () => {
    let modifiedUserOrderDetails = _.isEmpty(
      localStorage.getItem("modifiedUserOrderDetails")
    )
      ? []
      : JSON.parse(localStorage.getItem("modifiedUserOrderDetails"));
    const orderDetailsIndex = _.findIndex(modifiedUserOrderDetails, [
      "id",
      _.get(this, "props.userDetails.details.id"),
    ]);
    return orderDetailsIndex > -1;
  };
  OnTriggerDenyOrderBtn = (order) => {
    this.setState((previousState) => ({
      deny_modal_data: {
        ..._.get(previousState, "deny_modal_data", {}),
        status_glossary: "",
      },
    }));
    confirm({
      keyboard: false,
      maskClosable: false,
      title: "Are you sure you want to Deny this order?",
      content: (
        <TextArea
          rows={2}
          placeholder="Please mention the reason for denying the order"
          onChange={(event) => {
            const value = _.get(event, "target.value", "");
            this.setState((previousState) => ({
              deny_modal_data: {
                ..._.get(previousState, "deny_modal_data", {}),
                confirm: !_.isEmpty(value),
                status_glossary: _.toString(value),
              },
            }));
          }}
        />
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      cancelButtonProps: {
        disabled: _.get(
          this,
          "props.restaurant.isOrderChangeStatusProgress",
          false
        ),
      },
      onOk: () => {
        this.setState({ modal_spinner: true });
        this.props.onChangeOrderStatus(
          { id: _.get(order, "id") },
          {
            status: 0,
            status_glossary: !_.isEmpty(
              _.get(this, "state.deny_modal_data.status_glossary")
            )
              ? _.get(this, "state.deny_modal_data.status_glossary")
              : _.get(
                this,
                "state.deny_modal_data.status_glossary_not_provided_message"
              ),
          },
          (success, data) => {
            this.setState({ modal_spinner: false });

            if (success) {
              this.removeOrderDetailsFromLocalStorage(order);
              this.setOrderModal(false);
            }
          }
        );
      },
    });
  };

  OnTriggerConfirmOrderBtn = (order) => {
    let bindDeliveryType = false;
    if (_.isEqual(_.get(order, "type"), 2)) {
      const formProperties = _.keys(this.props.form.getFieldsValue());
      if (_.includes(formProperties, "delivery_type")) {
        this.props.form.validateFields(["delivery_type"]);
        if (!_.isEmpty(this.props.form.getFieldError("delivery_type"))) {
          return;
        }
        bindDeliveryType = true;
      }
    }
    confirm({
      keyboard: false,
      maskClosable: true,
      title: "Do you want to accept this order?",
      okText: "Yes",
      cancelText: "Back",
      onOk: () => {
        const confirmOrdersData = {
          order_time: moment(
            this.props.form.getFieldValue("order_time")
          ).format("HH:mm"),
          restaurant_comments: this.props.form.getFieldValue(
            "restaurant_comments"
          ),
        };
        if (bindDeliveryType) {
          confirmOrdersData.delivery_type =
            this.props.form.getFieldValue("delivery_type");
        }
        this.setState({ modal_spinner: true });
        this.props.onConfirmOrders(
          { id: _.get(order, "id") },
          this.onChangeOrderDetails({}, confirmOrdersData, true),
          (success, data) => {
            if (success) {
              this.removeOrderDetailsFromLocalStorage(order);
              this.props.onGetUserOrderDetails(
                {
                  id: _.get(data, "details.id"),
                  expand: "user,details,items,discounts,transactions",
                },
                () => {
                  this.setState({ modal_spinner: false });
                  setTimeout(() => {
                    this.setOrderModal(false);
                  }, 2000);
                }
              );
            } else {
              this.setState({ modal_spinner: false });
            }
          }
        );
      },
    });
  };

  /*Windows print*/
  printOrderModal = () => {
    window.print();
  };

  removeOrderDetailsFromLocalStorage = (order) => {
    let modifiedUserOrderDetails = _.isEmpty(
      localStorage.getItem("modifiedUserOrderDetails")
    )
      ? []
      : JSON.parse(localStorage.getItem("modifiedUserOrderDetails"));
    const orderDetailsIndex = _.findIndex(modifiedUserOrderDetails, [
      "id",
      _.get(order, "id"),
    ]);
    if (orderDetailsIndex > -1) {
      modifiedUserOrderDetails.splice(orderDetailsIndex, 1);
      localStorage.setItem(
        "modifiedUserOrderDetails",
        JSON.stringify(modifiedUserOrderDetails)
      );
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showKOTModal = () => {
    this.setState({
      kotModalVisible: true,
    });
  };

  kotHandleOk = () => {
    this.setState({
      kotModalVisible: false,
    });
  };

  kotHandleCancel = () => {
    this.setState({
      kotModalVisible: false,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  printThermalModal = (order) => {
    const $btnPrint = document.querySelector("#btnPrint");
    $btnPrint.addEventListener("click", () => {
      window.print();
    });
  };
  render() {
    const order = _.get(
      this,
      "state.current_selected_customer_order_details",
      {}
    );
    const antIcon = <Icon type="loading" style={{ fontSize: 38 }} spin />;
    return (
      <Modal
        className="order-details-container"
        title="Order Details"
        width="75%"
        maskClosable={false}
        centered
        visible={this.props.orderModal}
        onOk={() => this.setOrderModal(false)}
        onCancel={() => this.setOrderModal(false)}
        footer={null}
      >
        <Spin
          indicator={antIcon}
          spinning={
            _.get(
              this,
              "props.restaurant.isGetUserOrderDetailProgress",
              false
            ) ||
            _.get(this, "props.restaurant.computeConfirmInProgress", false) ||
            _.get(this, "state.modal_spinner")
          }
        >
          <div className="row" id="print_kot">
            {this.orderTopConatinerDetailsSet(order)}
            {this.orderListContainerSet(order)}
            <div className="col-lg-5">
              {this.getSpecificCoreOrderDetails(order, "selected_items_count") >
                0 ? (
                <React.Fragment>
                  {this.orderCostContainerSet(order)}
                  <div className="border-divided"></div>
                </React.Fragment>
              ) : null}
              {this.rightBottomContainerSet(order)}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    restaurant: state.restaurant,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onGetOrders: (payload) => dispatch({ type: GET_ORDERS, payload }),
  onConfirmOrders: (queryParams, bodyParams, callback) =>
    dispatch({
      type: CONFIRM_ORDERS,
      payload: agent.Retaurant.confirmOrders(bodyParams, queryParams),
      callback: callback,
    }),
  onChangeOrderStatus: (queryParams, bodyParams, callback) =>
    dispatch({
      type: CHANGE_ORDER_STATUS,
      payload: agent.Retaurant.changeOrderStatus(bodyParams, queryParams),
      callback: callback,
    }),
  onChangeOrderStatusValue: (queryParams, bodyParams, callback) =>
    dispatch({
      type: CHANGE_ORDER_STATUS_VALUE,
      payload: agent.Retaurant.changeOrderStatusValue(bodyParams, queryParams),
      callback: callback,
    }),
  onGetUserOrderDetails: (queryParams, callback = () => { }) =>
    dispatch({
      type: GET_USER_ORDER_DETAILS,
      payload: agent.Retaurant.view(queryParams),
      callback: callback,
    }),
  onComputeConfirmOrder: (queryParams, bodyParams, callback) =>
    dispatch({
      type: COMPUTE_CONFIRM,
      payload: agent.Retaurant.computeConfirm(bodyParams, queryParams),
      callback: callback,
    }),
});
const WrappedNormalForm = Form.create({ name: "order_model_form" })(OrderModel);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalForm);
