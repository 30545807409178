import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  REGISTER,
  HOME_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  IS_LOGGEDIN,
  SET_UTILITIES,
  SET_ORDERS,
  GET_NOTIFY_DATA,
  SET_VISITS
} from '../constants/actionTypes';

const defaultState = {
  appName: 'Hopsticks',
  token: null,
  viewChangeCounter: 0,
  isLoggedIn: false,
  utilitiesData: [],
  ordersData: [],
  notifyData: {},
  visitsData: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload : null
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      return { ...state, redirectTo: '/login', isLoggedIn: false, currentUser: null };
    case IS_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.error ? null : action.payload.isLoggedIn,
        currentUser: action.error ? null : action.payload,
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : action.payload.isLoggedIn ? '/dashboard' : '/login',
        isLoggedIn: action.error ? false : action.payload.isLoggedIn,
        currentUser: action.error ? null : action.payload
      };
    case SET_UTILITIES:
      return {
        ...state,
        utilitiesData: action.error ? null : action.utilitiesData
      };
    case SET_ORDERS:
      return {
        ...state,
        ordersData: action.error ? null : action.ordersData
      };
    case SET_VISITS:
      return {
        ...state,
        visitsData: action.error ? null : action.visitsData
      };
    case GET_NOTIFY_DATA:
      return {
        ...state,
        notifyData: action.error ? null : action.notifyData
      };
    case HOME_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};
