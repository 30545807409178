import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import queryString from 'query-string';

import agent from "../../agent";
import "./style.css";
import {
    GET_ALL_MENUS
} from "../../constants/actionTypes";
import MenusManagement from './menus';
import AddonsManagement from './addons';
import {history} from '../../config/storeConfig';
const { TabPane } = Tabs;

class MenusAndAddonsManagement extends React.Component {
    state = {
        tabs: {
          tab_panes: {
            menus: { name: 'Menu Item', key: '1' },
            addons: { name: 'Addons', key: '2' }
          },
          activeKey: '-'
        },
        pastQueryParams: {}
    };
    componentDidMount() {
        this.setTabActiveKey(_.get(queryString.parse(_.get(this, 'props.location.search')), 'tab'));
    }
    componentDidUpdate() {
        this.changeTabPane();
    }
    changeTabPane = () => {
        const pastQueryParams = _.pick(_.get(this, 'state.pastQueryParams'), ['tab']);
        const currentQueryParams = _.pick(queryString.parse(_.get(this, 'props.location.search')), ['tab']);
        if (!_.isEqual(pastQueryParams, currentQueryParams)) {
          this.setState({ pastQueryParams: currentQueryParams });
          this.setTabActiveKey(_.get(currentQueryParams, 'tab'));
        }
    }
    setTabActiveKey = (value) => {
        const tab_keys = _.map(_.values(_.get(this, 'state.tabs.tab_panes')), 'key');
        if (!_.isEmpty(value) && _.indexOf(tab_keys, value) > -1) {
          this.setState(previousState => ({ tabs: _.merge(previousState.tabs, { activeKey: value }) }));
          history.push({
            pathname: _.get(this, 'props.location.pathname'),
            search: queryString.stringify(
              _.merge(queryString.parse(_.get(this, 'props.location.search')),
                { tab: value }
              ))
          });
        } else {
          this.setState(previousState => ({ tabs: _.merge(previousState.tabs, { activeKey: _.get(this, 'state.tabs.tab_panes.menus.key') }) }));
        }
      }
    render(){
        const tabPanes = _.get(this, 'state.tabs.tab_panes');
        return (
            <div className="menu-addons-management-page-container">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="section-title">Manage Menu and Addons</div>
                        </div>
                        <div className="col-12">
                            <div className="menu-addons-management-tab-container">
                                <Tabs
                                    onTabClick={
                                        key => {
                                          this.setTabActiveKey(key);
                                        }}
                                    activeKey={_.get(this, 'state.tabs.activeKey')}
                                >
                                    <TabPane tab={_.get(tabPanes, 'menus.name')} key={_.get(tabPanes, 'menus.key')}>
                                        <MenusManagement {...this.props}/>
                                    </TabPane>
                                    <TabPane tab={_.get(tabPanes, 'addons.name')} key={_.get(tabPanes, 'addons.key')}>
                                        <AddonsManagement {...this.props}/>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({restaurant: _.get(state, 'restaurant', {})});

const mapDispatchToProps = dispatch => ({
    getRestaurentMenus: (queryParams, callback) => dispatch({ type: GET_ALL_MENUS, payload: agent.Retaurant.menus(queryParams), callback: callback })
});

export default connect(mapStateToProps, mapDispatchToProps)(MenusAndAddonsManagement);