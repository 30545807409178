import React from 'react'
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

import Login from '../containers/Login';
import Dashboard from '../containers/Dashboard';
import Orders from '../containers/Orders/orders';
import Page404 from '../containers/404';
import Logout from '../containers/Logout';

import * as routeConfig from '../config/routeConfig';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import DefaultRoutes from './DefaultRoutes';
import Dishes from '../containers/Dishes';
import CRM from '../containers/CRM';
import OrdersList from '../containers/Orders/orders-list';
import NoRestaurants from '../containers/NoRestaurant';
import orderModel from '../containers/order-model/orderModel';
import zeroHourDeal from '../containers/zero-hour-deals/zero-hour-deals';
import zeroHourForm from '../containers/zero-hour-deals/zero-hours-deals-form';
import MenusAndAddonsManagement from '../containers/menu-and-addons-management/index';

import Transactions from '../containers/Hoppoints/transactions';

const isAuth = localStorage.getItem('userLoggedInStatus') !== '' ? JSON.parse(localStorage.getItem('userLoggedInStatus')) : false;

const routes = (
    <div>
        <Switch>
            <PublicRoutes exact path={routeConfig.globalRoutes.zeroHourForm} component={zeroHourForm} title="zeroHourForm"/>
            <PublicRoutes exact path={routeConfig.globalRoutes.zeroHourDeal} component={zeroHourDeal} title="zeroHourDeal"/>
            <PublicRoutes exact path={routeConfig.globalRoutes.orderModel} component={orderModel} title="orderModel"/>
            <PublicRoutes exact path={routeConfig.globalRoutes.login} component={Login} title="Login"/>
            <PrivateRoutes path={routeConfig.globalRoutes.dashboard} component={Dashboard}  title="Dashboard"/>
            <PrivateRoutes path={routeConfig.globalRoutes.dishes} component={Dishes} title="Dishes" />
            <PrivateRoutes path={routeConfig.globalRoutes.CRM} component={CRM}  title="CRM"/>
            <PrivateRoutes path={routeConfig.globalRoutes.orders} component={Orders} title="Orders" />
            <PrivateRoutes path={routeConfig.globalRoutes.MenusAndAddonsManagement} component={MenusAndAddonsManagement} title="MenusAndAddonsManagement" />
            <PrivateRoutes path={routeConfig.globalRoutes.ordersList} component={OrdersList} title="OrdersList" />
            <PrivateRoutes path={routeConfig.globalRoutes.transactions} component={Transactions} title="Transactions" />
            <Route exact path="/" render={() => <Redirect to={isAuth ? '/dashboard' : '/login'} />} />
            <DefaultRoutes path={routeConfig.globalRoutes.logout} component={Logout} />
            <DefaultRoutes path={routeConfig.globalRoutes.noRestaurants} component={NoRestaurants} />
            <DefaultRoutes component={Page404} />
        </Switch>
    </div>
)


export default routes
