import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from "connected-react-router";

import { store, history} from './config/storeConfig';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import './config';

import './resources/css/index.css';
import './resources/fonts/font-awesome/css/font-awesome.css';
import './resources/fonts/font-icomoon/style.css';
import './../node_modules/antd/dist/antd.css';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './resources/css/App.css';
import './../node_modules/jquery/dist/jquery.min.js';
import './../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

ReactDOM.render((
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>

), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();