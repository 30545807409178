import React from "react";
import { connect } from "react-redux";
import "./hourDeal.css";
import { Select, Input, Modal, Button, notification, Table, Switch, Collapse } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import agent from "../../agent";
import globalVariable from "../../config/generalConfig";
class ZeroHourDeal extends React.Component {
    render() {
        return (
            <div className="hour-deal-container">
            <div className="container">
              <div className="table-responsive">
                <table className="table table-bordered tabl table-striped">
  <thead>
    <tr className="text-center">
      <th scope="col">
        Zero Hour Deals <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </th>
      <th scope="col">Price</th>
      <th scope="col">Discount %</th>
      <th scope="col">1+1</th>
      <th scope="col">Offer Price</th>
      <th scope="col">Offer Start Time</th>
      <th scope="col">Offer End Time</th>
      <th scope="col">Available Quantity</th>
      <th scope="col" width="120">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th>Burgers</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr className="row-opacity">
      <td>Chicken Burger</td>
      <td className="text-right">200</td>
      <td className="text-right">40</td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">120</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right">10</td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      disabled="false"
    />
      </td>
    </tr>
    <tr>
      <td>Cheese Burger</td>
      <td className="text-right">150</td>
      <td className="text-right"></td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">60</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right"></td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <td>Veg Burger</td>
      <td className="text-right">100</td>
      <td className="text-right">40</td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">60</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right">8</td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <th colspan="9"></th>
    </tr>
    <tr>
      <th>Shawarma</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Chicken Shawarma - Roll</td>
      <td className="text-right">200</td>
      <td className="text-right">40</td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">120</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right">10</td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <td>Chicken Shawarma - Plate</td>
      <td className="text-right">150</td>
      <td className="text-right"></td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">60</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right"></td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <td>Grilled Chicken Shawarma</td>
      <td className="text-right">100</td>
      <td className="text-right">40</td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">60</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right">8</td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <td>Cheese Chicken Shawarma</td>
      <td className="text-right">100</td>
      <td className="text-right">40</td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">60</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right">8</td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <th colspan="9"></th>
    </tr>
    <tr>
      <th>Grill</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Full Grilled Chicken</td>
      <td className="text-right">300</td>
      <td className="text-right">40</td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">120</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right">10</td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <td>Half Grilled Chicken</td>
      <td className="text-right">150</td>
      <td className="text-right"></td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">60</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right"></td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>

    <tr>
      <th colspan="9"></th>
    </tr>
    <tr>
      <th>Biriyani</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>Mutton Biriyani</td>
      <td className="text-right">200</td>
      <td className="text-right">50</td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">100</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right">10</td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    <tr>
      <td>Chicken Biriyani</td>
      <td className="text-right">150</td>
      <td className="text-right"></td>
      <td className="text-right">
      <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
      disabled="false"
    />
      </td>
      <td className="text-right">60</td>
      <td className="text-right">10:00 PM</td>
      <td className="text-right">11:00 PM</td>
      <td className="text-right"></td>
      <td className="text-right">
        <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked
    />
      </td>
    </tr>
    
  </tbody>
</table>
              </div>
            </div>
          </div>
        );
    }
}
export default ZeroHourDeal