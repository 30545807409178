import React, { Fragment } from 'react';
import { Switch, Form, message, DatePicker } from 'antd';
import _ from 'lodash';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import './style.css';
import agent from "../../agent";
import moment from 'moment';
import {
    RESTAURANT_VIEW, RESTAURANT_UPDATE
} from "../../constants/actionTypes";
import { connect } from 'react-redux';
const { RangePicker } = DatePicker;
class RestaurantSettings extends React.Component{

    state={
        switchPropsDefault: {
            checkedChildren:<CheckOutlined />,
            unCheckedChildren:<CloseOutlined />
        },
        formItem: {
            dining: {property_name: 'dining_enabled', atext: "Dining", props: {disabled:false, loading: false}},
            delivery: {property_name: 'delivery_enabled', atext: "Delivery", props: {disabled:false, loading: false}},
            takeaway: {property_name: 'takeaway_enabled', atext: "Takeaway", props: {disabled:false, loading: false}},
            restaurantAvailability: {property_name: 'is_open', atext: "Restaurant Availability", props: {disabled:false, loading: false}}
        },
        restaurant_details: {},
        isDatePickerShow: false,
        closing_date: {}
    };
    componentDidMount(){
        this.fetchRestaurantDetails();
    }
    componentDidUpdate(prevProps, prevState){  
        if (prevState.isDatePickerShow !== this.state.isDatePickerShow) {
            if(this.state.isDatePickerShow){
                this.props.form.setFieldsValue({is_open: true});
            }
        }
        const restaurantView=_.get(this, 'props.restaurant.restaurantView.details', {});
        const restaurantTimingsView =_.get(this, 'props.restaurant.restaurantView.details.timings', {});
        const prevRestaurantView = _.get(prevProps, 'restaurant.restaurantView.details', {});
        if(!_.isEmpty(this.props.form.getFieldsValue())&&!_.isEmpty(restaurantView)&&!_.isEqual(prevRestaurantView, restaurantView)){
            this.setState({restaurant_details: restaurantView});
            this.setFormFields(restaurantView,restaurantTimingsView);
        }
    }
    fetchRestaurantDetails = () => {
        this.updateFormItemProps({loading: true});
        this.props.getRestaurantDetails({expand : 'timings'}, (success, data)=>{
        this.updateFormItemProps({loading: false});
        });
    }
    updateRestaurantDetails = (property_name, property_value) => {
        if(property_name == 'is_open' && !property_value){
            this.setState({ 
                isDatePickerShow : true
             });
        }else{
            this.setState({ isDatePickerShow : false, closing_date : {}});
            const restaurant_details = {..._.get(this, 'state.restaurant_details', {})};
            restaurant_details.location = `${_.get(restaurant_details, 'location.x', null)},${_.get(restaurant_details, 'location.y', null)}`;
            restaurant_details.closing_start_date = null;
            restaurant_details.closing_end_date = null;
            this.updateFormItemProps({loading: true}, property_name);
            this.updateStateRestaurantDetails(property_name, property_value);
            this.props.updateRestaurantDetails({}, {...restaurant_details, [property_name]: property_value}, (success, data)=>{
                if(success){
                    const formItemProperty = _.find(_.get(this, 'state.formItem', {}), {property_name});
                    message.success(`${_.get(formItemProperty, 'atext', '')} option turned ${property_value? 'ON': 'OFF'} successfully!`);
                }
            this.updateFormItemProps({loading: false}, property_name);
            });
        }
        // this.dateRangePicker(property_name, property_value);
    }

    // updateRestaurantDetail = (property_name, property_value) => {
    //     const restaurant_details = {..._.get(this, 'state.restaurant_details', {})};
    //     restaurant_details.location = `${_.get(restaurant_details, 'location.x', null)},${_.get(restaurant_details, 'location.y', null)}`;
    //     this.updateFormItemProps({loading: true}, property_name);
    //     this.updateStateRestaurantDetails(property_name, property_value);
    //     this.props.updateRestaurantDetails({}, {...restaurant_details, [property_name]: property_value}, (success, data)=>{
    //         if(success){
    //             const formItemProperty = _.find(_.get(this, 'state.formItem', {}), {property_name});
    //             message.success(`${_.get(formItemProperty, 'atext', '')} option turned ${property_value? 'ON': 'OFF'} successfully!`);
    //         }
    //     this.updateFormItemProps({loading: false}, property_name);
    //     });
    // }
    
    setFormFields = (restaurant_details,restaurantTimingsView) => {
        const propertiesName = _.map(_.get(this, 'state.formItem', {}), 'property_name');
        const FormProperties = _.pick(restaurant_details, propertiesName);
        if(FormProperties && !FormProperties.is_open){
            const inTime = (startTime, endTime, date) => {
                function toMins(v) {
                  v = v.split(':');
                  return v[0]*60 + +v[1];
                }
                var now = new Date();
                startTime = new Date(+now).setHours(0, toMins(startTime), 0, 0); 
                endTime = new Date(+now).setHours(0, toMins(endTime), 0, 0); 
              
                return now >= startTime && now <= endTime;
            }

            if(restaurantTimingsView && restaurantTimingsView.length > 0){
                FormProperties.is_open = false;
                var FormIsOpen = false;
                const current_date = new Date();
                _.forEach(restaurantTimingsView, function(value) {
                    if(value.day === current_date.getDay()){
                        if(inTime(value.start_hour+':'+value.start_minute,value.end_hour+':'+value.end_minute)){
                            FormProperties.is_open = true;
                            FormIsOpen = true;
                        }
                    }
                });
                if(new Date() >= new Date(restaurant_details.closing_start_date) && new Date() <= new Date(restaurant_details.closing_end_date)){
                    FormProperties.is_open = false;
                    FormIsOpen = false;
                }
                if(FormIsOpen){
                    const property_name = 'is_open';
                    restaurant_details.location = `${_.get(restaurant_details, 'location.x', null)},${_.get(restaurant_details, 'location.y', null)}`;
                    restaurant_details.closing_start_date = null;
                    restaurant_details.closing_end_date = null;
                    this.props.updateRestaurantDetails({}, {...restaurant_details, [property_name]: FormIsOpen}, (success, data)=>{
                        if(success){
                            const formItemProperty = _.find(_.get(this, 'state.formItem', {}), {property_name});
                            message.success(`${_.get(formItemProperty, 'atext', '')} option turned ${FormIsOpen? 'ON': 'OFF'} successfully!`);
                        }
                    this.updateFormItemProps({loading: false}, property_name);
                    });
                }
            }
        }
        if(FormProperties && FormProperties.is_open){
            if(restaurant_details.closing_start_date && restaurant_details.closing_end_date){
                if(new Date() >= new Date(restaurant_details.closing_start_date) && new Date() <= new Date(restaurant_details.closing_end_date)){
                    FormProperties.is_open = false;
                    FormIsOpen = false;
                    const property_name = 'is_open';
                    restaurant_details.location = `${_.get(restaurant_details, 'location.x', null)},${_.get(restaurant_details, 'location.y', null)}`;
                    this.props.updateRestaurantDetails({}, {...restaurant_details, [property_name]: FormIsOpen}, (success, data)=>{
                        if(success){
                            const formItemProperty = _.find(_.get(this, 'state.formItem', {}), {property_name});
                            message.success(`${_.get(formItemProperty, 'atext', '')} option turned ${FormIsOpen? 'ON': 'OFF'} successfully!`);
                        }
                    this.updateFormItemProps({loading: false}, property_name);
                    });
                }
            }
        }
        this.props.form.setFieldsValue(FormProperties);
    }
    updateStateRestaurantDetails = (property_name, property_value ) => {
        this.setState(prevState=>({restaurant_details: {..._.get(prevState, 'restaurant_details', {}), [property_name]: property_value}}));
    }
    updateFormItemProps = (properties, property_name=null) => {
        this.setState(previousState=>{
            const stateFormItem = _.get(previousState, 'formItem', {});
            _.forEach(stateFormItem, (item, key)=>{
                const modifiedProps = {...item, props: {..._.get(item, 'props', {}), ...properties}};
                if(!_.isEmpty(property_name)){
                    if(_.isEqual(property_name, _.get(item, 'property_name', null))){
                        stateFormItem[key] = modifiedProps;
                    }
                }else{
                    stateFormItem[key] = modifiedProps;
                }
                
            });
            return stateFormItem;
        });
    }

    getDateFunction = (dateString) => {
        this.setState({ closing_date : { start_date : dateString[0], end_date : dateString[1]}});
    }

    onCloseDateFunction = () => {
        let FormIsOpenOrClose = false;
        const property_name = 'is_open';
        const restaurantView=_.get(this, 'props.restaurant.restaurantView.details', {});
        restaurantView.location = `${_.get(restaurantView, 'location.x', null)},${_.get(restaurantView, 'location.y', null)}`;
        restaurantView.closing_start_date = this.state.closing_date?.start_date;
        restaurantView.closing_end_date = this.state.closing_date?.end_date;
        this.props.updateRestaurantDetails({}, {...restaurantView, [property_name]: FormIsOpenOrClose}, (success, data)=>{
            if(success){
                const formItemProperty = _.find(_.get(this, 'state.formItem', {}), {property_name});
                message.success(`${_.get(formItemProperty, 'atext', '')} option turned ${FormIsOpenOrClose? 'ON': 'OFF'} successfully!`);
            }
        this.updateFormItemProps({loading: false}, property_name);
        });
        this.props.form.setFieldsValue({is_open: false});
        setTimeout(() => {
            this.setState({ isDatePickerShow : false });
        }, 1000)
    }

    dateRangePicker = (property_name, property_value) => {
        return ( 
            <RangePicker showTime onChange={(value, dateString) => this.getDateFunction(dateString) } onOk={() => { this.onCloseDateFunction() } } ranges={{
                Today: [moment(), moment()],
              }}/>
        );
    }

    wrapByFormItem = (params) => {
        const { getFieldDecorator } = this.props.form;
        const FormItem = Form.Item;
        return (

                <FormItem className="restaurant-form-settings-form-item">
                    {getFieldDecorator(_.get(params, 'property_name', ''), {
                        valuePropName: 'checked'
                    })(
                        _.get(params, 'element', null)
                    )}
                </FormItem>

        );
    }
    diningBlock = () => {
        return (
            <div className="col-3 align-items-center d-flex">
                <div className="resturant-label">Dining</div>
                <div className="ml-3">
                    {this.wrapByFormItem({
                        property_name: _.get(this, 'state.formItem.dining.property_name', ''),
                        element: <Switch {..._.get(this, 'state.switchPropsDefault', {})} {..._.get(this, 'state.formItem.dining.props', {})} onChange={
                            checked=> this.updateRestaurantDetails(_.get(this, 'state.formItem.dining.property_name', ''), checked)
                        }/>
                    })}
                </div>
            </div>
        );
    }
    deliveryBlock = () => {
        return (
            <div className="col-3 align-items-center d-flex">
                <div className="resturant-label">Delivery</div>
                <div className="ml-3">
                    {this.wrapByFormItem({
                        property_name: _.get(this, 'state.formItem.delivery.property_name', ''),
                        element: <Switch {..._.get(this, 'state.switchPropsDefault', {})} {..._.get(this, 'state.formItem.delivery.props', {})} onChange={
                            checked=> this.updateRestaurantDetails(_.get(this, 'state.formItem.delivery.property_name', ''), checked)
                        }/>
                    })}
                </div>
            </div>
        );
    }
    takeawayBlock = () => {
        return (
            <div className="col-3 align-items-center d-flex">
                <div className="resturant-label">Takeaway</div>
                <div className="ml-3">
                    {this.wrapByFormItem({
                        property_name: _.get(this, 'state.formItem.takeaway.property_name', ''),
                        element: <Switch {..._.get(this, 'state.switchPropsDefault', {})} {..._.get(this, 'state.formItem.takeaway.props', {})} onChange={
                            checked=> this.updateRestaurantDetails(_.get(this, 'state.formItem.takeaway.property_name', ''), checked)
                        }/>
                    })}
                </div>
            </div>
        );
    }
    isOpenedBlock = () => {
        return (
            <div className="col-3 align-items-center d-flex mr-4">
                <div className="resturant-label">Restaurant Availability</div>
                <div className="ml-3">
                    {this.wrapByFormItem({
                        property_name: _.get(this, 'state.formItem.restaurantAvailability.property_name', ''),
                        element: <Switch {..._.get(this, 'state.switchPropsDefault', {})} {..._.get(this, 'state.formItem.restaurantAvailability.props', {})}  onChange={
                            checked=> this.updateRestaurantDetails(_.get(this, 'state.formItem.restaurantAvailability.property_name', ''), checked)
                        }/>
                    })}
                </div>
            </div>
        );
    }
    mainComponent = () => {
        return (
            <div className="resturant-control row pb-3 pt-3">
                <div className="col-6">
                    <div className="row align-items-center">
                        {/* Defining dining block */}
                        {this.diningBlock()}
                        {/* Defining delivery block */}
                        {this.deliveryBlock()}
                        {/* Defining takeaway block */}
                        {this.takeawayBlock()}
                    </div>
                </div>
                <div className="col-6">
                    <div className="row align-items-center justify-content-end pr-3">
                        {/* Defining isOpened block */}
                        {this.state.isDatePickerShow && this.dateRangePicker()}
                        {this.isOpenedBlock()}
                    </div>
                </div>
          </div>
        );
    }
    render(){
        const restaurantView = _.get(this, 'props.restaurant.restaurantView.details', {});
        return !_.isEmpty(restaurantView)?this.mainComponent(): null;
    }
}
const mapStateToProps = state => ({restaurant: _.get(state, 'restaurant', {})});

const mapDispatchToProps = dispatch => ({
    getRestaurantDetails: (queryParams, callback) => dispatch({ type: RESTAURANT_VIEW, payload: agent.Retaurant.detailView(queryParams), callback: callback }),
    updateRestaurantDetails: (queryParams, bodyParams, callback) => dispatch({ type: RESTAURANT_UPDATE, payload: agent.Retaurant.update(queryParams, bodyParams), callback: callback })
});
const WrappedNormalForm = Form.create({ name: 'restaurant_form_settings' })(RestaurantSettings);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalForm);