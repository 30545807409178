import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PageHeader from "../components/Header/header";
import PageSidebar from "../components/Sidebar/sidebar";
import { APP_LOAD, REDIRECT } from "../constants/actionTypes";
import renderMergedProps from "./renderMergedProps";
import Page from "../pages";
/**
 * Ensure the user is authenticated before they can view this route
 *
 * @class ProtectedRoute
 * @extends {React.Component}
 */

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    isLoggedIn: state.common.isLoggedIn,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
});

class PrivateRoutes extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.onLoad();
  }

  render() {
    const { isLoggedIn, component: Component, ...rest } = this.props;
    const isAuth =
      localStorage.getItem("userLoggedInStatus") !== ""
        ? JSON.parse(localStorage.getItem("userLoggedInStatus"))
        : false;

    return (
      <Page
        {...rest}
        render={routeProps => {
          return isAuth || isLoggedIn ? (
            <div className="page-container">
              <div className="page-sidebar">
                <PageSidebar />
              </div>
              <div className={(()=>{
                if(this.props.location.pathname ==='/orders'){
                  return 'page-right-content orders-page';
                }else {
                  return 'page-right-content';
                }
              })()}>
                <PageHeader {...this.props} />
                {renderMergedProps(Component, routeProps, { ...rest })}
              </div>
            </div>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: routeProps.location },
              }}
            />
          );
        }}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoutes);
