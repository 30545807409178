import agent from "../agent/";
import React from "react";
import { connect } from "react-redux";
import {
  APP_LOAD,
  REDIRECT,
  IS_LOGGEDIN,
  HOPPOINTS_VIEW_SUMMARY_LOADED,
  RESTAURANT_LOADED,
  VENDOR_PROFILE_LOADED,
  CURRENT_RESTAURANT
} from "../constants/actionTypes";
import { store } from "../config/storeConfig";
import { push } from "react-router-redux";
import routes from "../routes";
import globalVariable from "../config/generalConfig";
import AppReUsableComponents from "./re-usable";
import { notification } from 'antd';
import { connectSocket } from "./re-usable/socket-connection";


const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
    isLoggedIn: state.common.isLoggedIn,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    currentRestaurant:state.profile.currentRestaurant
  };
};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
  current: () => dispatch({ type: IS_LOGGEDIN, payload: agent.Auth.current() }),
  onProfile: payload => dispatch({ type: VENDOR_PROFILE_LOADED, payload }),
  onRestaurants: payload => dispatch({ type: RESTAURANT_LOADED, payload }),
  onHoppointsViewSummary: payload =>
    dispatch({ type: HOPPOINTS_VIEW_SUMMARY_LOADED, payload }),
  onCurrentRestaurant: payload =>
    dispatch({ type: CURRENT_RESTAURANT, payload })
});

class App extends React.Component {

  componentDidMount(){
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }
  
    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      // If it's okay let's create a notification
        console.log("granted");
    }
  
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          console.log("granted");
        }
      });
    }

    else if (Notification.permission === "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        notification.error({
            message: 'Notification',
            description: 'Please enable Notification in the browser to get new order notifications.',
            duration: 0,
        });
        if (permission === "granted") {
          console.log("granted");
        }
      });
    }
    
    connectSocket({}, (data)=>{
      console.log('connected app details: in socket', data, global.config.socket.connected);
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  componentWillMount() {
    this.props.current();
    this.props.onLoad();
  }

  componentDidUpdate(prevProps) {

   
    const hopstcikProfileInfo = window.localStorage.getItem(
      "userLoggedInStatus"
    );
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      this.props.onProfile(
        this.props.isLoggedIn || hopstcikProfileInfo
          ? agent.Auth.profile()
          : null
      );
    }
    if (
      prevProps.vendorProfile !== this.props.vendorProfile &&
      this.props.vendorProfile &&
      this.props.vendorProfile.details
    ) {
      const requestParams = {};
      requestParams.expand = "city";
      this.props.onRestaurants(
        this.props.isLoggedIn || hopstcikProfileInfo
          ? agent.Retaurant.all(requestParams)
          : null
      );
    }
    if (
      prevProps.vendorRestaurant !== this.props.vendorRestaurant &&
      this.props.vendorRestaurant &&
      this.props.vendorRestaurant.items &&  this.props.vendorRestaurant.items.length === 0
    ) {
      store.dispatch(push('/no-restaurants'));
      this.props.onRedirect();
    }
    if (
      prevProps.vendorRestaurant !== this.props.vendorRestaurant &&
      this.props.vendorRestaurant &&
      this.props.vendorRestaurant.items
    ) {
      const requestParams = {};
      requestParams.id_type = 1; // 1 Means restaurant
      requestParams.expand = "restaurant";
      this.props.onHoppointsViewSummary(
        this.props.isLoggedIn || hopstcikProfileInfo
          ? agent.Hoppoints.viewSummary(requestParams)
          : null
      );
      this.props.onCurrentRestaurant(
        this.props.isLoggedIn || hopstcikProfileInfo
          ? agent.Retaurant.details()
          : null
      );
      
    }
  }

  render() {
    if (Object.keys(this.props.currentRestaurant).length!==0) {
      window.localStorage.setItem(
        "restaurantId",
        this.props.currentRestaurant.details.id
      );
    }
    if (this.props.appLoaded) {
      return (<div>
        {routes}
      </div>);
    }
    return <div>Loading...</div>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
