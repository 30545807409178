import React from "react";
import { connect } from "react-redux";
import { Table, Switch, Input, message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import "./header.css";
import { capitalizeWord } from "../../utils/Helpers";
import HeaderLoader from "./header-loader";
import {
  RESTAURANT_UNLOADED,
  HOPPOINTS_VIEW_SUMMARY_UNLOADED,
  CURRENT_RESTAURANT
} from "../../constants/actionTypes";
import agent from "../../agent";
import RestaurantSettings from '../settings';
import { Link} from "react-router-dom";

const mapStateToProps = state => {
  return {
    ...state.home,
    appName: state.common.appName,
    token: state.common.token,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    inProgress: state.profile.inProgress,
    currentRestaurant: state.profile.currentRestaurant,
  };
};

const mapDispatchToProps = dispatch => ({
  onChangeRestaurant: requestParams =>
    dispatch({
      type: CURRENT_RESTAURANT,
      payload: agent.Retaurant.changeRestaurant(requestParams)
    }),
  onRestaurantUnload: () => dispatch({ type: RESTAURANT_UNLOADED }),
  onHoppointsUnload: () => dispatch({ type: HOPPOINTS_VIEW_SUMMARY_UNLOADED })
});

class Header extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.hoppointsDetails !== this.props.hoppointsDetails &&
      this.props.hoppointsDetails &&
      this.props.hoppointsDetails.details
    ) {
      this.props.onHoppointsUnload();
    }
  }

  onChangeRestaurant = restaurantInfo => {
    this.props.onChangeRestaurant(restaurantInfo);
  };

  render() {
    let restaurantImage = '';
    let currentRestaurant = this.props.currentRestaurant && this.props.currentRestaurant.details ? this.props.currentRestaurant.details : {};
    return (
      <div className="full-width">
        <div className="header-container">
          {this.props.vendorRestaurant &&
            this.props.vendorRestaurant.items &&
            this.props.vendorRestaurant.items.map((value, index) => {
              if(value.id === currentRestaurant.id) {
                if (value.photo) {
                  restaurantImage = `${this.props.vendorRestaurant &&
                    this.props.vendorRestaurant.meta &&
                    this.props.vendorRestaurant.meta.photo.path}/${this.props.vendorRestaurant &&
                    this.props.vendorRestaurant.meta &&
                    this.props.vendorRestaurant.meta.photo.folders.restaurant}/${this.props
                    .vendorRestaurant &&
                    this.props.vendorRestaurant.meta &&
                    this.props.vendorRestaurant.meta.photo.sizes.medium}/${
                    value.photo
                  }`;
                }
                return (
                  <React.Fragment key={index}>
                    <div
                      className="row"
                      // onClick={() => this.onChangeRestaurant(value)}
                    >
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className="row">
                          <div className="header-left">
                            <div className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                              <div className="restaurant-logo"
                              style={{
                                backgroundImage: "url(" + restaurantImage + ")"
                              }}>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-10 col-lg-10 col-xl-10">
                              <div className="restaurant-detail-content">
                                <div className="restaurant-name text-capitalize">
                                  {value.name ? capitalizeWord(value.name) : '-'}{" "}
                                  <button className="restaurant-edit-icon">
                                    <i className="fa fa-pencil-square-o" />
                                  </button>
                                </div>
                                <div className="restaurant-location">
                                  {value.address ? value.address : '-' +
                                    ", " +
                                    value.city.name ? capitalizeWord(value.city.name) : '' +
                                    " - " +
                                    value.zip_code ? value.zip_code : ''}
                                </div>
                                <div className="restaurant-contact">

                                  {value && value.contact_numbers && value.contact_numbers.length > 0 ? `Primary Contact: ${value.contact_numbers.toString()}` : ''}
                                  {value && value.manager_contact_numbers && value.manager_contact_numbers.length > 0 ?
                                    `• Manager Contact: ${value.manager_contact_numbers.toString()}`
                                     : ''}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <div className="header-right">
                          <div className="float-md-left float-lg-left float-xl-right">
                            <div className="count-set">
                              <span className="count-icon">
                              <img
                                className="img-fluid"
                                src={require("../../resources/images/hp-earned.png")}
                              />
                              </span>
                              <div className="count-type-set">
                                <span className="count-text">
                                  {this.props.hoppointsDetails &&
                                    this.props.hoppointsDetails.details &&
                                    this.props.hoppointsDetails.details
                                      .earned_points}
                                </span>
                                <span className="count-type-text">Earned</span>
                              </div>
                            </div>
                            <div className="count-set">
                              <span className="count-icon">
                              <img
                                className="img-fluid"
                                src={require("../../resources/images/hp-spent.png")}
                              />
                              </span>
                              <div className="count-type-set">
                                <span className="count-text">
                                  {this.props.hoppointsDetails &&
                                    this.props.hoppointsDetails.details &&
                                    this.props.hoppointsDetails.details
                                      .spent_points}
                                </span>
                                <span className="count-type-text">Spent</span>
                              </div>
                            </div>
                            <div className="count-set">
                              <span className="count-icon">
                              <img
                                className="img-fluid"
                                src={require("../../resources/images/hp-current.png")}
                              />
                              </span>
                              <div className="count-type-set">
                                <span className="count-text">
                                  {this.props.hoppointsDetails &&
                                    this.props.hoppointsDetails.details &&
                                    this.props.hoppointsDetails.details
                                      .current_points}
                                </span>
                                <span className="count-type-text">Balance</span>
                              </div>
                            </div>
                          </div>
                          <div className="float-md-left float-lg-left float-xl-right">

                                <button
                                  type="button"
                                  className="outline-secondary-btn-sm ripple-btn-secondary text-upper mt-1 m-lr-10"
                                >
                                <Link to={`/transactions`}>View Ledger</Link>
                                </button>
                                <button
                                type="button"
                                className="green-button-sm ripple-btn-green text-upper text-700 mt-1"
                              >
                                Buy More
                              </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  {/* Resturant Control Start */}
                      <RestaurantSettings {...this.props}/>
                  {/* Resturant Control End */}
                  </React.Fragment>
                );
              }
            })}
          {this.props.inProgress &&
            Object.keys(this.props.vendorRestaurant).length === 0 &&
            Object.keys(this.props.hoppointsDetails).length === 0 && (
              <HeaderLoader />
            )}
        </div>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
