import React, { Component } from "react";
import { connect } from "react-redux";

import ReactTooltip from 'react-tooltip';
import { Spin } from "antd";

import { capitalizeWord, formatDate } from "../../utils/Helpers";
import { MENUS_UNLOADED } from "../../constants/actionTypes";
import { Link} from "react-router-dom";

class Crmtable extends Component {


  onAddReceiverId = (receiverId) => {
    this.props.onAddReceiversForNotifiaction(receiverId);
  }

  render() {
    return (
      <table className="table table-bordered table-striped">
        <thead className="thead-light">
          <tr>
            <th scope="col">User Hash</th>
            <th scope="col">Ocassion</th>
            <th scope="col">Gender</th>
            <th scope="col">Age</th>
            <th scope="col">Last Visit</th>
            <th scope="col">Visit Count</th>
            <th scope="col">LTS</th>
          </tr>
        </thead>
        <tbody>
          {(this.props.inCRMProgress || this.props.currentRestaurantInProgress) &&
            <tr><td colSpan="7"><Spin className="crm-table-spinner" /></td></tr>}
          {(!this.props.currentRestaurantInProgress || !this.props.inCRMProgress) && this.props.crm &&
            this.props.crm.items && this.props.stateData &&
            this.props.stateData.length === 0 && (<tr><td colSpan="7">
              <div className="no-content-container">
                <div className="icon">
                  <i className="fa fa-coffee" />
                </div>
                <div className="text">No data</div>
              </div>
            </td></tr>)}
          {!this.props.inCRMProgress && this.props.stateData &&
            this.props.stateData.map((value, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="custom-control custom-checkbox" data-tip={!value.can_send_notification ? "User does not allow to send any more notifications today." : ""}>
                      <ReactTooltip />
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={'crmCheckBox' + index}
                        disabled={!value.can_send_notification}
                        onChange={() => this.onAddReceiverId(value.id)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={'crmCheckBox' + index}
                      >
                        {value.last_name ? capitalizeWord(value.first_name) + ' ' + capitalizeWord(value.last_name) : capitalizeWord(value.first_name)}
                      </label>
                    </div>
                  </td>
                  <td>
                    {(() => {
                      const getDay = (day, month) => {
                        let today = new Date();
                        const date = new Date(today.getFullYear(), month - 1, day);
                        if (today.getMonth() == month - 1 && today.getDate() > day) {
                          date.setFullYear(date.getFullYear() + 1);
                        }
                        const oneDay = 1000 * 60 * 60 * 24;
                        return Math.ceil((date.getTime() - today.getTime()) / (oneDay));
                      }
                      if (value.date_of_birth && !value.wedding_anniversary) {
                        return 'Date Of Birth' + ' ' + '(' + value.date_of_birth + ')';
                      } else if (value.wedding_anniversary && !value.date_of_birth) {
                        return 'Anniversary' + ' ' + '(' + value.wedding_anniversary + ')';
                      } else if (value.wedding_anniversary && value.date_of_birth) {
                        const birthDayMonth = formatDate(value.date_of_birth, 'MM');
                        const birthDayDate = formatDate(value.date_of_birth, 'DD');

                        const weddingDayMonth = formatDate(value.wedding_anniversary, 'MM');
                        const weddingDayDate = formatDate(value.wedding_anniversary, 'DD');

                        const dateOfBirth = getDay(birthDayDate, birthDayMonth)
                        const weddingAnniversary = getDay(weddingDayDate, weddingDayMonth);
                        if (dateOfBirth <= weddingAnniversary) {
                          return 'Date Of Birth' + ' ' + '(' + value.date_of_birth + ')';
                        } else {
                          return 'Anniversary' + ' ' + '(' + value.wedding_anniversary + ')';
                        }
                      }
                    })()}

                  </td>
                  <td>{value.gender}</td>
                  <td>
                    {value.age_range === 0
                      ? "<15"
                      : value.label === 1
                        ? "15 - 18"
                        : value.label === 2
                          ? "18 - 25"
                          : value.label === 3
                            ? "25 - 30"
                            : value.label === 4
                              ? "30 - 40"
                              : "<40"
                    }
                  </td>
                  <td>{value&& value.last_visit?(formatDate(value.last_visit, 'MM/DD/YYYY')):"-"}</td>
                  <td>{value.visits}</td>
                  <td><Link to={`/order-list?user=${value.id}`}>Rs. {value.revenue}</Link></td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    )
  }
}


const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    inCRMProgress: state.users.inCRMProgress,
    currentRestaurant: state.profile.currentRestaurant,
    crm: state.users.crm,
    notificationData: state.users.notificationData,
    inSendNotificationProgress: state.users.inSendNotificationProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onUnload: () => dispatch({ type: MENUS_UNLOADED })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Crmtable);
