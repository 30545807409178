import React, { Component, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { InputNumber } from 'antd';
import {Link} from "react-router-dom";
import ReactDOM from "react-dom";

import "./top-dishes.css";
import agent from "../../agent";
import {
  GET_TOP_MENUS,
  GET_ALL_MENUS,
  UPDATE_TOP_MENUS,
  MENUS_UNLOADED,
  UPDATE_TOP_MENU_SLOT
} from "../../constants/actionTypes";
import { TopDishLoader, AllDishLoader } from "./dish-loader";
import { notification, Button, Modal } from "antd";

class Dishes extends React.Component {
  constructor(props) {
    super(props);
    this.topDishRef = React.createRef()
  }

  state = {
    currentSortName: "asc",
    addedMenuItemsId: [],
    currentRestaurant: {},
    alreadyLoadedTopMenus: [],
    removeMenuItemsId: [],
    addModalVisible: false,
    removeModalVisible: false,
    topMenuSlotType: 1,
    menuSlotModalVisible: false,
    menuSlotNumber: null,
    topMenuLimit: null,
    loadedMenus: [],
    dishLimit: 10,
    dishPage: 1,
    isLoadMore: false
  };

  setAddModalVisible(addModalVisible) {
    this.setState({ addModalVisible });
  }

  setRemoveModalVisible(removeModalVisible) {
    this.setState({ removeModalVisible });
  }

  setMenuSlotModalVisible(menuSlotModalVisible) {
    this.setState({ menuSlotModalVisible });
    !menuSlotModalVisible && this.props.onUnload();
  }

  componentDidMount() {
    if (this.props.currentRestaurant && this.props.currentRestaurant.details) {
      let allDishRequestParams = { expand: "dish", sort: 'is_bestseller.desc', limit: this.state.dishLimit, page: this.state.dishPage };
      let topDishRequestParams = { is_top: 1, expand: "dish" };
      this.props.onGetAllDishes(agent.Retaurant.menus(allDishRequestParams));
      this.props.onGetTopDishes(agent.Retaurant.menus(topDishRequestParams));
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.currentRestaurant !== this.props.currentRestaurant &&
      this.props.currentRestaurant &&
      this.props.currentRestaurant.details
    ) {
      let allDishRequestParams = { expand: "dish", sort: 'is_bestseller.desc', limit: this.state.dishLimit, page: this.state.dishPage };
      let topDishRequestParams = { is_top: 1, expand: "dish", limit: this.props.currentRestaurant.details.max_top_menus };
      this.props.onGetAllDishes(agent.Retaurant.menus(allDishRequestParams));
      this.props.onGetTopDishes(agent.Retaurant.menus(topDishRequestParams));
      this.setState({
        currentRestaurant: this.props.currentRestaurant.details
      });
      this.setState({
        topMenuLimit: this.props.currentRestaurant.details.max_top_menus
      });
    }
    if (
      prevProps.menus !== this.props.menus &&
      this.props.menus &&
      this.props.menus.items && this.props.menus.items.length > 0
    ) {
      if(!this.state.isLoadMore) {
        await this.setState({
          loadedMenus: [],
        });
      }
      let newMenus = [];
      this.props.menus.items.map((value, index) => {
        newMenus.push(value);
      })
      await this.setState({
        loadedMenus: [...this.state.loadedMenus, ...newMenus ],
        isLoadMore: false,
        isSort: false
      });
    }
    if (
      prevProps.topMenus !== this.props.topMenus &&
      this.props.topMenus &&
      this.props.topMenus.items && this.props.topMenus.items.length > 0
    ) {
     await this.setState({
        alreadyLoadedTopMenus: []
      });
      let topMenuItemsId = [];
      this.props.topMenus.items.map((value, index) => {
        topMenuItemsId.push(value.id);
      })
      await this.setState({
        alreadyLoadedTopMenus: [...this.state.alreadyLoadedTopMenus, ...topMenuItemsId ],
        addedMenuItemsId: [],
        removeMenuItemsId: []
      });
    }
    if (
      prevProps.updateTopMenus !== this.props.updateTopMenus &&
      this.props.updateTopMenus && this.props.updateTopMenus.error
    ) {
      // console.log('error check', this.props.updateTopMenus);
      
      notification.error({ message: this.props.updateTopMenus.message });
      // window.scrollTo(0, 0)
    }
    if (
      prevProps.updateTopMenus !== this.props.updateTopMenus &&
      this.props.updateTopMenus && this.props.updateTopMenus.success
    ) {
      this.setAddModalVisible(false);
      this.setRemoveModalVisible(false);
      let topDishRequestParams = { is_top: 1, expand: "dish", limit: this.state.topMenuLimit };
      this.props.onGetTopDishes(agent.Retaurant.menus(topDishRequestParams));
      this.props.onUnload();
      window.scrollTo(0, 0)
    }
    if (
      prevProps.updateTopMenuSlots !== this.props.updateTopMenuSlots &&
      this.props.updateTopMenuSlots && this.props.updateTopMenuSlots.success
    ) {
      this.setMenuSlotModalVisible(false);
      let topDishRequestParams = { is_top: 1, expand: "dish", limit: this.state.topMenuLimit };
      this.props.onGetTopDishes(agent.Retaurant.menus(topDishRequestParams));
    }
  }

  onSortWithDishes = async fieldName => {
    await this.setState((prevState, props) => {
      return {
        currentSortName: prevState.currentSortName === "asc" ? "desc" : "asc",
        isLoadMore: false,
        isSort: true
      };
    });
    let allDishRequestParams = {
      expand: "dish",
      sort: fieldName + "." + this.state.currentSortName
    };
    this.props.onGetAllDishes(agent.Retaurant.menus(allDishRequestParams));
  };

  onAddItemToMenus = async (itemId) => {
    let topMenuItems = 0;
    if (
      this.props.topMenus &&
      this.props.topMenus.meta &&
      this.props.topMenus.meta.total
    ) {
      topMenuItems = this.props.topMenus.meta.total;
    }
    if(!this.state.addedMenuItemsId.includes(itemId)) {
      if (
        this.state.currentRestaurant.max_top_menus !==
        this.state.addedMenuItemsId.length + topMenuItems && itemId
      ) {
        if(!this.state.alreadyLoadedTopMenus.includes(itemId)) {
            var joinItemId = this.state.addedMenuItemsId.concat(itemId);
            await this.setState({ addedMenuItemsId: joinItemId });
        }else {
          notification.error({ message: "This menu already in top dishes." });
        }
      } else {
        notification.error({ message: "You already selected all menus" });
      }
    }else {
      var tempAddedMenuItemsId = [...this.state.addedMenuItemsId]; // make a separate copy of the array
      var itemIndex = tempAddedMenuItemsId.indexOf(itemId)
      if (itemIndex !== -1) {
        tempAddedMenuItemsId.splice(itemIndex, 1);
        await this.setState({addedMenuItemsId: tempAddedMenuItemsId});
      }
    }
  };

  onAddTopMenus = () => {
    let requestParams = {};
    requestParams.menus = [...this.state.addedMenuItemsId, ...this.state.alreadyLoadedTopMenus];
    this.props.onUpdateTopMenus(requestParams)
  }

  onConfirmToDelete = async (itemId) => {
    this.setRemoveModalVisible(true);
    let tempLoadedMenus = [...this.state.alreadyLoadedTopMenus];
    const itemIndex = tempLoadedMenus.indexOf(itemId);
    if (itemIndex !== -1) {
      tempLoadedMenus.splice(itemIndex, 1);
    }
    await this.setState({ removeMenuItemsId: tempLoadedMenus });
  }

  onRemoveTopMenus = () => {
    let requestParams = {};
    requestParams.menus = [...this.state.removeMenuItemsId];
    this.props.onUpdateTopMenus(requestParams)
  }

  onUpdateTopMenuSlots = () => {
    let requestParams = {};
    requestParams.no_of_slots = this.state.menuSlotNumber;
    requestParams.type = this.state.topMenuSlotType;
    this.props.onUpdateTopMenuSlots(requestParams);
  }

  onUpdateTopMenuSlotNumber = (value) => {
    this.setState({ menuSlotNumber: value });
  }

  onLoadMoreItemsToDishes = async () => {
    if(this.props.menus && this.props.menus.meta && this.props.menus.meta.total !== this.state.loadedMenus.length) {
      await this.setState({
        dishPage: this.state.dishPage + 1,
        isLoadMore: true,
        isSort: false
      })
      let allDishRequestParams = { expand: "dish", limit: this.state.dishLimit, page: this.state.dishPage };
      this.props.onGetAllDishes(agent.Retaurant.menus(allDishRequestParams));
    }
  }

  render() {
    let pendingMenus = 0;
    if (
      this.props.topMenus &&
      this.props.topMenus.meta &&
      this.props.topMenus.meta.total
    ) {
      pendingMenus =
        this.state.currentRestaurant.max_top_menus -
        this.props.topMenus.meta.total;
    }

    const allDishRef = React.createRef();

    const scrollToAllDishContainer = () =>
    allDishRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });

    return (
      <div className="top-dishes-container" ref={this.topDishRef}>
        {/*Top Dishes Start*/}
        <div className="section-title">Top Dishes</div>
        {this.props.topMenus &&
          this.props.topMenus.items &&
          this.props.topMenus.items.length == 0 &&
          !this.props.inTopDishProgress && (
            <div className="no-content-container">
              <div className="icon">
                <i className="fa fa-coffee" />
              </div>
              <div className="text">No top dishes available.</div>
            </div>
          )}
        {(this.props.inTopDishProgress ||
          this.props.currentRestaurantInProgress) && <TopDishLoader />}
        {!this.props.inTopDishProgress && (
          <div className="row-content">
            <div className="row">
              {/*Dishes*/}
              {this.props.topMenus &&
                this.props.topMenus.items &&
                this.props.topMenus.items.map((value, index) => {
                  let photoUrl = "";
                  if (value.photo) {
                    photoUrl = `${this.props.topMenus &&
                      this.props.topMenus.meta &&
                      this.props.topMenus.meta.photo.path}/${this.props
                      .topMenus &&
                      this.props.topMenus.meta &&
                      this.props.topMenus.meta.photo.folders.menu}/${this.props
                      .topMenus &&
                      this.props.topMenus.meta &&
                      this.props.topMenus.meta.photo.sizes.large}/${
                      value.photo
                    }`;
                  }
                  if (!value.photo && value.dish && value.dish.photo) {
                    photoUrl = `${this.props.topMenus &&
                      this.props.topMenus.meta &&
                      this.props.topMenus.meta.photo.path}/${this.props
                      .topMenus &&
                      this.props.topMenus.meta &&
                      this.props.topMenus.meta.photo.folders.dish}/${this.props
                      .topMenus &&
                      this.props.topMenus.meta &&
                      this.props.topMenus.meta.photo.sizes.large}/${
                      value.dish.photo
                    }`;
                  }

                  return (
                    <div className="top-dishes-set" key={index}>
                      <div className="top-dishes-box">
                        <div className="top-dishes-close-btn-content">
                          <button className="top-dishes-close-btn" onClick={() => this.onConfirmToDelete(value.id)}>
                            <i className="fa fa-times-circle" />
                          </button>
                        </div>
                        <div className="top-dishes-img-content">
                          <div
                            className="top-dishes-img"
                            style={{
                              backgroundImage: "url(" + photoUrl + ")"
                            }}
                          />
                        </div>
                        <div className="top-dishes-detail">
                          <div className="top-dishes-name text-center">
                            {value.name}
                          </div>
                          <div className="top-dishes-rating-and-edit-set">
                          <div className="like-heart-set">
                            <div className="heart" />
                            <div
                              className="heart-fill-range"
                              style={{
                                width:
                                  (value.rating > 0 ? value.rating * 20 : 0) +
                                  "%"
                              }}
                            >
                              <div className="heart-fill" />
                            </div>
                          </div>
                          {value.rating && (
                            <div className="top-dishes-rating w-auto">
                              {value.rating}{" "}
                              <span className="rating-count">
                                ({value.rating_count})
                              </span>
                            </div>
                          )}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/*Dishes*/}
              {/*Dishes*/}
              {/*Dishes*/}
              {this.props.topMenus &&
                this.props.topMenus.items &&
                this.props.topMenus.items.length >= 0 &&
                !this.props.inTopDishProgress && (
                  <div className="top-dishes-set" onClick={scrollToAllDishContainer}>
                    <div className="top-dishes-box-add">
                      <i className="fa fa-plus" aria-hidden="true" />
                    </div>
                  </div>
                )}
              {/*Dishes*/}
              {/*Dishes*/}
              {/*Dishes*/}
              {this.props.topMenus &&
                this.props.topMenus.items &&
                this.props.topMenus.items.length >= 0 &&
                !this.props.inTopDishProgress && (
                  <div className="top-dishes-set">
                    <div className="buy-more-card">
                      <div className="buy-more-card-text">
                        <div className="buy-more-card-text">Buy More Slots</div>
                        <div className="buy-more-card-sub-text">
                          Max Limit 10
                        </div>
                      </div>
                      <div className="buy-more-card-bottom-set">
                        <div className="buy-more-card-text">
                          <div className="buy-more-card-price">500 HP</div>
                          <div className="buy-more-card-price-text">
                            Per Dish / Month
                          </div>
                        </div>
                        <button
                          onClick={() => this.setMenuSlotModalVisible(true)}
                          type="button"
                          className="button-md font-700 text-upper ripple-btn-green btn-green mt-1"
                        >
                          Buy More
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {/*Dishes*/}
            </div>
          </div>
        )}

        {/*Top Dishes End*/}
        <div className="divider" />
        {/*Your Star Dishes Start*/}
        <div className="section-title-content" ref={allDishRef}>
          <div className="row">
            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <div className="section-title">
                Your Star Dishes
                {this.state.addedMenuItemsId.length > 0 && (
                  <button type="button" className="btn btn-sm btn-success btn-addmore-dish ml-3" onClick={() => this.setAddModalVisible(true)}>
                    Add To Top Dishes
                  </button>
                )}
                {this.state.currentRestaurant.max_top_menus && (
                  <span className="btn add-more-text ml-2">
                    You can add{ " " }
                    {pendingMenus - this.state.addedMenuItemsId.length} more
                    items
                  </span>
                )}
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="rating-and-count-filter-set">
                <div
                  className="rating-filter"
                  onClick={() => this.onSortWithDishes("rating_count")}
                >
                  <span>Rating Count</span>
                  <button className="rating-filter-icon">
                    <i className="fa fa-filter" />
                  </button>
                </div>
                <div
                  className="rating-filter"
                  onClick={() => this.onSortWithDishes("rating")}
                >
                  <span>Rating</span>
                  <button className="rating-filter-icon">
                    <i className="fa fa-filter" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.props.menus &&
          this.props.menus.items &&
          this.props.menus.items.length === 0 &&
          !this.props.inAllDishProgress && (
            <div className="no-content-container">
              <div className="icon">
                <i className="fa fa-coffee" />
              </div>
              <div className="text">No dishes available.</div>
            </div>
          )}
          {(this.props.currentRestaurantInProgress && !this.state.isLoadMore ||
            this.props.inAllDishProgress && !this.state.isLoadMore) && <AllDishLoader />}
        {(this.state.isLoadMore || !this.state.isSort) && this.state.loadedMenus &&
          this.state.loadedMenus.length > 0 && (<div className="row-content">
            <div className="row">
              {/*Star Dishes*/}
              {
                this.state.loadedMenus.map((value, index) => {
                  let topDishesPhotoUrl = "";
                  if (value.photo) {
                    topDishesPhotoUrl = `${this.props.menus &&
                      this.props.menus.meta &&
                      this.props.menus.meta.photo.path}/${this.props.menus &&
                      this.props.menus.meta &&
                      this.props.menus.meta.photo.folders.menu}/${this.props
                      .menus &&
                      this.props.menus.meta &&
                      this.props.menus.meta.photo.sizes.large}/${value.photo}`;
                  }
                  if (!value.photo && value.dish && value.dish.photo) {
                    topDishesPhotoUrl = `${this.props.menus &&
                      this.props.menus.meta &&
                      this.props.menus.meta.photo.path}/${this.props.menus &&
                      this.props.menus.meta &&
                      this.props.menus.meta.photo.folders.dish}/${this.props
                      .menus &&
                      this.props.menus.meta &&
                      this.props.menus.meta.photo.sizes.large}/${
                      value.dish.photo
                    }`;
                  }

                  const filteredAddedMenu = this.state.addedMenuItemsId.filter( (itemId) => {
                    return itemId ===  value.id;
                  });

                  return (
                    <div className="star-dishes-set" key={index}>
                      <div
                        className={
                          filteredAddedMenu.length > 0 &&
                          filteredAddedMenu[0] === value.id
                            ? "star-dishes-box cursor-pointer selected"
                            : "star-dishes-box cursor-pointer"
                        }
                        onClick={() => this.onAddItemToMenus(value.id)}
                      >
                       {!this.state.alreadyLoadedTopMenus.includes(value.id)  && (
                        <div className="top-dishes-close-btn-content">
                        <button className="star-dishes-tick-btn">
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                       )}
                        <div className="top-dishes-img-content">
                        <div
                        className="star-dishes-img"
                        style={{
                          backgroundImage: "url(" + topDishesPhotoUrl + ")"
                        }}
                      />
                        </div>
                        <div className="top-dishes-detail">
                          <div className="star-dishes-name text-center">
                            {value.name}
                          </div>
                          <div className="top-dishes-rating-and-edit-set ">
                            <div className="like-heart-set">
                              <div className="heart" />
                              <div
                                className="heart-fill-range"
                                style={{
                                  width:
                                    (value.rating > 0 ? value.rating * 20 : 0) +
                                    "%"
                                }}
                              >
                                <div className="heart-fill" />
                              </div>
                            </div>
                            {value.rating && (
                              <div className="top-dishes-rating w-auto">
                                {value.rating}{" "}
                                <span className="rating-count">
                                  ({value.rating_count})
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/*Star Dishes*/}

            </div>
          </div>)}
          {this.state.isLoadMore && (
              <AllDishLoader />
            )
          }
            {this.props.menus && this.props.menus.meta && this.props.menus.meta.total !== this.state.loadedMenus.length && (
              <div className="dish-load-more" onClick={() => this.onLoadMoreItemsToDishes()}>
              <button
                  type="button"
                  className="button-md font-700 text-upper ripple-btn-green btn-green mt-1"
                >
                  Load More...
                </button>
              </div>
            )}
        {/*Your Star Dishes End*/}
        <Modal className="common-modal-content"
              title=""
              centered
              visible={this.state.addModalVisible}
              onOk={() => this.setAddModalVisible(false)}
              onCancel={() => this.setAddModalVisible(false)}
              footer={[
                <Button disabled={this.props.inTopDishProgress || this.props.isUpdateMenuProgress} className="outline-secondary-btn-lg ripple-btn-secondary" key="back" onClick={() => this.setAddModalVisible(false)}>Dismiss</Button>,
                <Button loading={this.props.inTopDishProgress || this.props.isUpdateMenuProgress} className="pink-button-lg ripple-btn-pink" key="submit" type="primary" onClick={() => this.onAddTopMenus()}> Accept</Button>]}
            >
              <div className="modal-container">
                <div className="modal-text">Top dish modal</div>
                <div className="modal-mid-content" style={{fontSize: '16px'}}>
                  Are you confirm to add this item to top dishes?
                </div>
              </div>
        </Modal>
        <Modal className="common-modal-content"
              title=""
              centered
              visible={this.state.removeModalVisible}
              onOk={() => this.setRemoveModalVisible(false)}
              onCancel={() => this.setRemoveModalVisible(false)}
              footer={[
                <Button disabled={this.props.inTopDishProgress || this.props.isUpdateMenuProgress} className="outline-secondary-btn-lg ripple-btn-secondary" key="back" onClick={() => this.setRemoveModalVisible(false)}>No</Button>,
                <Button loading={this.props.inTopDishProgress || this.props.isUpdateMenuProgress} className="pink-button-lg ripple-btn-pink" key="submit" type="primary" onClick={() => this.onRemoveTopMenus()}> Yes</Button>]}
            >
              <div className="modal-container">
                <div className="modal-text">Top dish modal</div>
                <div className="modal-mid-content" style={{fontSize: '16px'}}>
                  Are you confirm to remove this item from top dishes?
                </div>
              </div>
        </Modal>
        <Modal className="common-modal-content buy-more-modal-container"
              title=""
              centered
              visible={this.state.menuSlotModalVisible}
              onOk={() => this.setMenuSlotModalVisible(false)}
              onCancel={() => this.setMenuSlotModalVisible(false)}
              footer={[
                <Button disabled={this.props.isUpdateMenuSlotsProgress} className="outline-secondary-btn-lg ripple-btn-secondary" key="back" onClick={() => this.setMenuSlotModalVisible(false)}>No</Button>,
                <Button disabled={!this.state.menuSlotNumber} loading={this.props.isUpdateMenuSlotsProgress } className="pink-button-lg ripple-btn-pink" key="submit" type="primary" onClick={() => this.onUpdateTopMenuSlots()}> Yes</Button>]}
            >
              <div className="modal-container">
                <div className="modal-text">Buy More</div>
                <div className="modal-mid-content-form input-container" style={{fontSize: '16px'}}>
                <label>No Of Slots</label>
                <InputNumber className="antd-input-number input" placeholder="No of slot" onChange={(value) => this.onUpdateTopMenuSlotNumber(value)}/>
                <div>
                {this.props.topMenuSlotErrors && this.props.topMenuSlotErrors.errors && this.props.topMenuSlotErrors.errors.map((value, index) => {
                  return <div className="error" key={index}>{
                    value.rules && value.rules.map((value, index) => {
                      return value.message;
                    })}</div>
                })}
                </div>
                </div>
              </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    menus: state.restaurant.menus,
    topMenus: state.restaurant.topMenus,
    inTopDishProgress: state.restaurant.inTopDishProgress,
    inAllDishProgress: state.restaurant.inAllDishProgress,
    currentRestaurant: state.profile.currentRestaurant,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    isUpdateMenuProgress: state.restaurant.isUpdateMenuProgress,
    updateTopMenus:  state.restaurant.updateTopMenus,
    isUpdateMenuSlotsProgress: state.restaurant.isUpdateMenuSlotsProgress,
    updateTopMenuSlots:  state.restaurant.updateTopMenuSlots,
    topMenuSlotErrors:  state.restaurant.topMenuSlotErrors,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetAllDishes: payload => dispatch({ type: GET_ALL_MENUS, payload }),
  onGetTopDishes: payload => dispatch({ type: GET_TOP_MENUS, payload }),
  onUpdateTopMenus: requestParams => dispatch({ type: UPDATE_TOP_MENUS, payload: agent.Retaurant.updateTopMenus(requestParams) }),
  onUpdateTopMenuSlots: requestParams => dispatch({ type: UPDATE_TOP_MENU_SLOT, payload: agent.Retaurant.updateTopMenuSlots(requestParams) }),
  onUnload: () => dispatch({ type: MENUS_UNLOADED })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dishes);
