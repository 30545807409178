import {
  VENDOR_PROFILE_LOADED,
  VENDOR_PROFILE_UNLOADED,
  RESTAURANT_LOADED,
  RESTAURANT_UNLOADED,
  HOPPOINTS_VIEW_SUMMARY_LOADED,
  HOPPOINTS_VIEW_SUMMARY_UNLOADED,
  CURRENT_RESTAURANT,
  ASYNC_START
} from "../constants/actionTypes";

const defaultState = {
  vendorProfile: {},
  vendorRestaurant: {},
  currentRestaurant: {},
  hoppointsDetails: {},
  inProgress: true,
  currentRestaurantInProgress: true
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case VENDOR_PROFILE_LOADED:
      return {
        ...state,
        vendorProfile: action.error ? {} : action.payload,
        inProgress: true
      };
    case RESTAURANT_LOADED:
      // console.log(action.payload && action.payload.items && action.payload.items.length === 0 ? '/404' : '/');
      
      return {
        ...state,
        redirectTo: action.payload && action.payload.items && action.payload.items.length === 0 ? '/404' : '/' ,
        vendorRestaurant: action.error ? {} : action.payload,
        inProgress: true
      };
    case CURRENT_RESTAURANT:
      return {
        ...state,
        currentRestaurant: action.error ? {} : action.payload,
        currentRestaurantInProgress: false
      };
    case HOPPOINTS_VIEW_SUMMARY_LOADED:
      return {
        ...state,
        hoppointsDetails: action.error ? {} : action.payload,
        inProgress: true
      };
    case ASYNC_START:
      if (action.subtype === CURRENT_RESTAURANT) {
        return { ...state, currentRestaurantInProgress: true };
      }
      return { ...state };
    case VENDOR_PROFILE_UNLOADED:
    case RESTAURANT_UNLOADED:
    case HOPPOINTS_VIEW_SUMMARY_UNLOADED:
      return { ...state, inProgress: false };
    default:
      return state;
  }
};
