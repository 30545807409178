import React, { Component } from "react";

const AllDishLoader = () => (
  <React.Fragment>
    {/*Content Placeholder Start*/}
    <div className="content-placeholder top-dish-placeholder">
      <div className="row-content d-block">
        <div className="row">
          {/*Dishes*/}
          <div className="top-dishes-set star-dishes-set">
            <div className="top-dishes-box star-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <button className="top-dishes-edit-icon p-bg" />
                  <div className="top-dishes-rating-bg p-bg" />
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
          {/*Dishes*/}
          <div className="top-dishes-set star-dishes-set">
            <div className="top-dishes-box star-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <button className="top-dishes-edit-icon p-bg" />
                  <div className="top-dishes-rating-bg p-bg" />
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
          {/*Dishes*/}
          <div className="top-dishes-set star-dishes-set">
            <div className="top-dishes-box star-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <button className="top-dishes-edit-icon p-bg" />
                  <div className="top-dishes-rating-bg p-bg" />
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
          {/*Dishes*/}
          <div className="top-dishes-set star-dishes-set">
            <div className="top-dishes-box star-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <button className="top-dishes-edit-icon p-bg" />
                  <div className="top-dishes-rating-bg p-bg" />
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
          {/*Dishes*/}
          <div className="top-dishes-set star-dishes-set">
            <div className="top-dishes-box star-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <button className="top-dishes-edit-icon p-bg" />
                  <div className="top-dishes-rating-bg p-bg" />
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
        </div>
      </div>
    </div>
    {/*Content Placeholder End*/}
  </React.Fragment>
);

const TopDishLoader = () => (
  <React.Fragment>
    {/*Content Placeholder Start*/}
    <div className="content-placeholder top-dish-placeholder">
      <div className="row-content  d-block">
        <div className="row">
          {/*Dishes*/}
          <div className="top-dishes-set">
            <div className="top-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <div className="top-dishes-rating-bg p-bg" />
                  <div className="top-dishes-rating ml-auto">
                    <button className="top-dishes-edit-icon p-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
          {/*Dishes*/}
          <div className="top-dishes-set">
            <div className="top-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <div className="top-dishes-rating-bg p-bg" />
                  <div className="top-dishes-rating ml-auto">
                    <button className="top-dishes-edit-icon p-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
          {/*Dishes*/}
          <div className="top-dishes-set">
            <div className="top-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <div className="top-dishes-rating-bg p-bg" />
                  <div className="top-dishes-rating ml-auto">
                    <button className="top-dishes-edit-icon p-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
          {/*Dishes*/}
          <div className="top-dishes-set">
            <div className="top-dishes-box">
              <div className="top-dishes-close-btn-content">
                <button className="top-dishes-close-btn p-bg" />
              </div>
              <div className="top-dishes-img p-bg" />
              <div className="top-dishes-detail">
                <div className="top-dishes-name p-bg" />
                <div className="top-dishes-rating-and-edit-set d-flex align-items-center">
                  <div className="top-dishes-rating-bg p-bg" />
                  <div className="top-dishes-rating ml-auto">
                    <button className="top-dishes-edit-icon p-bg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Dishes*/}
        </div>
      </div>
    </div>
    {/*Content Placeholder End*/}
  </React.Fragment>
);

export { AllDishLoader, TopDishLoader };
