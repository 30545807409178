import React from 'react';
import { Table, Switch, Input, message } from 'antd';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';

import agent from "../../../agent";
import {
    MENU_VARIANT_GROUPS, CHANGE_MENU_VARIANT_GROUP_STATUS, CHANGE_MENU_VARIANT_STATUS, MENU_VARIANTS
} from "../../../constants/actionTypes";

const { Search } = Input;

class AddonsManagement extends React.Component {
    constructor(props) {
		super(props);
		this.onChangeSearchValue = debounce(this.onChangeSearchValue, 1200);
	}
    state={
        addonsList: [],
        processing: false,
        variantsList: [],
        variantsProcessing: false,
        searchAddon:'',
        addonListApiParams: {
            // probably we will get all addons
            limit: 1000, 
            // requesting api to sort addons list by recently updated
            sort: 'updated_at.desc'
        },
        variantsListApiParams: {
            // probably we will get all variants of a group
            limit: 1000, 
            // requesting api to sort addons list by recently updated
            sort: 'updated_at.desc'
        }
    }
    columns = [
        {
          title: 'Item',
          dataIndex: 'name',
        },
        {
          title: 'Availability',
          key: 'availability',
          render: (text, record) => (
            <span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={!!+_.get(record, 'status', 0)}
                  onChange={checked=>this.onChangeAddonAvailability(checked, _.get(record, 'id', null))}
                />
            </span>
          ),
        },
    ];
    variantColumns = [
        {
          title: 'Item',
          dataIndex: 'name',
        },
        {
            title: 'Price',
            dataIndex: 'price',
        },
        {
          title: 'Availability',
          key: 'availability',
          render: (text, record) => (
            <span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={!!+_.get(record, 'status', 0)}
                  onChange={checked=>this.onChangeAddonVariantAvailability(checked, record)}
                />
            </span>
          ),
        },
    ];
    componentDidMount(){
        this.fetchRestaurentAddons();
    }
    fetchRestaurentAddons = () => {
        this.setState({processing: true});
        this.props.getRestaurentAddons({..._.get(this, 'state.addonListApiParams', {}), status:0}, (success, data)=>{
            let buildAddonsList = _.get(data, 'items', []);
            this.props.getRestaurentAddons({..._.get(this, 'state.addonListApiParams', {}), status:1}, (success, data)=>{
                buildAddonsList = _.concat(buildAddonsList, _.get(data, 'items', []));
                this.setState({processing: false, addonsList: buildAddonsList});
            });
        });
    }
    fetchMenuGroupVariantsList = (fieldProperties) => {
        const group = _.get(fieldProperties, 'group_id', null);
        this.updateAddonDetails({is_variants_loading_in_progress: true}, group);
        this.updateVariantList(group, []);
        this.props.getMenuGroupVariants({..._.get(this, 'state.variantsListApiParams', {}), group, status:0}, (success, data)=>{
            let variantsList = _.get(data, 'items', []);
            this.props.getMenuGroupVariants({..._.get(this, 'state.variantsListApiParams', {}),group, status:1}, (success, data)=>{
                variantsList = _.concat(variantsList, _.get(data, 'items', []));
                this.updateVariantList(group, variantsList);
                this.updateAddonDetails({is_variants_loading_in_progress: false}, group);
            });
        });
    }
    updateAddonDetails = (properties, addonId) =>{
        let modifyAddonList = _.get(this, 'state.addonsList',[]);
        const addonIndex = _.findIndex([...modifyAddonList], {id: addonId});
    
        if(addonIndex>-1){
            modifyAddonList[addonIndex]={...modifyAddonList[addonIndex], ...properties};
            this.setState({addonsList: modifyAddonList});
        }
    }
    updateVariantAvailability = (availability, addonId, variantId) =>{
        let modifyAddonList = _.clone(_.get(this, 'state.addonsList',[]));
        const addonIndex = _.findIndex(modifyAddonList, {id: addonId});
    
        if(addonIndex>-1){
            const variantIndex = _.findIndex(_.get(modifyAddonList, `[${addonIndex}].variants`, []), {id: variantId});
            if(variantIndex>-1){
                modifyAddonList[addonIndex]['variants'][variantIndex]={...modifyAddonList[addonIndex]['variants'][variantIndex],status: Number(availability)};
                this.setState({addonsList: modifyAddonList});
            }
        }
    }
    updateVariantList = (addonId, variantList) =>{
        let modifyAddonList = _.clone(_.get(this, 'state.addonsList',[]));
        const addonIndex = _.findIndex(modifyAddonList, {id: addonId});
    
        if(addonIndex>-1){
            modifyAddonList[addonIndex]['variants']=variantList;
        }
    }
    onChangeAddonAvailability = (checked, addonId) => {
        this.updateAddonDetails({status: Number(checked)}, addonId);
        this.setState({processing: true});
        this.props.changeRestaurentAddonStatus({id: addonId}, { status: Number(checked), status_glossary: "Restaurent have been changed the addon status" }, (success, data)=>{
            const defaultMessage = 'Addon status changed successfully.';
            if(success){
                message.success(_.get(data, 'message', defaultMessage));
            }else{
                this.updateAddonDetails({status: Number(!checked)}, addonId);
            }
            this.setState({processing: false});
        });
    }

    onChangeAddonVariantAvailability = (checked, variant) => {
        const menu_variant_group_id = _.get(variant, 'group', null);
        const variantId = _.get(variant, 'id', null);
        this.updateVariantAvailability(checked, menu_variant_group_id, variantId);
        this.updateAddonDetails({is_variants_loading_in_progress: true}, menu_variant_group_id);
        this.props.changeRestaurantAddonVariantStatus({id: variantId}, { status: Number(checked), status_glossary: "Restaurent have been changed the variant status" }, (success, data)=>{
            const defaultMessage = 'Variant status changed successfully.';
            if(success){
                message.success(_.get(data, 'message', defaultMessage));
            }else{
                this.updateVariantAvailability(!checked, menu_variant_group_id, variantId);
            }
            this.updateAddonDetails({is_variants_loading_in_progress: false}, menu_variant_group_id);
        });
    }

    buildTableDataSource = (addonList, searchValue) => {
        if(_.isEmpty(searchValue)){
            return addonList;
        }else {
            return _.filter(addonList, addonItem=>{
                const addonItemName = _.toLower(_.get(addonItem, 'name', ""));
                searchValue = _.toLower(searchValue);
                return _.includes(addonItemName, searchValue);
            });
        }
    }

    onChangeSearchValue = value => {
        value = _.trim(value);
        this.setState({searchAddon: value});
    }

    expandedRowRenderFunctionality = record => {
        const variantsList = _.get(record, 'variants', []);
        const loading = _.get(record, 'is_variants_loading_in_progress', false);
        const localeEmptyText = !loading?'No variants found for the menu group.':'Loading...';
        return (
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div className="item-expandable-view">
                    <Scrollbars style={{height: 200 }}>
                        <Table loading={loading} dataSource={variantsList} rowKey='id' columns={this.variantColumns} pagination={false} locale={{emptyText: localeEmptyText}} />
                    </Scrollbars>
                </div>
            </div>
        );
    }
    mainComponent=()=>{
        return (
            <React.Fragment>
                <div className="full-width">
                    <div className="row justify-content-end mb-3">
                        <div className="col-3">
                            <Search placeholder="Search here" style={{ width: '100%' }} allowClear onChange={value=>this.onChangeSearchValue(_.get(value, 'target.value', null))} />
                        </div>
                    </div>
                </div>
                <div className="full-width">
                <Scrollbars style={{height: 400 }}>
                    <Table loading={_.get(this, 'state.processing', false)} rowKey='id' expandedRowRender={this.expandedRowRenderFunctionality} dataSource={this.buildTableDataSource(_.get(this,'state.addonsList', []), _.get(this, 'state.searchAddon', ''))} columns={this.columns} pagination={false} onExpand={(expanded, record)=>{
                            if(expanded){
                                this.fetchMenuGroupVariantsList({group_id: _.get(record, 'id', null)});
                            }
                        }}
                    />
                </Scrollbars>
                </div>
            </React.Fragment>
        );
    }
    render(){
        return this.mainComponent();
    }
}
const mapStateToProps = state => ({restaurant: _.get(state, 'restaurant', {})});

const mapDispatchToProps = dispatch => ({
    getRestaurentAddons: (queryParams, callback) => dispatch({ type: MENU_VARIANT_GROUPS, payload: agent.Retaurant.menuVariantGroups(queryParams), callback: callback }),
    getMenuGroupVariants: (queryParams, callback) => dispatch({ type: MENU_VARIANTS, payload: agent.Retaurant.menuVariants(queryParams), callback: callback }),
    changeRestaurentAddonStatus: (queryParams, bodyParams, callback) => dispatch({ type: CHANGE_MENU_VARIANT_GROUP_STATUS, payload: agent.Retaurant.changeMenuVariantGroupStatus(queryParams, bodyParams), callback: callback }),
    changeRestaurantAddonVariantStatus: (queryParams, bodyParams, callback) => dispatch({ type: CHANGE_MENU_VARIANT_STATUS, payload: agent.Retaurant.changeMenuVariantStatus(queryParams, bodyParams), callback: callback })
});

export default connect(mapStateToProps, mapDispatchToProps)(AddonsManagement);