import React, { Component } from "react";
import { connect } from "react-redux";

import ReactTooltip from 'react-tooltip';
import { Spin } from "antd";

import { capitalizeWord, formatDate } from "../../utils/Helpers";
import { MENUS_UNLOADED } from "../../constants/actionTypes";
import { Link} from "react-router-dom";

class TransactionsTable extends Component {


  onAddReceiverId = (receiverId) => {
    this.props.onAddReceiversForNotifiaction(receiverId);
  }

  render() {
    return (
      <table className="table table-bordered table-striped">
        <thead className="thead-light">
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Points </th>
            <th scope="col">Then Balance</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {(this.props.inTransactionsProgress || this.props.currentRestaurantInProgress) &&
            <tr><td colSpan="7"><Spin className="transactions-table-spinner" /></td></tr>}
          {(!this.props.currentRestaurantInProgress || !this.props.inTransactionsProgress) && this.props.transactions &&
            this.props.transactions.items && this.props.stateData &&
            this.props.stateData.length === 0 && (<tr><td colSpan="7">
              <div className="no-content-container">
                <div className="icon">
                  <i className="fa fa-coffee" />
                </div>
                <div className="text">No data</div>
              </div>
            </td></tr>)}
						{!this.props.inTransactionsProgress && this.props.stateData &&
	            this.props.stateData.map((value, index) => {
	              return (
	                <tr key={index}>
                  {(() =>{if(value.granter_type === 0){
                    return<td className='text-danger'>Debit</td>
                  }else{
                    return<td className='text-success'>Credit</td>}
                })()}
                    <td>{value.points}</td>
	                  <td>{value.then_balance}</td>
	                  <td>{value.description}</td>
	                </tr>
	              );
	            })
	          }
	        </tbody>
	      </table>
	    )
	  }
	}

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    inTransactionsProgress: state.hoppoints.inTransactionsProgress,
    currentRestaurant: state.profile.currentRestaurant,
    transactions: state.hoppoints.transactions,
    notificationData: state.users.notificationData,
    inSendNotificationProgress: state.users.inSendNotificationProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onUnload: () => dispatch({ type: MENUS_UNLOADED })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionsTable);
