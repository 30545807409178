import React, { Component } from "react";
import { Modal, Button } from "antd";
import SecureLS from "secure-ls";
import { secondsToTime } from "../../utils/Helpers";
import Timer from "react-compound-timer";

const lStorage = new SecureLS();

class VisitModal extends Component {
  setVisitModal = value => {
    this.props.setVisitModal(value);
  };

  onAcceptUtilityRequest = () => {
    const utilitiesRequest =
      lStorage.get("utilities").length > 0 ? lStorage.get("utilities") : [];
    const findIndex = utilitiesRequest.findIndex(
      ele => ele.id === this.props.selectedUtilitiesData.id
    );
    if (findIndex > -1) {
      utilitiesRequest.splice(findIndex, 1);
    }
    lStorage.set("utilities", utilitiesRequest);
    this.props.onRemovedUtilityItems(utilitiesRequest);
  };

  render() {
    let userPhoto = "";
    if (
      (this.props.userDetails &&
      this.props.userDetails.meta) &&
      (this.props.userDetails &&
      this.props.userDetails.details &&
      this.props.userDetails.details.photo)
    ) {
      userPhoto = `${this.props.userDetails.meta.photo.path}/${this.props.userDetails.meta.photo.folder}/${this.props.userDetails.meta.photo.sizes.small}/${this.props.userDetails.details.photo}`;
    }
    let userDetails = {};
    if(this.props.userDetails && this.props.userDetails.details) {
        userDetails = this.props.userDetails.details;
    }
    let selectedVisitData = {};
    if(this.props.selectedVisitData && this.props.selectedVisitData) {
      selectedVisitData = this.props.selectedVisitData;
    }
    let sessionTime = {h: 0, m: 0, s: 0};
    let difference = 0;
    if(selectedVisitData.entered_at){
        let entered_at = new Date(selectedVisitData.entered_at).getTime();
        let date = new Date().getTime();
        difference = date-entered_at;
        let hours = Math.floor(difference/(60*60*1000));
        let minutes = Math.floor((difference/(60*1000))-(hours*60));
        let seconds = Math.floor((difference/1000)-((hours*60*60)+(minutes*60)));
        sessionTime = {h: hours, m: minutes, s: seconds};
    }

    return (
      <Modal
        className="common-modal-content"
        title=""
        centered
        visible={this.props.visitModal}
        onOk={() => this.setVisitModal(false)}
        onCancel={() => this.setVisitModal(false)}
        footer={[
          <Button
            className="outline-secondary-btn-md ripple-btn-secondary"
            key="back"
            onClick={() => this.setVisitModal(false)}
          >
            Dismiss
          </Button>
        ]}
      >
        <div className="modal-container">
          <div className="order-receive-row-set">
            <div className="order-receive-text">
              <i className="fa fa-clock-o"></i>
              <Timer  initialTime = {difference}>
                  <Timer.Hours  />:
                  <Timer.Minutes />:
                  <Timer.Seconds  />
              </Timer>
            </div>
          {/*<div className="order-receive-text text-right">
              Table <span className="font-700">05</span>
            </div>*/}
          </div>
          <div className="order-receive-row-set">
            <div className="order-receive-member-left-set">
              <div className="order-receive-user-image">
                <img
                  className="img-fluid rounded-circle"
                  src={userPhoto}
                />
              </div>
              <div className="order-receive-user-detail">
                <div className="order-receive-user-name">
                  {(() => {
                    if (
                      userDetails &&
                      userDetails.first_name &&
                      userDetails.last_name
                    ) {
                      return (
                        userDetails.first_name +
                        " " +
                        userDetails.last_name
                      );
                    } else if (
                      userDetails &&
                      userDetails.first_name
                    ) {
                      return userDetails.first_name;
                    }
                  })()}
                </div>
                <div className="order-receive-user-location">{userDetails && userDetails.city ? userDetails.city : ''}</div>
              </div>
            </div>
            <div className="order-receive-member-right-set text-right">
              <div className="restaurant-count">
                <i className="fa fa-cutlery"></i> {selectedVisitData && selectedVisitData.user && selectedVisitData.user.no_of_orders ? selectedVisitData.user.no_of_orders : 0}
              </div>
              <div className="restaurant-user-visit-count">
               {(() => {
                      if(selectedVisitData && selectedVisitData.user && selectedVisitData.user.no_of_visits) {
                          if(selectedVisitData.user.no_of_visits === 1) {
                            return <span>{selectedVisitData.user.no_of_visits} Visit</span>;
                          }else{
                            return  <span>{selectedVisitData.user.no_of_visits} Visits</span>;
                        };
                      }
                })()}
              </div>
            </div>
          </div>
          <div className="order-receive-member-info-content text-center ">
            <div className="order-receive-member-info-top">
            <ul>
              {(() => {
                    if(selectedVisitData && selectedVisitData.user && selectedVisitData.user.no_of_reviews) {
                        if(selectedVisitData.user.no_of_reviews === 1) {
                          return <li>{selectedVisitData.user.no_of_reviews} Review</li>;
                        }else{
                          return  <li>{selectedVisitData.user.no_of_reviews} Reviews</li>;
                      };
                    }
                  })()}
              <li>{selectedVisitData && selectedVisitData.user && selectedVisitData.user.average_rating ? selectedVisitData.user.average_rating : 0} Avg Rating</li>
              {(() => {
                    if(selectedVisitData && selectedVisitData.user && selectedVisitData.user.no_of_restaurant_visits) {
                        if(selectedVisitData.user.no_of_restaurant_visits === 1) {
                          return <li>{selectedVisitData.user.no_of_restaurant_visits} Restaurant</li>;
                        }else{
                          return  <li>{selectedVisitData.user.no_of_restaurant_visits} Restaurants</li>;
                      };
                    }
                  })()}
            </ul>
            </div>
            <div className="order-receive-member-info-mid">
              <ul>
                <li>Gender: {userDetails && userDetails.gender ? userDetails.gender : '-'}</li>
                <li>Age-Group:
                  {(() => {
                    if(userDetails.age_range === 0){
                      return('<15');
                    }else if(userDetails.age_range === 1){
                      return('15-18');
                    }else if(userDetails.age_range === 2){
                      return('18-25');
                    }else if(userDetails.age_range === 3){
                      return('25-30');
                    }else if(userDetails.age_range === 4){
                      return('30-40');
                    }else if(userDetails.age_range === 5){
                      return('<40');}
                  })()}
                </li>
              </ul>
            </div>
            <div className="order-receive-member-info-bottom">
               {(() => {
                if(userDetails && userDetails.details && userDetails.details.food_preference) {
                  return userDetails.details.food_preference.map((value, index) => {
                    if(value === 0) {
                      return <span key={index} className="badge-green">VEGETARIAN</span>;
                    }else if(value === 1) {
                      return <span key={index} className="badge-green">JAIN</span>;
                    }else if(value === 2) {
                      return <span key={index} className="badge-green">EGG</span>;
                    }else if(value === 3) {
                      return <span key={index} className="badge-green">VEGAN</span>;
                    }
                  });
                }
              })()}
              {(() => {
                if(userDetails && userDetails.details && userDetails.details.allergies) {
                  return userDetails.details.allergies.map((value, index) => {
                      return <span key={index} className="badge-pink">{value.name}</span>;
                  });
                }
              })()}
            </div>

            <div className="social-icons-group">
            <div className="footer-social-icons">
              <ul className="social-icons">
                <li><a href="" className="social-icon"> <i className="fa fa-facebook"></i></a></li>
                <li><a href="" className="social-icon"> <i className="fa fa-twitter"></i></a></li>
                <li><a href="" className="social-icon"> <i className="fa fa-linkedin"></i></a></li>
                <li><a href="" className="social-icon"> <i className="fa fa-youtube"></i></a></li>
                <li><a href="" className="social-icon"> <i className="fa fa-instagram"></i></a></li>
              </ul>
            </div>
            </div>
          </div>
          {(() => {
            if(selectedVisitData && selectedVisitData.discounts) {
              return (
                   <div className="order-receive-row-set">
                     <div className="order-receive-offer-icon">
                       <i className="fa fa-tag" aria-hidden="true"></i>
                     </div>
                     <div className="order-receive-offer-content">
                     <div className="order-receive-offer-text"></div>
                     <div className="order-receive-discount-text">
                     {(()=>{
                       return selectedVisitData.discounts.map((value, index) => {
                           if(value.amount_type === 0) {
                             return <div key={index}>{value.label}: {value.amount}%</div>;
                           }else if(value.amount_type === 1) {
                             return <div key={index}>{value.label}: Rs.{value.amount}</div>;
                           }
                         });
                       })()
                     }
                     </div>
                   </div>
                   </div>
                 )
               }
             })()}
        </div>
      </Modal>
    );
  }
}

export default VisitModal;
