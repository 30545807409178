import React, { Component } from "react";
import { Select, Modal, Button } from "antd";
import SecureLS from "secure-ls";

import { convertArrayCommaSeperated } from "../../utils/Helpers";

const lStorage = new SecureLS();

class UtilitiesModal extends React.Component {

    setUtilitiesModal = (value) => {
        this.props.setUtilitiesModal(value)
    }

    onAcceptUtilityRequest = () => {
       const utilitiesRequest =  lStorage.get("utilities").length > 0 ? lStorage.get("utilities") : [];
       if(this.props.currentUtilityIndex > -1) {
           utilitiesRequest.splice(this.props.currentUtilityIndex, 1);
       }
       lStorage.set("utilities", utilitiesRequest);
       this.props.onRemovedUtilityItems(utilitiesRequest);
    }

  render() {
    return (
      <Modal
        className="common-modal-content"
        title=""
        centered
        visible={this.props.utilitiesModal}
        onOk={() => this.setUtilitiesModal(false)}
        onCancel={() => this.setUtilitiesModal(false)}
        footer={[
          <Button
            className="pink-button-sm ripple-btn-pink "
            key="submit"
            type="primary"
            onClick={() => this.onAcceptUtilityRequest()}
          >
            {" "}
            Okay
          </Button>
        ]}
      >
        <div className="modal-container">
          <div className="modal-text">You have a pending request from</div>
          <div className="modal-mid-content">
            <div className="modal-mid-right">
              <div className="modal-mid-title mb-3">
                Table{" "}
                {this.props.selectedUtilitiesData &&
                  this.props.selectedUtilitiesData.data &&
                  this.props.selectedUtilitiesData.data.table}
              </div>
                  <div className="modal-mid-text">
                  <div class="row align-items-center">
                  {this.props.selectedUtilitiesData &&
                    this.props.selectedUtilitiesData.data &&
                    this.props.selectedUtilitiesData.data &&
                  this.props.selectedUtilitiesData.data.data.map((value, index) => {
                    return (
                    <div class="col-md-6 mb-3">
                      <div class="row align-items-center no-gutters">
                          <div class="col-md-2">
                          <img
                            className="img-fluid"
                            src={`${this.props.selectedUtilitiesData.data.meta.photo.path}/${this.props.selectedUtilitiesData.data.meta.photo.folder}/${value.photo}`}
                          />
                          </div>
                          <div class="col-md-10">
                          <span className="utilities-name text-truncate pl-2">
                          {value && value.name ? value.name : ''}
                          </span>
                          </div>
                      </div>
                    </div>
                  )
                })}
                  </div>
                  </div>

            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default UtilitiesModal;
