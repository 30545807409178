import React, { useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string"
import { debounce } from 'lodash'

import "./orders-list.css";
import { Select, Input, Checkbox, Button, Modal, Form, Spin, InputNumber, notification } from "antd";
import agent from "../../agent";
import {
  MENUS_UNLOADED,
  GET_ORDERS_LIST,
  SET_UTILITIES,
  SET_ORDERS,
  GET_ORDERS,
  GET_NOTIFY_DATA,
  SET_VISITS,
  CHANGE_ORDER_STATUS,
  CLEAR_ERRORS,
  GET_VISITS,
  GET_USER_DETAILS,
  GET_USER_ORDER_DETAILS,
} from "../../constants/actionTypes";
import { capitalizeWord, formatDate,toSubtractDate } from "../../utils/Helpers";
import OrdersListTable from "./orders-list-table";
import moment from "moment";
// const [selected, setSelected] = useState()    
const FormItem = Form.Item;
// var moment = require('moment');
class OrdersList extends React.Component {
  constructor(props){
    super(props);
    this.onFilterOrdersList = debounce(this.onFilterOrdersList, 800);
    let params;
    if (this.props && this.props.location) {
      let url = this.props.location.search;
      params = queryString.parse(url);
    }
    // console.log('constructor',params.user);

    // const userId = queryParams.user ? queryParams.user : '';
    this.state = {
  		OrdersListFilterValues: {expand:"user"},
      notificationModalVisible: false,
      receivers: [],
      pageCount: 1,
      pageLimit: 10,
      loadedOrdersList: [],
      isLoadMore: false,
      isFilter: false,
      userId : params.user,
      sortBy: 'id.desc',
      curentDate: moment().format('YYYY-MM-DD'),
      filters:{}
    };
  }


  setNotificationModalVisible(notificationModalVisible) {
    this.setState({ notificationModalVisible });
  }
// API Call..
  componentDidMount() {
    if (this.props && this.props.location) {
      let url = this.props.location.search;
      let params = queryString.parse(url);
      this.setState({
        userId:params.user
      })
    }
    
    if (this.props.currentRestaurant && this.props.currentRestaurant.details) {
      let requestParams = {
        sort: this.state.sortBy,
        user: this.state.userId,
        restaurant: this.props.currentRestaurant.details.id,
        expand: "user,discounts,transactions",
        start_date: this.state.curentDate,
        // status: "0,1,2,5,8,9,7"
        status: "1,7,8"
    };
      this.props.onGetOrdersList(agent.Retaurant.orders(requestParams));
      this.onGetOrders();

    }
  }
// API Update ...
  async componentDidUpdate(prevProps) {
    // if (this.props && this.props.location) {
    //   let url = this.props.location.search;
    //   let params = queryString.parse(url);
    //   this.setState({
    //     userId:params
    //   })
    // }
    if (
      prevProps.currentRestaurant !== this.props.currentRestaurant &&
      this.props.currentRestaurant &&
      this.props.currentRestaurant.details
    ) {
			let requestParams = {
        sort: this.state.sortBy,
        user: this.state.userId,
        restaurant: this.props.currentRestaurant.details.id,
        expand: "user,discounts,transactions",
        start_date: this.state.curentDate,
        // status:"0,1,2,5,8,9,7",
        status:"1,7,8",
        limit: this.state.pageLimit,
      };
      this.props.onGetOrdersList(agent.Retaurant.orders(requestParams));
      this.setState({
        currentRestaurant: this.props.currentRestaurant.details
      });
      this.onGetOrders();

    }
    if (
      prevProps.ordersList !== this.props.ordersList &&
      this.props.ordersList &&
      this.props.ordersList.items
    ) {
      if(!this.state.isLoadMore) {
        await this.setState({
          loadedOrdersList: [],
        });
      }
      let newOrdersList = [];
      this.props.ordersList.items.map((value, index) => {
        newOrdersList.push(value);
      });
      await this.setState({
        loadedOrdersList: [...this.state.loadedOrdersList, ...newOrdersList],
        isLoadMore: false,
        isFilter: false
      });
    }
    if (
      prevProps.notificationData !== this.props.notificationData &&
      this.props.notificationData &&
      this.props.notificationData.details
    ) {
      let userId = queryString.parse(this.props.location.search)
      let requestParams = {expand:"user", user: this.state.userId};
      this.props.onGetOrdersList(agent.Retaurant.ordersList(requestParams));
      this.setNotificationModalVisible(false);
      this.setState({
        receivers: []
      });
    }
    if(prevProps.ordersListErrors !== this.props.ordersListErrors &&
      this.props.ordersListErrors && this.props.ordersListErrors.errors) {
      this.props.ordersListErrors.errors.map((value, index) => {
        value.rules.map((value, index) => {
          return notification.error({message: value.message})
        })
      })
    }
  }
   // Orders
   onGetOrders = async () => {
    let requestParams = {
      sort: this.state.sortBy,
      user: this.state.userId,
      restaurant: this.props.currentRestaurant.details.id,
      expand: "user,discounts,transactions",
      start_date: toSubtractDate(4),
      // status: "0,1,2,5,8,9,7",
      status:"1,7,8",
      limit: this.state.pageLimit,
    };
    this.props.onGetOrders(agent.Retaurant.orders(requestParams));
  };
// onChange for Filters...
  onFilterOrdersList = async (fieldName, value) => {
    // console.log('filters',fieldName, value);
    
    // if(fieldName === "updated_at"){
    //   let date = new Date()
    // value = date.setDate(date.getDate() - parseInt(value));
    // }
      await this.setState(prevState => {
        if(fieldName === "updated_at"|| fieldName=== "create_at"){
          if( moment(value).format('YYYY-MM-DD')<= this.state.curentDate){
            return {
              OrdersListFilterValues: { ...prevState.OrdersListFilterValues, [fieldName]: value },
              isLoadMore: false,
              isFilter: true
            };
          }
        }else{
          return {
            OrdersListFilterValues: { ...prevState.OrdersListFilterValues, [fieldName]: value },
            isLoadMore: false,
            isFilter: true
          };
        }
       
      });

      let requestParams = {
        ...this.state.OrdersListFilterValues,
        user:this.state.userId,
        expand:"user",
        sort:this.state.sortBy,
        restaurant:this.props.currentRestaurant.details.id,
        // status: "0,1,2,5,8,9",
        limit: this.state.pageLimit,
      };
      if(fieldName === "date"){
        let curDate = new Date()
        // console.log('date',curDate);
        // console.log('date 1',currentDate.setDate(currentDate.getDate() - parseInt(value)));
        
        let date=curDate.setDate(curDate.getDate() - parseInt(value));
        // console.log('date 1',date);
          // requestParams.start_date = moment(currentDate).format("DD/MM/YYYY")
	        requestParams.start_date = formatDate(
            date,
            "YYYY-MM-DD"
          );
	       
      }
      if (requestParams.create_at) {
        requestParams.start_date = formatDate(
          requestParams.create_at,
          "YYYY-MM-DD"
        );
      }  if (requestParams.updated_at) {
	        requestParams.end_date = formatDate(
	          requestParams.updated_at,
	          "YYYY-MM-DD"
	        );
        }
        this.setState({
          filters:requestParams
        })
      this.props.onGetOrdersList(agent.Retaurant.orders(requestParams));
  };

  onAddReceiversForNotifiaction = async receiverId => {
    await this.setState({
      receivers: [...this.state.receivers, receiverId]
    });
  };

  onSendNotification = e => {
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      if (!err) {
        data.receivers = [...this.state.receivers];
        this.props.onSendNotification(data);
      }
    });
  };
// Page Count...
  onLoadMoreItemsToDishes = async () => {
    if (
      this.props.ordersList &&
      this.props.ordersList.meta &&
      this.props.ordersList.meta.total !== this.state.loadedOrdersList.length
    ) {
      await this.setState({
        pageCount: this.state.pageCount + 1,
        isLoadMore: true,
        isFilter: false
      });
      let requestParams;
      if(this.state.filters && Object.keys(this.state.filters).length > 0){
        requestParams = {
          ...this.state.filters,
          limit: this.state.pageLimit,
          page: this.state.pageCount,
        };
      }else{
         requestParams = {
          sort: this.state.sortBy,
          user: this.state.userId,
          restaurant: this.props.currentRestaurant.details.id,
          expand: "user,discounts,transactions",
          start_date: this.state.curentDate,
          // status: "0,1,2,5,8,9,7",
          status:"1,7,8",
          limit: this.state.pageLimit,
          page: this.state.pageCount,
        };
      
      }
      
      // requestParams={...this.state.filters, requestParams}
      this.props.onGetOrdersList(agent.Retaurant.orders(requestParams));
    }
  };
  submit=()=>{
    this.props.onGetOrdersList(agent.Retaurant.orders(this.state.filters));
  }
  revert = () => {
    window.location.reload(false);
    // if (this.props.currentRestaurant && this.props.currentRestaurant.details) {
    //   this.setState({
    //     OrdersListFilterValues:{expand:"user" },
    //     value:""
    //   })
    //   // setSelected(null)
    //   let requestParams = {
    //     sort: this.state.sortBy,
    //     // user: this.state.userId,
    //     restaurant: this.props.currentRestaurant.details.id,
    //     expand: "user,discounts,transactions",
    //     start_date: this.state.curentDate,
    //     status: "0,1,5",
    //     limit: this.state.pageLimit,
    // };
    //   this.props.onGetOrdersList(agent.Retaurant.orders(requestParams));
    // }
  }
  render() {    
    // console.log('this.state', this.state);
    // const [selected, setSelected] = useState()    
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="ordersList-page-container">
	     <div className="section-title">Orders</div>
       {/* Customers Fliters....*/}
	      <div className="customer-table-filter-set" id="myForm">
					<div className="customer-filter-set">
						<span className="filter-label">Order Type</span>
						<Select
							showSearch
							style={{ width: 155 }}
							placeholder="Order Type"
              onChange={value => this.onFilterOrdersList("type", value)}
              // value={this.state.value}
						>
							<Option value="0">Dine</Option>
							<Option value="1">Take away</Option>
							<Option value="2">Delivery</Option>
						</Select>
					</div>
					<div className="customer-filter-set total-amount">
						<span className="filter-label">Total Amount</span>
						<Input style={{ width: 60,textAlign:"left",marginRight:8 + 'px' }}
							type="number"
							placeholder="0"
							onChange={e => this.onFilterOrdersList("min_total", e.target.value)}
						/>
						<span className="filter-label">To</span>
						<Input style={{ width: 60,textAlign:"left",marginRight:0 + 'px' }}
							type="number"
							placeholder="100"
							onChange={e => this.onFilterOrdersList("max_total", e.target.value)}
						/>
					</div>
          <div className="customer-filter-set start-date">
            <span className="filter-label">Start Date</span>
            <DatePicker
              selected={this.state.OrdersListFilterValues.create_at}
              onChange={date => this.onFilterOrdersList("create_at", date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              customInput={<Input style={{ width: 130 + "px", textAlign:"left" }} />}
            />
          </div>
					<div className="customer-filter-set end-date">
						<span className="filter-label">End Date</span>
						<DatePicker
							selected={this.state.OrdersListFilterValues.updated_at}
							onChange={date => this.onFilterOrdersList("updated_at", date)}
							dateFormat="dd/MM/yyyy"
							placeholderText="DD/MM/YYYY"
							customInput={<Input style={{ width: 130 + "px", textAlign:"left" }} />}
						/>
					</div>
					<div className="customer-filter-set">
						<span className="filter-label">Order Status</span>
						<Select
							showSearch
							style={{ width: 150 + "px" }}
							placeholder="Status"
              onChange={value => this.onFilterOrdersList("status", value)}
              // value={selected}
						>
							<Option value="0">Rejected</Option>
              <Option value="1">Completed Orders</Option>
							<Option value="2">Awaiting</Option>
							<Option value="5">Payment Pending</Option>
              <Option value="7">Order Canceled</Option>
							<Option value="8">Preparing</Option>
							<Option value="9">Order Ready</Option>
						</Select>
					</div>
          <div className="customer-filter-set">
						<span className="filter-label">Status</span>
						<Select
							showSearch
							style={{ width: 150 + "px" }}

							placeholder="Status"
              onChange={value => this.onFilterOrdersList("status", value)}
              // value={selected}
						>
							<Option value="0">Orders Canceled</Option>
              <Option value="1">Orders Paid</Option>
						</Select>
					</div>
          <div className="customer-filter-set">
            <span className="filter-label">Table</span>
            <Input
              type="text"
              style={{ width: 189 + "px", textAlign:"left" }}
              placeholder="Table No"
              onChange={e => this.onFilterOrdersList("table", e.target.value)}
            />
          </div>
          <div className="customer-filter-set">
						<span className="filter-label">Date</span>
						<Select
							showSearch
							style={{ width: 155 }}
							placeholder="Order Type"
              onChange={date => { this.onFilterOrdersList("date", date)}}
						>
							<Option value={0}>Present  Day</Option>
							<Option value={7}>1 Week</Option>
							<Option value={30}>1 Month</Option>
							<Option value={90}>3 Month</Option>
						</Select>
					</div>
          <div className="filter-button-group">
          <Button className="btn-submit mr-3" onClick={this.submit}> Submit</Button>
          <Button className="btn-reset" onClick={this.revert}> Reset</Button>
          </div>
         
        </div>
        {/*Table of Content*/}
        <div className="customer-table">
          <div className="pull-left">
            <div className="customer-count-text">
              {this.props.ordersList &&
              this.props.ordersList.items &&
              this.props.ordersList.items.length > 0
                ? this.props.ordersList.items.length
                : 0
                }{" "}
              Orders
            </div>
          </div>
          <div className="table-responsive">
            <OrdersListTable
            {...this.props}
            stateData={this.state.loadedOrdersList}
            onAddReceiversForNotifiaction={this.onAddReceiversForNotifiaction}
            receivers={this.state.receivers}
            />
            {this.props.ordersList &&
              this.props.ordersList.meta &&
              this.props.ordersList.meta.total !== this.state.loadedOrdersList.length && (
                <div
                  className="dish-load-more"
                  onClick={() => this.onLoadMoreItemsToDishes()}
                >
                  <button
                    type="button"
                    className="button-md font-700 text-upper ripple-btn-green btn-green mt-1"
                  >
                    Load More...
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {  
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    inOrdersListProgress: state.restaurant.inOrderListProgress,
    currentRestaurant: state.profile.currentRestaurant,
    ordersList: state.restaurant.ordersList,
    notificationData: state.users.notificationData,
    inSendNotificationProgress: state.users.inSendNotificationProgress,
    ordersListErrors: state.users.ordersListErrors,
    orders: state.restaurant.orders,

  };
};

const mapDispatchToProps = dispatch => ({
  onGetOrdersList: payload => dispatch({ type: GET_ORDERS_LIST, payload }),
  onUnload: () => dispatch({ type: MENUS_UNLOADED }),
  onSetOrderData: data => dispatch({ type: SET_ORDERS, ordersData: data }),
  onSetVisitsData: data => dispatch({ type: SET_VISITS, visitsData: data }),
  onGetOrders: payload => dispatch({ type: GET_ORDERS, payload }),
  onGetVisits: payload => dispatch({ type: GET_VISITS, payload }),
  onGetUserDetails: payload => dispatch({ type: GET_USER_DETAILS, payload }),
  onGetUserOrderDetails: payload => dispatch({ type: GET_USER_ORDER_DETAILS, payload }),
  onSetNotifyData: data =>
    dispatch({ type: GET_NOTIFY_DATA, notifyData: data }),
  onChangeOrderStatus: payload =>
    dispatch({ type: CHANGE_ORDER_STATUS, payload }),
  onClearErrors: () => dispatch({ type: CLEAR_ERRORS })
});

const WrappedNormalForm = Form.create({ name: "normal_notification_form" })(
  OrdersList
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalForm);