import React from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { debounce } from 'lodash'

import "./crm.css";
import { Select, Input, Checkbox, Button, Modal, Form, Spin, InputNumber, notification } from "antd";
import agent from "../../agent";
import {
  MENUS_UNLOADED,
  GET_CRM,
  SEND_NOTIFICATION
} from "../../constants/actionTypes";
import { capitalizeWord, formatDate } from "../../utils/Helpers";
import CrmTable from "./crm-table";

const FormItem = Form.Item;

class CRM extends React.Component {
  constructor(props){
    super(props);
    this.onFilterCRM = debounce(this.onFilterCRM, 800);
  }
  state = {
    crmFilterValues: { expand: "can_send_notification" },
    notificationModalVisible: false,
    receivers: [],
    pageCount: 1,
    pageLimit: 10,
    loadedCRM: [],
    isLoadMore: false,
    isFilter: false,
    filters:{}
  };

  setNotificationModalVisible(notificationModalVisible) {
    this.setState({ notificationModalVisible });
  }

  componentDidMount() {
    if (this.props.currentRestaurant && this.props.currentRestaurant.details) {
      let requestParams = {restaurant:this.props.currentRestaurant.details.id,
         expand: "can_send_notification" };
      this.props.onGetCRM(agent.Users.crm(requestParams));
    }


  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.currentRestaurant !== this.props.currentRestaurant &&
      this.props.currentRestaurant &&
      this.props.currentRestaurant.details
    ) {
      let requestParams = {restaurant:this.props.currentRestaurant.details.id,
         expand: "can_send_notification" };
      this.props.onGetCRM(agent.Users.crm(requestParams));
      this.setState({
        currentRestaurant: this.props.currentRestaurant.details
      });
    }
    if (
      prevProps.crm !== this.props.crm &&
      this.props.crm &&
      this.props.crm.items
    ) {
      if(!this.state.isLoadMore) {
        await this.setState({
          loadedCRM: [],
        });
      }
      let newCRM = [];
      this.props.crm.items.map((value, index) => {
        newCRM.push(value);
      });
      await this.setState({
        loadedCRM: [...this.state.loadedCRM, ...newCRM],
        isLoadMore: false,
        isFilter: false
      });
    }
    if (
      prevProps.notificationData !== this.props.notificationData &&
      this.props.notificationData &&
      this.props.notificationData.details
    ) {
      let requestParams = {restaurant:this.props.currentRestaurant.details.id,
         expand: "can_send_notification" };
      this.props.onGetCRM(agent.Users.crm(requestParams));
      this.setNotificationModalVisible(false);
      this.setState({
        receivers: []
      });
    }
    if(prevProps.crmErrors !== this.props.crmErrors &&
      this.props.crmErrors && this.props.crmErrors.errors) {
      this.props.crmErrors.errors.map((value, index) => {
        value.rules.map((value, index) => {
          return notification.error({message: value.message})
        })
      })
    }
  }

  onFilterCRM = async (fieldName, value) => {
      await this.setState(prevState => {
          return {
            crmFilterValues: { ...prevState.crmFilterValues, [fieldName]: value },
            isLoadMore: false,
            isFilter: true
          };
        });
      let requestParams = {
        ...this.state.crmFilterValues,
        restaurant:this.props.currentRestaurant.details.id
      };
      if (requestParams.last_visit) {
        requestParams.last_visit = formatDate(
          requestParams.last_visit,
          "DD/MM/YYYY"
        );
      }
      if(requestParams.gender === "All"){
      delete requestParams.gender
      }
      this.setState({
        filters:requestParams
      })      
      this.props.onGetCRM(agent.Users.crm(requestParams));
  };

  onAddReceiversForNotifiaction = async receiverId => {
    await this.setState({
      receivers: [...this.state.receivers, receiverId]
    });
  };

  onSendNotification = e => {
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      if (!err) {
        data.receivers = [...this.state.receivers];
        this.props.onSendNotification(data);
      }
    });
  };

  onLoadMoreItemsToDishes = async () => {
    if (
      this.props.crm &&
      this.props.crm.meta &&
      this.props.crm.meta.total !== this.state.loadedCRM.length
    ) {
      await this.setState({
        pageCount: this.state.pageCount + 1,
        isLoadMore: true,
        isFilter: false
      });
      let requestParams = {
        expand: "can_send_notification",
        limit: this.state.pageLimit,
        page: this.state.pageCount
      };
      this.props.onGetCRM(agent.Users.crm(requestParams));
    }
  };
  submit=()=>{
    this.props.onGetCRM(agent.Users.crm(this.state.filters));
  }
  revert = ()=>{
    window.location.reload(false);
    // this.setState({
    //   crmFilterValues: { expand: "can_send_notification" },
    // })
    // if (this.props.currentRestaurant && this.props.currentRestaurant.details) {
    //   let requestParams = {restaurant:this.props.currentRestaurant.details.id,
    //      expand: "can_send_notification" };
    //   this.props.onGetCRM(agent.Users.crm(requestParams));
    // }
  }
  render() {
    const { Option } = Select;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="crm-page-container">
        <div className="section-title">Customer Relationship Management</div>
        <div className="customer-table-filter-set">
          <div className="customer-filter-set">
            <span className="filter-label">Gender</span>
            <Select
              showSearch
              style={{ width: 189 }}
              placeholder="Gender"
              optionFilterProp="children"
              onChange={value => this.onFilterCRM("gender", value)}
            >
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="All">All</Option>
            </Select>
          </div>
          <div className="customer-filter-set">
            <span className="filter-label">Age</span>
            <Select
              showSearch
              style={{ width: 189 }}
              placeholder="21-30"
              optionFilterProp="children"
              onChange={value => this.onFilterCRM("age_range", value)}
            >
              <Option value="0">{"<15"}</Option>
              <Option value="1">{"15-18"}</Option>
              <Option value="2">{"18-25"}</Option>
              <Option value="3">{"25-30"}</Option>
              <Option value="4">{"30-40"}</Option>
              <Option value="5">{"<40"}</Option>
            </Select>
          </div>
          <div className="customer-filter-set">
            <span className="filter-label">Last Visit</span>
            <DatePicker
              selected={this.state.crmFilterValues.last_visit}
              onChange={date => this.onFilterCRM("last_visit", date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="DD/MM/YYYY"
              customInput={<Input style={{ width: 155 + "px",textAlign:'left' }} />}
            />
          </div>
          <div className="customer-filter-set">
            <span className="filter-label">Visit Count</span>
            <Input style={{ width: 55 + "px",textAlign:'left' }}
              type="number"
              placeholder="0"
              onChange={e => this.onFilterCRM("min_visits", e.target.value)}
            />
            <span className="filter-label">To</span>
            <Input style={{ width: 55 + "px",textAlign:'left' }}
              type="number"
              placeholder="100"
              onChange={e => this.onFilterCRM("max_visits", e.target.value)}
            />
          </div>
          <div className="customer-filter-set">
            <span className="filter-label">LTS</span>
            <InputNumber
              placeholder="0"
              onChange={value => this.onFilterCRM("min_revenue", value)}
            />
            <span className="filter-label">To</span>
            <InputNumber
              placeholder="100"
              onChange={value => this.onFilterCRM("max_revenue", value)}
            />
          </div>
          <div className="customer-filter-set">
            <Checkbox onChange={() => this.onFilterCRM("occassion", this.state.crmFilterValues.occassion == 1 ? "" : 1)}>
              <span className="filter-label">Special Ocassion</span>
            </Checkbox>
          </div>
          <div className="filter-button-group">
          <Button className="btn-submit mr-3" onClick={this.submit}> Submit</Button>
          <Button className="btn-reset" onClick={this.revert}> Reset</Button>
          </div>
        </div>
        <div className="customer-table">
          <div className="pull-left">
            <div className="customer-count-text">
              {this.props.crm &&
              this.props.crm.items &&
              this.props.crm.items.length > 0
                ? this.props.crm.items.length
                : 0}{" "}
              Customers
            </div>
          </div>
          <div className="pull-right">
            <button
              type="button"
              className="button-md ripple-btn-green btn-green mt-1"
              onClick={() => this.setNotificationModalVisible(true)}
              disabled={this.state.receivers.length === 0}
            >
              Send Notifications
            </button>
          </div>
          <div className="table-responsive">
            <CrmTable
            {...this.props}
            stateData={this.state.loadedCRM}
            onAddReceiversForNotifiaction={this.onAddReceiversForNotifiaction}
            receivers={this.state.receivers}
            />
            {this.props.crm &&
              this.props.crm.meta &&
              this.props.crm.meta.total !== this.state.loadedCRM.length && (
                <div
                  className="dish-load-more"
                  onClick={() => this.onLoadMoreItemsToDishes()}
                >
                  <button
                    type="button"
                    className="button-md font-700 text-upper ripple-btn-green btn-green mt-1"
                  >
                    Load More...
                  </button>
                </div>
              )}
          </div>
        </div>
        <Modal
          className="common-modal-content buy-more-modal-container"
          title=""
          centered
          visible={this.state.notificationModalVisible}
          onOk={() => this.setNotificationModalVisible(false)}
          onCancel={() => this.setNotificationModalVisible(false)}
          footer={[
            <Button
              disabled={this.props.inSendNotificationProgress}
              className="outline-secondary-btn-lg ripple-btn-secondary"
              key="back"
              onClick={() => this.setNotificationModalVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              disabled={false}
              loading={this.props.inSendNotificationProgress}
              className="pink-button-lg ripple-btn-pink"
              key="submit"
              type="primary"
              onClick={this.onSendNotification}
            >
              Send
            </Button>
          ]}
        >
          <div className="modal-container">
            <div className="modal-text">Notification Modal</div>
            <div
              className="modal-mid-content-form input-container"
              style={{ fontSize: "16px" }}
            >
              <Form>
                <FormItem>
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your title"
                      }
                    ]
                  })(<Input size="large" placeholder="Title" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("message", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your message!"
                      }
                    ]
                  })(<Input.TextArea size="large" placeholder="Message" />)}
                </FormItem>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    vendorProfile: state.profile.vendorProfile,
    vendorRestaurant: state.profile.vendorRestaurant,
    hoppointsDetails: state.profile.hoppointsDetails,
    currentRestaurantInProgress: state.profile.currentRestaurantInProgress,
    inCRMProgress: state.users.inCRMProgress,
    currentRestaurant: state.profile.currentRestaurant,
    crm: state.users.crm,
    notificationData: state.users.notificationData,
    inSendNotificationProgress: state.users.inSendNotificationProgress,
    crmErrors: state.users.crmErrors
  };
};

const mapDispatchToProps = dispatch => ({
  onSendNotification: requestParams =>
    dispatch({
      type: SEND_NOTIFICATION,
      payload: agent.Users.sendNotification(requestParams)
    }),
  onGetCRM: payload => dispatch({ type: GET_CRM, payload }),
  onUnload: () => dispatch({ type: MENUS_UNLOADED })
});

const WrappedNormalForm = Form.create({ name: "normal_notification_form" })(
  CRM
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalForm);
