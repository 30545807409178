import { GET_CRM, ASYNC_START, SEND_NOTIFICATION, GET_VISITS, GET_USER_DETAILS } from "../constants/actionTypes";

const defaultState = {
  crm: {},
  notificationData: {},
  crmErrors: {},
  visits: {},
  userDetails: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CRM:
      return {
        ...state,
        inCRMProgress: false,
        crm: action.error ? {} : action.payload,
        crmErrors: action.error ? action.payload.error : {},
      };
    case SEND_NOTIFICATION:
      return {
        ...state,
        inSendNotificationProgress: false,
        notificationData: action.error ? {} : action.payload
      };
    case GET_VISITS:
      return {
        ...state,
        isVisitsProgress: false,
        visits: action.error ? {} : action.payload
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        isGetUserDetailProgress: false,
        userDetails: action.error ? {} : action.payload
      };
    case ASYNC_START:
      if (action.subtype === GET_CRM) {
        return { ...state, inCRMProgress: true };
      }
      if (action.subtype === SEND_NOTIFICATION) {
        return { ...state, inSendNotificationProgress: true };
      }
      if (action.subtype === GET_VISITS) {
        return { ...state, isVisitsProgress: true };
      }
      if (action.subtype === GET_USER_DETAILS) {
        return { ...state, isGetUserDetailProgress: true };
      }
      return { ...state };
    default:
      return state;
  }
};
